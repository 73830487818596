import * as React from 'react';
import { Grid, TextField, Stack, debounce } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { useState, useMemo, useCallback } from 'react';
import { saveLink } from '../reducer/qr/configs';

export const Link = () => {
  const link = useSelector((state) => state.qrConfigs.link);
  const dispatch = useDispatch();

  const handleSaveLink = useCallback(
    (value) => {
      dispatch(saveLink(value));
    },
    [dispatch]
  );

  const debouncedSendRequest = useMemo(() => debounce(handleSaveLink, 1000), [handleSaveLink]);

  const onChange = (e) => {
    const { value } = e.target;
    debouncedSendRequest(value);
  };

  return (
    <Stack>
      <Grid container spacing={2} style={{ marginBottom: 0 }}>
        <a style={{ padding: 14, fontWeight: 'bold', fontSize: 24, marginLeft: 20, marginTop: 12 }}>Link</a>
        <TextField
          placeholder="Enter URL"
          label="Write your Url here"
          variant="outlined"
          fullWidth
          name="url"
          required
          style={{ marginLeft: 30, marginRight: 20 }}
          defaultValue={link}
          onChange={onChange}
        />

        <a style={{ fontSize: 13, marginTop: 130, marginLeft: 35 }}>Your QR code will open this URL. </a>
      </Grid>
    </Stack>
  );
};
