// scroll bar
import 'simplebar/src/simplebar.css';

// lazy image
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import 'react-lazy-load-image-component/src/effects/black-and-white.css';

import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/it';
// contexts
import { Provider } from 'react-redux';
import { ClerkProvider } from '@clerk/clerk-react';
import { dark } from '@clerk/themes';
import { SettingsProvider } from './contexts/SettingsContext';
import { CollapseDrawerProvider } from './contexts/CollapseDrawerContext';
import App from './App';
import store from './reducer/store';
import useSettings from './hooks/useSettings';
// ----------------------------------------------------------------------
// const store = createStore();
const root = ReactDOM.createRoot(document.getElementById('root'));
const clerkPubKey = 'pk_test_aW50ZXJuYWwtY3JhcHBpZS0zOS5jbGVyay5hY2NvdW50cy5kZXYk';

root.render(
  <ClerkProvider
    appearance={{
      // baseTheme: theme === 'dark' ? dark : undefined,
      layout: {
        socialButtonsVariant: 'iconButton',
        socialButtonsPlacement: 'bottom',
        logoPlacement: 'none',
      },
    }}
    publishableKey={clerkPubKey}
  >
    <HelmetProvider>
      <SettingsProvider>
        <CollapseDrawerProvider>
          <BrowserRouter>
            <Provider store={store}>
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="it">
                <App />
              </LocalizationProvider>
            </Provider>
          </BrowserRouter>
        </CollapseDrawerProvider>
      </SettingsProvider>
    </HelmetProvider>
  </ClerkProvider>
);
