import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import {
  Container,
  FormControlLabel,
  Grid,
  Box,
  Card,
  Button,
  TextField,
  Alert,
  Toolbar,
  CircularProgress,
} from '@mui/material';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { postQrLink, getDataByQrId, updateQrData } from '../communication/CommunicationController';
import BarChart from '../components/BarChart';
import BarChartBrowser from '../components/BarChartBrowser';
import LineChart from '../components/LineChart';
import NumberOfScan from '../components/NumberOfScan';
import ScansCity from '../components/ScansCity';
import MainLanguage from '../components/MainLanguage';
import Device from '../components/Device';
import ItalyMaps from '../components/ItalyMaps';
import WorldMap from '../components/WorldMap';
import TopRegion from '../components/TopRegion';
import useSettings from '../hooks/useSettings';

export default function AnalyticsQr() {
  const { themeStretch } = useSettings();
  const [content, setContent] = useState('');

  // console.log('content: ', content);

  return (
    <Container maxWidth={themeStretch ? false : 'xl'}>
      <Grid container spacing={3}>
        {/* Prima riga: total scan, top city e top browser language */}
        <Grid item xs={12} sm={6} md={3}>
          <NumberOfScan />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <TopRegion />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <ScansCity />
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          {' '}
          <MainLanguage />
        </Grid>
        {/* Seconda riga: linechart, main browser, main device */}
        {/* <Grid item xs={12} sm={6} md={8}>
          <Card style={{ padding: 50, minHeight: 220 }}>
            <LineChart />{' '}
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Card style={{ padding: 10, minHeight: 220 }}>
            <BarChartBrowser />
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Card style={{ padding: 10, minHeight: 220 }}>
            <Device />
          </Card>
        </Grid> */}
        <Grid container spacing={3}>
          <Grid item xs={12} sm={8} md={8}>
            <Card style={{ padding: 41, minHeight: 200, marginTop: 20, marginLeft: '2%' }}>
              <LineChart />
            </Card>
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <Grid container direction="column" spacing={3}>
              <Grid item>
                <Card style={{ padding: 10, minHeight: 220, marginTop: 20 }}>
                  <BarChartBrowser />
                </Card>
              </Grid>
              <Grid item>
                <Card style={{ padding: 10, minHeight: 220, marginTop: -10 }}>
                  <Device />
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {/* Terza riga: cartina del mondo */}
        <Grid item xs={12} sm={6} md={12}>
          <WorldMap setTooltipContent={setContent} />
          {/* <ItalyMaps setTooltipContent={setContent} /> */}
          <ReactTooltip>{content}</ReactTooltip>
        </Grid>
      </Grid>
    </Container>
  );
}
