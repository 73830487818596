import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Grid, TextField, Stack, Card, Button } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import YouTubeIcon from '@mui/icons-material/YouTube';
import TelegramIcon from '@mui/icons-material/Telegram';
import PinterestIcon from '@mui/icons-material/Pinterest';
import RedditIcon from '@mui/icons-material/Reddit';
import LinkIcon from '@mui/icons-material/Link';
import UpgradeIcon from '@mui/icons-material/Upgrade';
import DeleteIcon from '@mui/icons-material/Delete';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import ImageUploading from 'react-images-uploading';
import { MuiColorInput } from 'mui-color-input';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import {
  saveApp,
  saveSocialPreviewPage,
  saveSocialPreviewPageSocials,
  addSocialPreviewPageSocials,
  deleteSocialPreviewPageSocials,
} from '../reducer/qr/configs';

const availableSocials = [
  'facebook',
  'instagram',
  'linkedin',
  'twitter',
  'youtube',
  'telegram',
  'pinterest',
  'reddit',
  'link',
];

export const Social = () => {
  const socialName = useSelector((state) => state.qrConfigs.socialPreviewPage.socialName);

  const socials = useSelector((state) => state.qrConfigs.socialPreviewPage.socials);

  const dispatch = useDispatch();
  const [color, setColor] = useState(useSelector((state) => state.qrConfigs.socialPreviewPage.pageColor));
  const [colorCard, setColorCard] = useState(useSelector((state) => state.qrConfigs.socialPreviewPage.cardColor));
  const [colorText, setColorText] = useState(useSelector((state) => state.qrConfigs.socialPreviewPage.textColor));

  const [images, setImages] = useState();
  const maxNumber = 1;

  const onChange = (imageList) => {
    // data for submit
    setImages(imageList);
    dispatch(saveSocialPreviewPage({ key: 'logo', value: imageList[0].data_url }));
  };

  function rgbToHex(rgbColor) {
    // Rimuovi il prefisso "rgb(" e la parentesi chiusa ")"
    const rgbValues = rgbColor.substring(4, rgbColor.length - 1).split(', ');

    // Converte i valori RGB in esadecimali
    const r = parseInt(rgbValues[0], 10).toString(16).padStart(2, '0');
    const g = parseInt(rgbValues[1], 10).toString(16).padStart(2, '0');
    const b = parseInt(rgbValues[2], 10).toString(16).padStart(2, '0');

    // Restituisci il colore esadecimale
    return `#${r}${g}${b}`;
  }

  const handleChange = (color) => {
    const colorEx = rgbToHex(color);

    setColor(colorEx);
    dispatch(saveSocialPreviewPage({ key: 'pageColor', value: colorEx }));
  };

  const handleChangeText = (color) => {
    const colorEx = rgbToHex(color);

    setColorText(colorEx);
    dispatch(saveSocialPreviewPage({ key: 'textColor', value: colorEx }));
  };

  const renderIcon = (type) => {
    switch (type) {
      case 'facebook':
        return <FacebookIcon sx={{ fontSize: 30, color: '#3b5998' }} />;
      case 'instagram':
        return <InstagramIcon sx={{ fontSize: 30, color: '#C13584' }} />;
      case 'linkedin':
        return <LinkedInIcon sx={{ fontSize: 30, color: '#0e76a8' }} />;
      case 'twitter':
        return <TwitterIcon sx={{ fontSize: 30, color: '#00acee' }} />;
      case 'youtube':
        return <YouTubeIcon sx={{ fontSize: 30, color: '#c4032b' }} />;
      case 'telegram':
        return <TelegramIcon sx={{ fontSize: 30, color: '#0088cc' }} />;
      case 'pinterest':
        return <PinterestIcon sx={{ fontSize: 30, color: '#E60023' }} />;
      case 'reddit':
        return <RedditIcon sx={{ fontSize: 30, color: '#FF4500' }} />;
      case 'link':
        return <LinkIcon sx={{ fontSize: 30, color: '#555555' }} />;
      default:
        return null;
    }
  };

  return (
    <>
      <Stack>
        <a style={{ padding: 14, fontWeight: 'bold', fontSize: 30, marginLeft: 20, marginTop: -20 }}>Social Media</a>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={6}>
            <a
              style={{
                padding: 14,
                fontWeight: 'bold',
                fontSize: 24,
                marginLeft: 20,
                marginTop: 2,
                marginBottom: -10,
              }}
            >
              Page color
            </a>{' '}
          </Grid>
          <Grid item xs={6}>
            <a style={{ padding: 14, fontWeight: 'bold', fontSize: 24, marginLeft: 10, marginTop: 12 }}>Text color</a>{' '}
          </Grid>
          <Grid item xs={6}>
            <MuiColorInput value={color} onChange={handleChange} style={{ marginLeft: 30 }} />
          </Grid>
          <Grid item xs={6}>
            <MuiColorInput value={colorText} onChange={handleChangeText} style={{ marginLeft: 20 }} />
          </Grid>
        </Grid>
        <Grid />

        <Grid>
          <p />
        </Grid>
        <a style={{ padding: 14, fontWeight: 'bold', fontSize: 24, marginLeft: 20 }}>Social Media Profiles</a>
        <Grid>
          {socials.map((social) => (
            <Grid key={`social_${social.type}`} style={{ marginTop: 12 }}>
              {renderIcon(social.type)}
              <TextField
                style={{
                  marginLeft: 20,
                  width: 500,
                }}
                id="outlined-basic"
                // label="eg. www.facebook.com/page"
                variant="outlined"
                value={social.url}
                onChange={(ev) => dispatch(saveSocialPreviewPageSocials({ id: social.id, url: ev.target.value }))}
              />{' '}
              <DeleteIcon onClick={() => dispatch(deleteSocialPreviewPageSocials(social.id))} />
            </Grid>
          ))}
        </Grid>
        <a style={{ padding: 14, fontWeight: 'bold', fontSize: 24, marginLeft: 20, marginTop: 10 }}>Add more links</a>
        <a style={{ padding: 14, fontWeight: 'normal', fontSize: 16, marginLeft: 20, marginTop: -20 }}>
          Click on each icon to add more social media links
        </a>
        <Grid style={{ marginLeft: 1 }}>
          {availableSocials.map((avSocial) => (
            <Button
              key={`available_social_${avSocial}`}
              style={{ color: 'black' }}
              variant="text"
              startIcon={renderIcon(avSocial)}
              onClick={() => dispatch(addSocialPreviewPageSocials({ type: avSocial, url: '' }))}
            />
          ))}
        </Grid>
        <p />
        <a style={{ fontSize: 13, marginTop: 38, marginLeft: 35 }}>A tree for all your socials </a>
      </Stack>
    </>
  );
};
