import Chart from 'chart.js/auto';
import { Bar } from 'react-chartjs-2';
import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect, React } from 'react';

import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { postQrLink, getDataByQrId, updateQrData } from '../communication/CommunicationController';

const BarChartBrowser = () => {
  const userLogged = useSelector((state) => state.user.user);
  const queryParams = useParams();
  const qrId = queryParams?.id;
  const [analytics, setAnalytics] = useState();

  const initialBrowser = [
    {
      browser: '',
      count: 0,
    },
    {
      browser: '',
      count: 0,
    },
    {
      browser: '',
      count: 0,
    },
  ];

  const [browser, setBrowser] = useState(initialBrowser);
  // console.log('browser', browser);

  useEffect(() => {
    // console.log('browser', browser);
  }, [browser]);

  useEffect(() => {
    if (analytics?.length > 0 && analytics !== undefined && analytics !== null) {
      updateCounts(analytics);
    }
  }, [analytics]);

  const updateCounts = (newData) => {
    // console.log('newData ', newData);
    const updatedBrowser = newData.map((brows) => {
      const b = brows.browser;
      // console.log('b ', b);
      const count = brows.browser_count;
      // console.log('count ', count);
      return {
        b,
        count,
      };
    });
    setBrowser(updatedBrowser);
  };

  const getQrAnalytics = async () => {
    try {
      const res = await getDataByQrId(qrId);
      return res;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  // console.log('no');

  const getAnalytics = async () => {
    try {
      const res = await getQrAnalytics();
      setAnalytics(res.browser);
    } catch (err) {
      console.error('Error getting user qrs', err);
    }
  };

  // useEffect(() => {
  //   if (userLogged.isLog && userLogged.id) {
  //     getAnalytics();
  //   }
  // }, [userLogged]);

  useEffect(() => {
    getAnalytics();
  }, []);

  const data = {
    labels: browser?.map((conta) => conta.b),
    datasets: [
      {
        // label: 'hidden',
        data: browser?.map((conta) => conta.count),
        fill: false,
        // backgroundColor: ['rgba(75, 192, 192, 0.2)', 'rgba(54, 162, 235, 0.2)', 'rgba(153, 102, 255, 0.2)'],
        borderColor: ['rgb(75, 192, 192)', 'rgb(54, 162, 235)', 'rgb(153, 102, 255)'],
        backgroundColor: ['rgb(75, 192, 192)', 'rgb(54, 162, 235)', 'rgb(153, 102, 255)'],
        borderWidth: 1,
        borderRadius: 5,
      },
    ],
  };

  const options = {
    scales: {
      x: {
        grid: {
          display: false, // Nasconde le linee verticali
        },
      },
      y: {
        grid: {
          display: true, // Mostra le linee orizzontali
          borderDash: [10, 10],
        },
      },
    },
    plugins: {
      title: {
        display: true,
        text: 'Main Browsers',
      },
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
        usePointStyle: true,
        titleAlign: 'center',
        titleColor: 'gold',
        titleSpacing: 3,
        TitleFont: {
          weight: 'bold',
        },
      },
    },
  };

  return (
    <div>
      <Bar data={data} options={options} />
    </div>
  );
};

export default BarChartBrowser;
