import { Container, Select, Grid, Card, TextField, Button, FormControl, InputLabel } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import MobileStoreButton from 'react-mobile-store-button';
import { Link } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import LinkIcon from '@mui/icons-material/Link';
import EventIcon from '@mui/icons-material/Event';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import dayjs from 'dayjs';
import moment from 'moment';
import Weddingday from '../assets/weddingday.jpeg';

export const PrevievEventOption = ({ props }) => {
  const { input, configs } = props;

  console.log('EV: ', configs);
  // event
  const nameEvent = useSelector((state) => state.qrConfigs.event.nameEvent);
  const location = useSelector((state) => state.qrConfigs.event.location);
  const url = useSelector((state) => state.qrConfigs.event.url);
  const descriptionEvent = useSelector((state) => state.qrConfigs.event.description);
  const dataStart = useSelector((state) => state.qrConfigs.event.dataStart);
  const dataEnd = useSelector((state) => state.qrConfigs.event.dataEnd);
  const pageColorEvent = useSelector((state) => state.qrConfigs.event.pageColor);
  const cardColorEvent = useSelector((state) => state.qrConfigs.event.cardColor);
  const textColorEvent = useSelector((state) => state.qrConfigs.event.textColor);
  const logoEvent = useSelector((state) => state.qrConfigs.event.logo);

  return (
    <div
      style={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: pageColorEvent,
      }}
    >
      {(!!logoEvent && (
        <img
          src={logoEvent}
          alt="logo"
          style={{
            position: 'absolute',
            top: 45,
            width: 90,
            height: 90,
            borderRadius: '20%',
            border: `4px solid ${pageColorEvent}`,
            zIndex: 1000,
          }}
        />
      )) || (
        <img
          src={Weddingday}
          alt="logo"
          style={{
            position: 'absolute',
            borderRadius: '20%',
            border: `4px solid ${pageColorEvent}`,

            width: 90,
            height: 90,
            top: 40,
            zIndex: 1000,
          }}
        />
      )}
      <Card
        style={{
          padding: 16,
          backgroundColor: cardColorEvent,
          width: 247,
          height: 420,
          marginTop: 85,

          // overflow: 'scroll',
        }}
      >
        <a
          style={{
            fontWeight: 'bold',
            fontSize: 25,
            flex: 1,
            marginTop: 20,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: textColorEvent,
          }}
        >
          {' '}
          {nameEvent || 'Wedding Day!'}
        </a>
        <a
          style={{
            fontWeight: 'unset',
            fontFamily: 'serif',
            fontSize: 15,
            flex: 1,
            marginBottom: 10,
            display: 'flex',
            textAlign: 'center',
            alignItems: 'center',
            justifyContent: 'center',
            color: textColorEvent,
          }}
        >
          {descriptionEvent || 'Maria & Steve are finally \n getting married'}
        </a>
        <EventIcon />
        <h3 style={{ color: textColorEvent, marginTop: -32, marginLeft: 30 }}>Date</h3>
        <Table style={{ marginLeft: -20, marginTop: -12 }} aria-label="simple table">
          <TableBody>
            <TableRow>
              <TableCell align="left">
                <h3 style={{ color: textColorEvent }}>Start</h3>
                <h4 style={{ fontWeight: 'normal', padding: 2, color: textColorEvent }}>
                  {dataStart ? dayjs(dataStart).format('DD-MM-YYYY') : moment().format('DD-MM-YYYY')}
                </h4>
              </TableCell>
              <TableCell align="left">
                <h3 style={{ color: textColorEvent }}> Time</h3>
                <h4 style={{ fontWeight: 'normal', color: textColorEvent }}>
                  {dataStart ? dayjs(dataStart).format('HH:mm') : moment().format('HH:mm')}
                </h4>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <Table style={{ marginLeft: -20, marginTop: -30 }} aria-label="simple table">
          <TableBody>
            <TableRow>
              <TableCell align="left">
                <h3 style={{ color: textColorEvent }}> End</h3>
                <h4 style={{ fontWeight: 'normal', padding: 2, color: textColorEvent }}>
                  {dataEnd ? dayjs(dataEnd).format('DD-MM-YYYY') : moment().format('DD-MM-YYYY')}
                </h4>
              </TableCell>
              <TableCell align="left">
                <h3 style={{ color: textColorEvent }}> Time</h3>
                <h4 style={{ fontWeight: 'normal', color: textColorEvent }}>
                  {dataEnd ? dayjs(dataEnd).format('HH:mm') : moment().format('HH:mm')}
                </h4>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <LocationOnIcon />
        <h3 style={{ color: textColorEvent, marginTop: -30, marginLeft: 30 }}>Location</h3>
        <a
          style={{
            fontWeight: 'unset',
            flex: 1,
            fontSize: 15,

            marginBottom: 10,
            marginTop: 7,
            marginLeft: '3%',
            display: 'flex',
            // textAlign: 'center',
            // alignItems: 'center',
            // justifyContent: 'center',
            color: textColorEvent,
          }}
        >
          {location || 'Bellevue, Seattle, Washington 98109, United States'}
        </a>
        <LinkIcon />
        <h3 style={{ color: textColorEvent, marginTop: -33, marginLeft: 30 }}>Website</h3>
        <a
          style={{
            fontWeight: 'unset',
            fontSize: 15,
            flex: 1,
            marginBottom: 10,
            marginLeft: '3%',
            display: 'flex',
            color: textColorEvent,
          }}
        >
          {url || 'https://weddingday.com'}
        </a>
      </Card>
      <div style={{ marginTop: 10 }}>
        <Button variant="contained"> Open in calendar</Button>
      </div>
    </div>
  );
};
