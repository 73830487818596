import { useSelector, useDispatch } from 'react-redux';
import { Container, Select, Grid, Card, TextField, Button, FormControl, InputLabel } from '@mui/material';

export const PrevievTextOption = ({ props }) => {
  const { input, configs } = props;

  const pageColorText = useSelector((state) => state.qrConfigs.textColor.pageColor);
  const cardColorText = useSelector((state) => state.qrConfigs.textColor.cardColor);
  const textColorText = useSelector((state) => state.qrConfigs.textColor.textColor);

  return (
    <div
      style={{
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: pageColorText,
      }}
    >
      <Card
        style={{
          padding: 16,
          backgroundColor: cardColorText,
          width: 257,
          height: 370,
          marginTop: 35,
          // overflow: 'scroll',
        }}
      >
        <a style={{ fontWeight: 'bold', fontSize: 20, color: textColorText }}>Your text:</a>
        <p />
        <a style={{ color: textColorText }}>
          {configs.value ||
            'Ho sceso, dandoti il braccio, almeno un milione di scale e ora che non ci sei è il vuoto ad ogni gradino. Anche così è stato breve il nostro lungo viaggio. Il mio dura tuttora, nè più mi occorrono le coincidenze, le prenotazioni, le trappole, gli scorni di chi crede che la realtà sia quella che si vede.'}
        </a>
      </Card>
    </div>
  );
};
