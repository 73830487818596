import { useEffect, useState, useCallback, useMemo } from 'react';
import { Grid, TextField, Stack, debounce } from '@mui/material';
import MuiPhoneNumber from 'mui-phone-number';
import { useDispatch, useSelector } from 'react-redux';
import { saveWhatsapp } from '../reducer/qr/configs';

export const Whatsapp = () => {
  const [message, setMessage] = useState(useSelector((state) => state.qrConfigs.whatsapp.message));
  const [number, setNumber] = useState(useSelector((state) => state.qrConfigs.whatsapp.number));

  const dispatch = useDispatch();

  const handleSaveCall = useCallback(
    (e) => {
      dispatch(saveWhatsapp({ key: 'number', value: e }));
    },
    [dispatch]
  );

  const handleSaveMessage = useCallback(
    (message) => {
      dispatch(saveWhatsapp({ key: 'message', value: message }));
    },
    [dispatch]
  );

  const debouncedSendRequest = useMemo(() => debounce(handleSaveCall, 1000), [handleSaveCall]);
  const debouncedSendRequestMessage = useMemo(() => debounce(handleSaveMessage, 1000), [handleSaveMessage]);

  const onChange = (e) => {
    setNumber(e);
    // const { value } = e;
    debouncedSendRequest(e);
  };
  const onChangeMessage = (e) => {
    // const { value } = e.target;
    setMessage(e.target.value);
    debouncedSendRequestMessage(e.target.value);
  };

  return (
    <Stack>
      <Grid container spacing={2} style={{ marginBottom: 0 }}>
        <a style={{ padding: 14, fontWeight: 'bold', fontSize: 24, marginLeft: 20, marginTop: 12 }}>WhatsApp</a>
      </Grid>
      <a style={{ fontStyle: 'italic', marginTop: 8, marginLeft: 20 }}>Select coutry code and insert phone number</a>
      <MuiPhoneNumber
        onChange={onChange}
        style={{ marginLeft: 20, marginTop: 15, marginRight: 25 }}
        defaultCountry={'it'}
        regions={'europe'}
        value={number}
      />
      <TextField
        padding={12}
        style={{ marginTop: 14, marginLeft: 15, marginRight: 25 }}
        id="outlined-basic"
        label="Message"
        variant="outlined"
        value={message}
        onChange={onChangeMessage}
      />{' '}
      <a style={{ fontSize: 13, marginTop: 30, marginLeft: 20 }}>
        Scanning the QR code will send whatsapp message to the phone number.{' '}
      </a>
    </Stack>
  );
};
