/* eslint-disable import/no-unresolved */
import PropTypes from 'prop-types';
// @mui
import { Box, Link, Typography, Avatar, Grid, Button, Modal, IconButton } from '@mui/material';
import {
  useClerk,
  useUser,
  UserButton,
  SignInButton,
  SignIn,
  SignedIn,
  SignedOut,
  SignOutButton,
} from '@clerk/clerk-react';
import { ClerkWrapper } from 'src/clerk';
import { useTheme, styled } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

// hooks
import useSettings from 'src/hooks/useSettings';
import { saveUser, login } from 'src/reducer/user';
import PageSettings from 'src/pages/PageSettings';
import { dark } from '@clerk/themes';
import LoginIcon from '@mui/icons-material/Login';
// import { jwt } from 'jsonwebtoken';
// import { makeStyles } from '@mui/styles';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: theme.palette.grey[500_12],
  transition: theme.transitions.create('opacity', {
    duration: theme.transitions.duration.shorter,
  }),
}));

// ----------------------------------------------------------------------

NavbarAccount.propTypes = {
  isCollapse: PropTypes.bool,
};

export const userAuthenticatedFunction = () => newUser;
export function isAuthenticatedFunction() {
  return isLogin;
}

let isLogin = false;
let newUser = {};

export default function NavbarAccount({ isCollapse }) {
  const theme = useTheme();
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_THEME = theme.palette.mode;

  // const decodedToken = jwt.decode(
  //   'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJkZXYiOiJkdmJfMlBnaFA5c21TWHNkWDNCblI5MFczNWVJZXJRIn0.Upw5ysNP3b_35h0BL6TPqQP0QeY9lDd1QMIcG0OkmgWkEAcnP8ZDC4BQeWKoUo94zVw84Xc7A5Vf4pyhVzSwlahLwso4ChTXtC757pAali8-8cHnr0yCL5pcgJ_9L6-zzrwhVXI20PJ_IRYu-CkN-2euOtzonHXsfFj2BJmDMZ9LW3HZj2ygSEW_PDxKBOHOswQy_vg6x7QMwG8RLaNvNYSWNNPBl3xRS1vYx6DFqmUDYen4Qp1b2ongMO2iFaDbVFk87UWip9WG7DGehsH1N58mUVs-rFJ9t4ZG3VcfXMFvqg_r7KuMgH-65ybrcPT6h2OgfDy8iPLJr-IhN003aA'
  // );

  // const userId = decodedToken.userId;

  const dispatch = useDispatch();
  const [imageUser, setImageUser] = useState('');
  const [nameUser, setNameUser] = useState('');
  const [openSignIn, setOpenSignIn] = useState(false);

  const handleSignInOpen = () => {
    setOpenSignIn(true);
  };

  useEffect(() => {}, [isCollapse]);

  const LoginPage = () => {
    const { user, isLoading } = useUser();
    // console.log('us: ', user.id);

    if (user === null || user === undefined) {
      isLogin = false;
    } else {
      isLogin = true;
      setImageUser(user.profileImageUrl);
      setNameUser(user.fullName);
    }
    newUser = { ...newUser, ...user };

    // console.log('User: ', user);

    // TODO: ricordati di usare ovunque lo user da redux
    dispatch(
      login({
        firstName: user?.firstName,
        lastName: user?.lastName,
        fullName: user?.fullName,
        id: user?.id,
        profileImageUrl: user?.profileImageUrl,
        emailAddress: user?.primaryEmailAddress.emailAddress,
      })
    );
  };

  dispatch(saveUser({ key: 'isLog', value: isLogin }));

  return (
    <Link underline="none" color="inherit">
      <RootStyle
        sx={{
          bgcolor: 'transparent',
        }}
      >
        {PRIMARY_THEME === 'light' ? (
          <>
            <SignedIn>
              {isCollapse === true ? (
                <>
                  <Box
                    component="img"
                    sx={{
                      height: 40,
                      width: 40,
                      maxHeight: { xs: 40, md: 40 },
                      maxWidth: { xs: 40, md: 40 },
                    }}
                    src={imageUser}
                  />
                </>
              ) : (
                <>
                  <Box
                    component="img"
                    sx={{
                      height: 40,
                      width: 40,
                      maxHeight: { xs: 40, md: 40 },
                      maxWidth: { xs: 40, md: 40 },
                    }}
                    src={imageUser}
                  />
                  <h4 style={{ padding: 10 }}>{nameUser}</h4>
                  <p />
                </>
              )}
            </SignedIn>
            <SignedOut>
              {isCollapse === true ? (
                <SignInButton
                  mode="modal"
                  appearance={{
                    baseTheme: {
                      variables: {
                        colorPrimary: PRIMARY_MAIN,
                        fontFamily: 'Raleway, sans-serif',
                        fontWeight: {
                          normal: 400,
                          medium: 500,
                          bold: 600,
                        },
                      },
                      __type: 'prebuilt_appearance',
                    },
                  }}
                >
                  <Button variant="contained">
                    <LoginIcon />
                  </Button>
                </SignInButton>
              ) : (
                <SignInButton
                  // style={navbarStyle}
                  mode="modal"
                  appearance={{
                    baseTheme: {
                      variables: {
                        colorPrimary: PRIMARY_MAIN,
                        borderRadius: '8px',
                        fontFamily: 'Raleway, sans-serif',
                        fontWeight: {
                          normal: 400,
                          medium: 500,
                          bold: 600,
                        },
                      },
                      __type: 'prebuilt_appearance',
                    },
                  }}
                >
                  <Button
                    variant="contained"
                    className="btn"
                    sx={{
                      ...(!isCollapse && {
                        width: 700,
                        marginLeft: -3,
                        marginRight: -3,
                      }),
                    }}
                  >
                    Login
                  </Button>
                </SignInButton>
              )}
            </SignedOut>
          </>
        ) : (
          <>
            <SignedIn>
              {isCollapse === true ? (
                <>
                  <Box
                    component="img"
                    sx={{
                      height: 40,
                      width: 40,
                      maxHeight: { xs: 40, md: 40 },
                      maxWidth: { xs: 40, md: 40 },
                    }}
                    src={imageUser}
                  />
                </>
              ) : (
                <>
                  <Box
                    component="img"
                    sx={{
                      height: 40,
                      width: 40,
                      maxHeight: { xs: 40, md: 40 },
                      maxWidth: { xs: 40, md: 40 },
                    }}
                    src={imageUser}
                  />
                  <h4 style={{ padding: 10 }}>{nameUser}</h4>
                  <p />
                </>
              )}
            </SignedIn>
            <SignedOut>
              <SignInButton
                // style={navbarStyle}
                mode="modal"
                // baseTheme={PRIMARY_THEME}
                // appearance={{
                //   baseTheme: {
                //     variables: {
                //       colorBackground: '#191E28',
                //       colorPrimary: PRIMARY_MAIN,

                //       // colorInputBackground: '#19191A',
                //       colorAlphaShade: 'white',
                //       colorText: 'white',
                //       colorInputText: 'white',
                //     },
                //     __type: 'prebuilt_appearance',
                //   },
                // }}
                // onClose={() => setOpenSignIn(false)}
              >
                <Button
                  variant="contained"
                  className="btn"
                  onClick={handleSignInOpen}
                  sx={{
                    ...(!isCollapse && {
                      width: 700,
                      marginLeft: -3,
                      marginRight: -3,
                    }),
                  }}
                >
                  Login
                </Button>
              </SignInButton>
            </SignedOut>
          </>
        )}

        {<LoginPage />}
      </RootStyle>
    </Link>
  );
}
