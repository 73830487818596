import { createSlice } from '@reduxjs/toolkit';

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    user: {
      isLog: false,
    },
  },
  reducers: {
    login: (state, action) => {
      state.user = {
        ...state.user,
        ...action.payload,
      };
    },
    saveUser: (state, action) => {
      state.user = {
        ...state.user,
        [action.payload.key]: action.payload.value,
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const { saveUser, login } = userSlice.actions;

export default userSlice.reducer;
