import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// @mui
import {
  Container,
  Grid,
  TextField,
  Box,
  Card,
  Button,
  Alert,
  Toolbar,
  Tooltip,
  CircularProgress,
} from '@mui/material';
import { useTheme, styled } from '@mui/material/styles';
import DownloadIcon from '@mui/icons-material/Download';
import EditIcon from '@mui/icons-material/Edit';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import 'react-device-emulator/lib/styles/style.css';
import UpgradeIcon from '@mui/icons-material/Upgrade';
import DeleteIcon from '@mui/icons-material/Delete';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import { Save } from '@mui/icons-material';
import Switch from '@mui/material/Switch';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import { QRCode } from 'react-qrcode-logo';
import ImageUploading from 'react-images-uploading';
import { isEmptyStatement } from 'typescript';
import { format } from 'date-fns';
import {
  saveOptions,
  toggleIsDynamic,
  saveBehavior,
  saveLogo,
  saveColors,
  saveUrl,
  saveLink,
  saveEmail,
  saveSms,
  saveCall,
  saveWhatsapp,
  saveWifi,
  saveText,
  saveVcard,
  saveEvent,
  saveTextColor,
  saveAppPreviewPage,
  saveType,
  saveCode,
  saveApp,
} from '../reducer/qr/configs';
import { ComponentQrCard } from '../components/ComponentQrCard';
import { Link } from '../components/Link';
import { Sms } from '../components/Sms';
import { Event } from '../components/Event';
import { EmailOk } from '../components/Email';
import { Vcard } from '../components/Vcard';
import { App } from '../components/AppQR';
import { Call } from '../components/Call';
import { Whatsapp } from '../components/Whatsapp';
import { TextOk } from '../components/Text';
import { Wifi } from '../components/Wifi';
import { Social } from '../components/Social';
import { InputRenderer } from '../components/InputRenderer';
import { PrevievOption } from '../components/PreviewOption';
import { postQrLink, getDataByQrId, updateQrData, DISPATCHER_URL } from '../communication/CommunicationController';

// hooks
import useSettings from '../hooks/useSettings';
// components
import Page from '../components/Page';

export default function UpdateQr() {
  const [configs, setConfigs] = useState({});
  const { themeStretch } = useSettings();

  const dispatch = useDispatch();
  const qrConfigs = useSelector((state) => state.qrConfigs);

  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const theme = useTheme();
  const PRIMARY_MAIN = theme.palette.primary.main;

  const userLogged = useSelector((state) => state.user.user);

  const queryParams = useParams();
  // console.log('queryParams: ', queryParams);
  const qrId = queryParams?.id;
  const behavior = useSelector((state) => state.qrConfigs.behavior);

  const qrType = useSelector((state) => state.qrConfigs.type);

  const formatUrl = (type, configs) => {
    console.log('format url', configs);
    let dataStartFormatted = '';
    let dataStart = '';
    let dataEndFormatted = '';
    let dataEnd = '';
    switch (type) {
      case 'link':
        return configs.link;
      case 'sms':
        return `SMSTO:${configs.sms.number?.split(' ').join('')}:${configs.sms.message}`;
      case 'call':
        return `${configs.call.number?.split(' ').join('')}`;
      case 'email':
        return `MATMSG:TO:${configs.email.address};SUB:${configs.email.subject};BODY:${configs.email.message};;`;
      case 'txt':
        return configs.txt;
      case 'whatsapp':
        return `https://wa.me/${configs.whatsapp.number?.split(' ').join('')}/?text=${configs.whatsapp.message}`;
      case 'wifi':
        return `WIFI:S:${configs.wifi.ssid};T:${configs.wifi.networkType};P:${configs.wifi.wifiPsw};;`;
      case 'vcard':
        return `BEGIN:VCARD\nVERSION:2.1\nN;CHARSET=UTF-8:${configs.vcard.lastName};${
          configs.vcard.firstName
        }\nFN;CHARSET=UTF-8:${configs.vcard.firstName};${configs.vcard.lastName}\nTEL;CELL:${configs.vcard.mobile
          ?.split(' ')
          .join('')}\nTEL;HOME;VOICE:${configs.vcard.phoneNumber?.split(' ').join('')}\nORG;CHARSET=UTF-8:${
          configs.vcard.company
        }\nTITLE;CHARSET=UTF-8:${configs.vcard.jobTitle}\nTEL;WORK;FAX:${
          configs.vcard.fax
        }\nADR;CHARSET=UTF-8;WORK;PREF:;;${configs.vcard.address};${configs.vcard.city};${configs.vcard.postCode};${
          configs.vcard.country
        }\nEMAIL:${configs.vcard.email}\nURL:${configs.vcard.webSite}\nEND:VCARD`;
      case 'event':
        dataStart = `${configs.event.dataStart.replace(/[-\s:]/g, '')}00`;
        dataStartFormatted = `${dataStart.substring(0, 8)}T${dataStart.substring(8, 15)}`;
        dataEnd = `${configs.event.dataEnd.replace(/[-\s:]/g, '')}00`;
        dataEndFormatted = `${dataEnd.substring(0, 8)}T${dataEnd.substring(8, 15)}`;
        return `BEGIN:VEVENT\nSUMMARY:${configs.event.nameEvent}\nLOCATION:${configs.event.location}\nURL:${configs.event.url}\nDESCRIPTION:${configs.event.description}\nDTSTART:${dataStartFormatted}\nDTEND:${dataEndFormatted}\nEND:VEVENT`;
      case 'app':
        return `${DISPATCHER_URL}/${configs.code}`;
      default:
    }
  };

  const getQrDataByQrId = async () => {
    try {
      const res = await getDataByQrId(qrId);
      console.log('res: ', res);
      return res;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const qrCodeUrl = formatUrl(qrType, qrConfigs);

  const getDataQr = async () => {
    try {
      const res = await getQrDataByQrId();

      setConfigs((configs) => ({
        ...configs,
        value: `${DISPATCHER_URL}/${res.mycode}`,
        behavior,
        ecLevel: res.ec_level,
        size: res.qr_size,
        quiteZone: res.quite_zone,
        qrStyle: res.qr_style,
        eyeRadius: res.qr_eye_radius,
        eyeColor: res.qr_eye_color,
        enableCors: res.enable_cors,
        logoImage: res.logo_image,
        logoWidth: res.logo_width,
        logoHeight: res.logo_height,
        logoOpacity: res.logo_opacity,
        removeQrCodeBehindLogo: !!res.remove_qr_code_behind_logo,
        bgColor: res.qr_background_color,
        fgColor: res.qr_color,
      }));
      dispatch(saveBehavior(res.behavior));
      dispatch(saveCode(res.mycode));
      dispatch(saveOptions({ key: 'ecLevel', value: res.ec_level }));
      dispatch(saveOptions({ key: 'size', value: res.qr_size }));
      dispatch(saveOptions({ key: 'quiteZone', value: res.quite_zone }));
      dispatch(saveOptions({ key: 'qrStyle', value: res.qr_style }));
      dispatch(saveOptions({ key: 'eyeRadius', value: res.qr_eye_radius }));
      dispatch(saveOptions({ key: 'eyeColor', value: res.qr_eye_color }));
      dispatch(saveOptions({ key: 'enableCors', value: res.enable_cors }));
      dispatch(saveColors({ key: 'bgColor', value: res.qr_background_color }));
      dispatch(saveColors({ key: 'fgColor', value: res.qr_color }));
      dispatch(saveLogo({ key: 'logoImage', value: res.logo_image }));
      dispatch(saveLogo({ key: 'logoWidth', value: res.logo_width }));
      dispatch(saveLogo({ key: 'logoHeight', value: res.logo_height }));
      dispatch(saveLogo({ key: 'logoOpacity', value: res.logo_opacity }));
      dispatch(saveLogo({ key: 'removeQrCodeBehindLogo', value: !!res.remove_qr_code_behind_logo }));

      dispatch(saveType(res.type));

      console.log('res', res);
      setConfigs((configs) => ({
        ...configs,
        value: `${DISPATCHER_URL}/${res.mycode}`,
      }));

      switch (res.type) {
        case 'link':
          setConfigs((configs) => ({
            ...configs,
            value: res.info.data.url,
          }));
          dispatch(saveLink(res.info.data.url));
          break;
        case 'email':
          setConfigs((configs) => ({
            ...configs,
            value: `MATMSG:TO:${qrConfigs.email.address};SUB:${qrConfigs.email.subject};BODY:${qrConfigs.email.message};;`,
          }));
          dispatch(saveEmail({ key: 'address', value: res.info.data.email }));
          dispatch(saveEmail({ key: 'subject', value: res.info.data.subject }));
          dispatch(saveEmail({ key: 'message', value: res.info.data.qr_call_text }));

          break;
        case 'sms':
          setConfigs((configs) => ({
            ...configs,
            value: `SMSTO:${qrConfigs.sms.number?.split(' ').join('')}:${qrConfigs.sms.message}`,
          }));
          dispatch(saveSms({ key: 'number', value: res.info.data.phone_number }));
          dispatch(saveSms({ key: 'message', value: res.info.data.message }));

          break;
        case 'call':
          setConfigs((configs) => ({
            ...configs,
            value: `${qrConfigs.call.number?.split(' ').join('')}`,
          }));
          dispatch(saveCall({ key: 'number', value: res.info.data.phone_number }));
          break;
        case 'whatsapp':
          setConfigs((configs) => ({
            ...configs,
            value: `https://wa.me/${qrConfigs.whatsapp.number?.split(' ').join('')}/?text=${
              qrConfigs.whatsapp.message
            }`,
          }));
          dispatch(saveWhatsapp({ key: 'number', value: res.info.data.whatsapp_phone_number }));
          dispatch(saveWhatsapp({ key: 'message', value: res.info.data.whatsapp_message }));

          break;
        case 'wifi':
          setConfigs((configs) => ({
            ...configs,
            value: `WIFI:S:${qrConfigs.wifi.ssid};T:${qrConfigs.wifi.networkType};P:${qrConfigs.wifi.wifiPsw};;`,
          }));
          dispatch(saveWifi({ key: 'ssid', value: res.info.data.ssid }));
          dispatch(saveWifi({ key: 'networkType', value: res.info.data.network_type }));
          dispatch(saveWifi({ key: 'wifiPsw', value: res.info.data.password }));

          break;
        case 'txt':
          setConfigs((configs) => ({
            ...configs,
            value: qrConfigs.txt,
          }));
          dispatch(saveText(res.info.data.text));

          break;
        case 'event':
          setConfigs((configs) => ({
            ...configs,
            value: `BEGIN:VEVENT\nSUMMARY:${qrConfigs.event.nameEvent}\nLOCATION:${qrConfigs.event.location}\nURL:${qrConfigs.event.url}\nDESCRIPTION:${qrConfigs.event.description}\nDTSTART:${qrConfigs.event.dataStart}00\nDTEND:${qrConfigs.event.dataEnd}00\nEND:VEVENT`,
          }));
          dispatch(saveEvent({ key: 'nameEvent', value: res.info.data.name }));
          dispatch(saveEvent({ key: 'location', value: res.info.data.location }));
          dispatch(saveEvent({ key: 'url', value: res.info.data.event_url }));
          dispatch(saveEvent({ key: 'description', value: res.info.data.event_description }));
          dispatch(saveEvent({ key: 'dataStart', value: res.info.data.date_time_start }));
          dispatch(saveEvent({ key: 'dataEnd', value: res.info.data.date_time_end }));

          break;
        case 'vcard':
          setConfigs((configs) => ({
            ...configs,
            value: `BEGIN:VCARD\nVERSION:2.1\nN;CHARSET=UTF-8:${qrConfigs.vcard.lastName};${
              qrConfigs.vcard.firstName
            }\nFN;CHARSET=UTF-8:${qrConfigs.vcard.firstName};${
              qrConfigs.vcard.lastName
            }\nTEL;CELL:${qrConfigs.vcard.mobile?.split(' ').join('')}\nTEL;HOME;VOICE:${qrConfigs.vcard.phoneNumber
              ?.split(' ')
              .join('')}\nORG;CHARSET=UTF-8:${qrConfigs.vcard.company}\nTITLE;CHARSET=UTF-8:${
              qrConfigs.vcard.jobTitle
            }\nTEL;WORK;FAX:${qrConfigs.vcard.fax}\nADR;CHARSET=UTF-8;WORK;PREF:;;${qrConfigs.vcard.address};${
              qrConfigs.vcard.city
            };${qrConfigs.vcard.postCode};${qrConfigs.vcard.country}\nEMAIL:${qrConfigs.vcard.email}\nURL:${
              qrConfigs.vcard.webSite
            }\nEND:VCARD`,
          }));
          dispatch(saveVcard({ key: 'firstName', value: res.info.data.firstname }));
          dispatch(saveVcard({ key: 'address', value: res.info.data.address }));
          dispatch(saveVcard({ key: 'city', value: res.info.data.city }));
          dispatch(saveVcard({ key: 'company', value: res.info.data.company }));
          dispatch(saveVcard({ key: 'country', value: res.info.data.country }));
          dispatch(saveVcard({ key: 'fax', value: res.info.data.fax }));
          dispatch(saveVcard({ key: 'jobTitle', value: res.info.data.job_title }));
          dispatch(saveVcard({ key: 'lastName', value: res.info.data.lastname }));
          dispatch(saveVcard({ key: 'postCode', value: res.info.data.postcode }));
          dispatch(saveVcard({ key: 'email', value: res.info.data.vcard_email }));
          dispatch(saveVcard({ key: 'mobile', value: res.info.data.vcard_mobile_number }));
          dispatch(saveVcard({ key: 'phoneNumber', value: res.info.data.vcard_phone_number }));
          dispatch(saveVcard({ key: 'webSite', value: res.info.data.website }));

          break;
        case 'app':
          setConfigs((configs) => ({
            ...configs,
            value: `${DISPATCHER_URL}/${res.mycode}`,
          }));
          dispatch(saveApp({ key: 'android', value: res.info.data.android_url }));
          dispatch(saveApp({ key: 'ios', value: res.info.data.ios_url }));
          dispatch(saveAppPreviewPage({ key: 'page_title', value: res.info.data.page_title }));
          dispatch(saveAppPreviewPage({ key: 'page_description', value: res.info.data.page_description }));
          dispatch(saveAppPreviewPage({ key: 'website', value: res.info.data.website }));
          break;
        case 'social':
          break;
        default:
          break;
      }

      if (behavior === 'previewPage') {
        // dispatch(toggleIsDynamic(false));
        // console.log('è preview page');
        switch (qrType) {
          case 'txt':
            setConfigs((configs) => ({
              ...configs,
              pageBackgroundColor: res.pageBackgroundColor,
              card_color: res.card_color,
              text_color: res.text_color,
              image_uploaded: res.image_uploaded,
            }));

            dispatch(saveTextColor({ key: 'pageColor', value: res.pageBackgroundColor }));
            dispatch(saveTextColor({ key: 'cardColor', value: res.card_color }));
            dispatch(saveTextColor({ key: 'textColor', value: res.text_color }));
            dispatch(saveAppPreviewPage({ key: 'logo', value: res.image_uploaded }));

            break;
          case 'vcard':
            setConfigs((configs) => ({
              ...configs,
              pageBackgroundColor: res.pageBackgroundColor,
              card_color: res.card_color,
              text_color: res.text_color,
              image_uploaded: res.image_uploaded,
            }));
            dispatch(saveVcard({ key: 'pageColor', value: res.pageBackgroundColor }));
            dispatch(saveVcard({ key: 'cardColor', value: res.card_color }));
            dispatch(saveVcard({ key: 'textColor', value: res.text_color }));
            dispatch(saveVcard({ key: 'logo', value: res.image_uploaded }));

            break;
          case 'event':
            // console.log('EVENT: ', configs);
            setConfigs((configs) => ({
              ...configs,
              pageBackgroundColor: res.pageBackgroundColor,
              card_color: res.card_color,
              text_color: res.text_color,
              image_uploaded: res.image_uploaded,
            }));
            dispatch(saveEvent({ key: 'pageColor', value: res.pageBackgroundColor }));
            dispatch(saveEvent({ key: 'cardColor', value: res.card_color }));
            dispatch(saveEvent({ key: 'textColor', value: res.text_color }));
            dispatch(saveEvent({ key: 'logo', value: res.image_uploaded }));

            break;
          case 'app':
            setConfigs((configs) => ({
              ...configs,
              value: `${DISPATCHER_URL}/${res.mycode}`,
              pageBackgroundColor: res.pageBackgroundColor,
              card_color: res.card_color,
              text_color: res.text_color,
              image_uploaded: res.image_uploaded,
            }));
            dispatch(saveAppPreviewPage({ key: 'pageColor', value: res.pageBackgroundColor }));
            dispatch(saveAppPreviewPage({ key: 'cardColor', value: res.card_color }));
            dispatch(saveAppPreviewPage({ key: 'textColor', value: res.text_color }));
            dispatch(saveAppPreviewPage({ key: 'logo', value: res.image_uploaded }));

            break;
          case 'social':
            // setConfigs((configs) => ({
            //   ...configs,
            //   pageBackgroundColor: res.pageBackgroundColor,
            //   card_color: res.card_color,
            //   text_color: res.text_color,
            //   image_uploaded: res.image_uploaded,
            // }));
            // res.socialPreviewPage.socials.map((social) => {
            //   setConfigs((configs) => ({
            //     ...configs,
            //     social_type: social.type,
            //     url: social.url,
            //   }));

            //   return configs;
            // });

            // dispatch(saveAppPreviewPage({ key: 'pageColor', value: res.pageBackgroundColor }))
            // dispatch(saveAppPreviewPage({ key: 'cardColor', value: res.card_color }))
            // dispatch(saveAppPreviewPage({ key: 'textColor', value: res.text_color }))
            // dispatch(saveAppPreviewPage({ key: 'logo', value: res.image_uploaded }))

            break;
          default:
        }
      }
      // console.log(configs);
    } catch (err) {
      console.error('Error getting user qrs', err);
    }
  };

  useEffect(() => {
    setConfigs((configs) => ({
      ...configs,
      value: qrCodeUrl,
    }));
  }, [qrCodeUrl]);

  const inputs = [
    { key: 'ecLevel', label: 'EC Level', type: 'select', options: ['M', 'L', 'Q', 'H'] }, // L | M | Q | H
    { key: 'size', label: 'Size', type: 'number' }, // number (in pixel)
    { key: 'quiteZone', label: 'Quite zone', type: 'number' }, // number (in pixel)
    { key: 'bgColor', label: 'Background Color', type: 'color' }, // string
    { key: 'fgColor', label: 'Frontground Color', type: 'color' }, // string
    { key: 'qrStyle', label: 'Qr style', type: 'select', options: ['squares', 'dots'] }, // squares | dots
    { key: 'eyeRadius', label: 'Qr eye radius', type: 'number' }, // CornerRadii | CornerRadii[]
    { key: 'eyeColor', label: 'Qr eye color', type: 'text' }, // EyeColor | EyeColor[]
    { key: 'logoImage', label: 'Logo url', type: 'text' }, // string (url | base64)
    { key: 'logoWidth', label: 'Logo size', type: 'number' }, // number (in pixel)
    { key: 'logoHeight', label: 'Logo height', type: 'number' }, // number (in pixel)
    { key: 'logoOpacity', label: 'Logo opacity', type: 'number' }, // number (css opacity 0 <= x <= 1)
    { key: 'enableCors', label: 'Enable CORS', type: 'checkbox' }, // boolean
    { key: 'removeQrCodeBehindLogo', label: 'Remove Qr code behind logo', type: 'checkbox' }, // boolean
    // { label: "id", type: "text" }, // string
  ];

  const downloadCode = () => {
    if (configs.logoImage === '') {
      const canvas = document.getElementById('qrcode');
      const dataURL = canvas.toDataURL('image/png');
      const link = document.createElement('a');
      link.href = dataURL;
      link.download = 'qrcode.png';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      const canvas = document.getElementById(`qrcode`);
      // console.log(configs.logoImage);
      if (canvas) {
        const context = canvas.getContext('2d');

        // Aggiungi il logo all'immagine del QR code
        const logoImage = new Image();
        logoImage.src = configs.logoImage;
        logoImage.onload = function () {
          // const logoSize = userQrs[i].configs.logoWidth; // Regola la dimensione del logo come preferisci
          // const logoX = userQrs[i].configs.logoWidth;
          // const logoY = userQrs[i].configs.logoHeight;

          // context.drawImage(logoImage, logoSize, logoSize, logoSize, logoSize);

          // Scarica il QR code con il logo incluso
          const pngUrl = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream');
          const downloadLink = document.createElement('a');
          downloadLink.href = pngUrl;
          downloadLink.download = `QR_Code.png`;
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
        };
      }
    }
  };

  const navigate = useNavigate();

  const newCode = useSelector((state) => state.qrConfigs.code);

  const handleClick = async () => {
    setLoading(true);
    console.log('newcode: ', newCode);
    try {
      const res = await updateQrData(
        {
          ...qrConfigs,
          userId: userLogged.id,
          type: qrType,
          code: format(new Date(), "ddMMyyyy'T'HHmmss"),
          behavior: qrConfigs.behavior,
          mycode: newCode,
        },
        qrId
      );
      // setLinks(res?.data);
      setShowAlert(true);
    } catch (err) {
      console.err(err);
    }
    setLoading(false);
  };

  // getDataQr();
  useEffect(() => {
    getDataQr();
  }, [qrId]);

  const Accordion = styled((props) => <MuiAccordion disableGutters elevation={0} square {...props} />)(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
  }));

  const AccordionSummary = styled((props) => (
    <MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />} {...props} />
  ))(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
      marginLeft: theme.spacing(1),
    },
  }));

  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
  }));

  const [expanded, setExpanded] = useState('panel1');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const [images, setImages] = useState([]);
  const maxNumber = 1;
  const onChange = (imageList, addUpdateIndex) => {
    // data for submit
    // console.log('IMMAGINE: ', imageList);
    setImages(imageList);
    setConfigs((configs) => ({
      ...configs,
      logoImage: imageList.length && imageList[0].data_url,
    }));
    dispatch(saveLogo({ key: 'logoImage', value: imageList[0].data_url }));
  };

  const stringCode = useSelector((state) => state.qrConfigs.code);

  const onChangeCode = (e) => {
    console.log('new code', e.target.value);
    dispatch(saveCode(e.target.value));
  };

  // console.log('behavior', behavior);
  // console.log('qrType', qrType);
  console.log('qrConfigs: ', qrConfigs);
  console.log('configs: ', configs);

  return (
    <Container maxWidth={themeStretch ? false : 'xl'}>
      {behavior === 'previewPage' ? (
        <Grid container spacing={2} style={{ marginTop: -100 }}>
          <Grid item xs={12}>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <Grid container spacing={2} maxWidth={1500}>
                <Grid item xs={12} md={5}>
                  <Card style={{ marginTop: '1.3rem' }}>
                    <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                      <AccordionSummary
                        aria-controls="panel1d-content"
                        id="panel1d-header"
                        style={{ color: 'black' ? PRIMARY_MAIN : 'unset' }}
                      >
                        <Typography>Type</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          <TableContainer>
                            <Table sx={{ margin: 1 }} aria-label="simple table">
                              {qrType === 'link' && <Link link={qrConfigs.link} />}
                              {qrType === 'sms' && <Sms />}
                              {qrType === 'email' && <EmailOk />}
                              {qrType === 'txt' && <TextOk />}
                              {qrType === 'call' && <Call />}
                              {qrType === 'event' && <Event />}
                              {qrType === 'whatsapp' && <Whatsapp />}
                              {qrType === 'wifi' && <Wifi />}
                              {qrType === 'social' && <Social />}
                              {qrType === 'app' && <App />}
                              {qrType === 'vcard' && <Vcard />}
                            </Table>
                          </TableContainer>
                        </Typography>
                      </AccordionDetails>
                    </Accordion>

                    <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                      <AccordionSummary
                        style={{ color: 'black' ? PRIMARY_MAIN : 'unset' }}
                        aria-controls="panel2d-content"
                        id="panel2d-header"
                      >
                        <Typography>Options</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          <Grid item xs={12} spacing={1} container style={{ marginTop: 13 }}>
                            {inputs
                              .filter((input) => input.type !== 'color' && !input.key.toLowerCase().includes('logo'))
                              .map((input, i) => (
                                <InputRenderer
                                  key={`qr-input-${i}`}
                                  input={input}
                                  inputId={i}
                                  configs={configs}
                                  setConfigs={setConfigs}
                                  onChange={(ev) => dispatch(saveOptions(ev.target.value))}
                                />
                              ))}
                          </Grid>
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                      <AccordionSummary
                        style={{ color: 'black' ? PRIMARY_MAIN : 'unset' }}
                        aria-controls="panel2d-content"
                        id="panel2d-header"
                      >
                        <Typography>Logo</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          <Grid item xs={12} spacing={1} container style={{ marginTop: 16 }}>
                            {inputs
                              .filter((input) => input.type !== 'color' && !!input.key.toLowerCase().includes('logo'))
                              .map((input, i) => (
                                <InputRenderer
                                  key={`qr-input-${i}`}
                                  input={input}
                                  inputId={i}
                                  configs={configs}
                                  setConfigs={setConfigs}
                                />
                              ))}
                            <Grid item xs={12} style={{ marginTop: 2, marginLeft: 12 }}>
                              <ImageUploading
                                multiple
                                value={images}
                                onChange={onChange}
                                maxNumber={maxNumber}
                                dataURLKey="data_url"
                              >
                                {({ imageList, onImageUpload, onImageUpdate, onImageRemove, dragProps }) => (
                                  // write your building UI
                                  <Grid>
                                    <Button
                                      style={{ marginBottom: 8, marginTop: 8 }}
                                      variant="outlined"
                                      startIcon={<PhotoCamera />}
                                      onClick={onImageUpload}
                                      {...dragProps}
                                    >
                                      Upload image
                                    </Button>
                                    &nbsp;
                                    {imageList.map((image, index) => (
                                      <Grid key={`qr-input-image`}>
                                        <img src={image.data_url} alt="" width="100" />
                                        <Grid marginTop={1}>
                                          <Button
                                            style={{ marginRight: -22 }}
                                            variant="text"
                                            startIcon={<UpgradeIcon />}
                                            onClick={() => onImageUpdate(index)}
                                          />

                                          <Button
                                            variant="text"
                                            startIcon={<DeleteIcon />}
                                            onClick={() => onImageRemove(index)}
                                          />
                                        </Grid>
                                      </Grid>
                                    ))}
                                  </Grid>
                                )}
                              </ImageUploading>
                            </Grid>
                          </Grid>
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                      <AccordionSummary
                        style={{ color: 'black' ? PRIMARY_MAIN : 'unset' }}
                        aria-controls="panel2d-content"
                        id="panel2d-header"
                      >
                        <Typography>Colors</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          <Grid>
                            <Grid
                              item
                              xs={12}
                              container
                              justifyContent={'space-evenly'}
                              style={{ marginTop: 15, marginBottom: 15 }}
                            >
                              {inputs
                                .filter((input) => input.key === 'bgColor')
                                .map((input, i) => (
                                  <Grid key={`qr-color-code-${i}`}>
                                    <a style={{ fontWeight: 'bold', textDecoration: 'none' }}> Background </a>
                                    <InputRenderer
                                      key={`qr-input-${i}`}
                                      input={input}
                                      inputId={i}
                                      configs={configs}
                                      setConfigs={setConfigs}
                                    />
                                  </Grid>
                                ))}
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              container
                              justifyContent={'space-evenly'}
                              style={{ marginTop: 15, marginBottom: 15 }}
                            >
                              {inputs
                                .filter((input) => input.key === 'fgColor')
                                .map((input, i) => (
                                  <Grid key={`qr-color-code-${i}`}>
                                    <a style={{ fontWeight: 'bold', textDecoration: 'none' }}> Qr Code </a>
                                    <InputRenderer
                                      key={`qr-input-${i}`}
                                      input={input}
                                      inputId={i}
                                      configs={configs}
                                      setConfigs={setConfigs}
                                    />
                                  </Grid>
                                ))}
                            </Grid>
                          </Grid>
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </Card>
                </Grid>
                <Grid item xs={12} md={7} container spacing={2} direction="column">
                  <Grid item container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <Card
                        style={{
                          padding: 6,
                          marginBottom: '0.5rem',
                          marginTop: '1.3rem',
                          marginLeft: 1,
                          minHeight: 625,
                        }}
                        sx={{ minHeight: 510 }}
                        align="center"
                      >
                        <div style={{ marginTop: '20%' }}>
                          <QRCode id="qrcode" {...configs} style={{ position: 'absolute', top: '20%', left: '50%' }} />
                          <p style={{ marginBottom: '2%', marginTop: '2%' }}>
                            <TextField
                              placeholder="Edit personal code"
                              label="Update your code here"
                              variant="outlined"
                              name="code"
                              style={{ width: 250 }}
                              value={stringCode}
                              // focused
                              onChange={onChangeCode}
                              color="primary"
                            />
                          </p>
                          <p>
                            <Tooltip title="Download" arrow>
                              <Button variant="contained" onClick={() => downloadCode()}>
                                <DownloadIcon />
                              </Button>
                            </Tooltip>
                            <Tooltip title="Update" arrow>
                              <Button
                                style={{ marginLeft: 5 }}
                                onClick={handleClick}
                                variant="contained"
                                disabled={loading}
                              >
                                <EditIcon />
                                {loading ? <CircularProgress color="success" /> : ''}
                              </Button>
                            </Tooltip>

                            {showAlert && (
                              <Alert
                                style={{ marginLeft: 1, maxWidth: 250, marginTop: 8 }}
                                severity="success"
                                onClose={() => setShowAlert(false)}
                              >
                                QR code updated!
                              </Alert>
                            )}
                          </p>
                        </div>
                      </Card>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Card style={{ padding: 16, marginBottom: 10, marginLeft: 10, marginTop: '1.3rem' }}>
                        <PrevievOption input={qrType} configs={configs} setConfigs={setConfigs} />
                      </Card>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      ) : (
        <Grid container spacing={2} style={{ marginTop: -100 }}>
          <Grid item xs={6}>
            <Card style={{ marginTop: 35 }}>
              <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                <AccordionSummary
                  aria-controls="panel1d-content"
                  id="panel1d-header"
                  style={{ color: 'black' ? PRIMARY_MAIN : 'unset' }}
                >
                  <Typography>Type</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <TableContainer>
                      <Table sx={{ margin: 1 }} aria-label="simple table">
                        {qrType === 'link' && <Link link={qrConfigs.link} />}
                        {qrType === 'sms' && <Sms />}
                        {qrType === 'email' && <EmailOk />}
                        {qrType === 'txt' && <TextOk />}
                        {qrType === 'call' && <Call />}
                        {qrType === 'event' && <Event />}
                        {qrType === 'whatsapp' && <Whatsapp />}
                        {qrType === 'wifi' && <Wifi />}
                        {qrType === 'social' && <Social />}
                        {qrType === 'app' && <App />}
                        {qrType === 'vcard' && <Vcard />}
                      </Table>
                    </TableContainer>
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                <AccordionSummary
                  style={{ color: 'black' ? PRIMARY_MAIN : 'unset' }}
                  aria-controls="panel2d-content"
                  id="panel2d-header"
                >
                  <Typography>Options</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <Grid item xs={12} spacing={1} container style={{ marginTop: 13 }}>
                      {inputs
                        .filter((input) => input.type !== 'color' && !input.key.toLowerCase().includes('logo'))
                        .map((input, i) => (
                          <InputRenderer
                            key={`qr-input-${i}`}
                            input={input}
                            inputId={i}
                            configs={configs}
                            setConfigs={setConfigs}
                            onChange={(ev) => dispatch(saveOptions(ev.target.value))}
                          />
                        ))}
                    </Grid>
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                <AccordionSummary
                  style={{ color: 'black' ? PRIMARY_MAIN : 'unset' }}
                  aria-controls="panel2d-content"
                  id="panel2d-header"
                >
                  <Typography>Logo</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <Grid item xs={12} spacing={1} container style={{ marginTop: 16 }}>
                      {inputs
                        .filter((input) => input.type !== 'color' && !!input.key.toLowerCase().includes('logo'))
                        .map((input, i) => (
                          <InputRenderer
                            key={`qr-input-${i}`}
                            input={input}
                            inputId={i}
                            configs={configs}
                            setConfigs={setConfigs}
                          />
                        ))}
                      <Grid item xs={12} style={{ marginTop: 2, marginLeft: 12 }}>
                        <ImageUploading
                          multiple
                          value={images}
                          onChange={onChange}
                          maxNumber={maxNumber}
                          dataURLKey="data_url"
                        >
                          {({ imageList, onImageUpload, onImageUpdate, onImageRemove, dragProps }) => (
                            // write your building UI
                            <Grid>
                              <Button
                                style={{ marginBottom: 8, marginTop: 8 }}
                                variant="outlined"
                                startIcon={<PhotoCamera />}
                                onClick={onImageUpload}
                                {...dragProps}
                              >
                                Upload image
                              </Button>
                              &nbsp;
                              {imageList.map((image, index) => (
                                <Grid key={`qr-input-image`}>
                                  <img src={image.data_url} alt="" width="100" />
                                  <Grid marginTop={1}>
                                    <Button
                                      style={{ marginRight: -22 }}
                                      variant="text"
                                      startIcon={<UpgradeIcon />}
                                      onClick={() => onImageUpdate(index)}
                                    />

                                    <Button
                                      variant="text"
                                      startIcon={<DeleteIcon />}
                                      onClick={() => onImageRemove(index)}
                                    />
                                  </Grid>
                                </Grid>
                              ))}
                            </Grid>
                          )}
                        </ImageUploading>
                      </Grid>
                    </Grid>
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                <AccordionSummary
                  style={{ color: 'black' ? PRIMARY_MAIN : 'unset' }}
                  aria-controls="panel2d-content"
                  id="panel2d-header"
                >
                  <Typography>Colors</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <Grid>
                      <Grid
                        item
                        xs={12}
                        container
                        justifyContent={'space-evenly'}
                        style={{ marginTop: 15, marginBottom: 15 }}
                      >
                        {inputs
                          .filter((input) => input.key === 'bgColor')
                          .map((input, i) => (
                            <Grid key={`qr-color-code-${i}`}>
                              <a style={{ fontWeight: 'bold', textDecoration: 'none' }}> Background </a>
                              <InputRenderer
                                key={`qr-input-${i}`}
                                input={input}
                                inputId={i}
                                configs={configs}
                                setConfigs={setConfigs}
                              />
                            </Grid>
                          ))}
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        container
                        justifyContent={'space-evenly'}
                        style={{ marginTop: 15, marginBottom: 15 }}
                      >
                        {inputs
                          .filter((input) => input.key === 'fgColor')
                          .map((input, i) => (
                            <Grid key={`qr-color-code-${i}`}>
                              <a style={{ fontWeight: 'bold', textDecoration: 'none' }}> Qr Code </a>
                              <InputRenderer
                                key={`qr-input-${i}`}
                                input={input}
                                inputId={i}
                                configs={configs}
                                setConfigs={setConfigs}
                              />
                            </Grid>
                          ))}
                      </Grid>
                    </Grid>
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Card>
          </Grid>
          <Grid item xs={6}>
            <Card
              style={{
                padding: 6,
                marginBottom: '0.5rem',
                marginTop: '2.3rem',
                marginLeft: 18,
              }}
              sx={{ minHeight: 510 }}
              align="center"
            >
              <div style={{ marginTop: '20%' }}>
                <QRCode id="qrcode" {...configs} style={{ position: 'absolute', top: '20%', left: '50%' }} />
                <p style={{ marginBottom: '2%', marginTop: '2%' }}>
                  <TextField
                    placeholder="Edit personal code"
                    label="Update your code here"
                    variant="outlined"
                    name="code"
                    style={{ width: 250 }}
                    value={stringCode}
                    // focused
                    onChange={onChangeCode}
                    color="primary"
                  />
                </p>
                <p>
                  <Tooltip title="Download" arrow>
                    <Button variant="contained" onClick={() => downloadCode()}>
                      <DownloadIcon />
                    </Button>
                  </Tooltip>
                  <Tooltip title="Save" arrow>
                    <Button style={{ marginLeft: 5 }} onClick={handleClick} variant="contained" disabled={loading}>
                      <Save />
                      {loading ? <CircularProgress color="success" /> : ''}
                    </Button>
                  </Tooltip>

                  {showAlert && (
                    <Alert
                      style={{ marginLeft: 1, maxWidth: 250, marginTop: 8 }}
                      severity="success"
                      onClose={() => setShowAlert(false)}
                    >
                      QR code updated!
                    </Alert>
                  )}
                </p>
              </div>
            </Card>
          </Grid>
        </Grid>
      )}
    </Container>
  );
}
