import { Container, Select, Grid, Card, TextField, Button, FormControl, InputLabel } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';

import Avatar from '../assets/avatar.png';

export const PrevievVcardOption = ({ props }) => {
  const { input, configs } = props;

  // vcard
  const firstName = useSelector((state) => state.qrConfigs.vcard.firstName);
  const lastName = useSelector((state) => state.qrConfigs.vcard.lastName);
  const phoneNumber = useSelector((state) => state.qrConfigs.vcard.phoneNumber);
  const mobile = useSelector((state) => state.qrConfigs.vcard.mobile);
  const email = useSelector((state) => state.qrConfigs.vcard.email);
  const webSite = useSelector((state) => state.qrConfigs.vcard.webSite);
  const company = useSelector((state) => state.qrConfigs.vcard.company);
  const jobTitle = useSelector((state) => state.qrConfigs.vcard.jobTitle);
  const address = useSelector((state) => state.qrConfigs.vcard.address);
  const city = useSelector((state) => state.qrConfigs.vcard.city);
  const postCode = useSelector((state) => state.qrConfigs.vcard.postCode);
  const fax = useSelector((state) => state.qrConfigs.vcard.fax);
  const country = useSelector((state) => state.qrConfigs.vcard.country);
  const pageColorVcard = useSelector((state) => state.qrConfigs.vcard.pageColor);
  const cardColorVcard = useSelector((state) => state.qrConfigs.vcard.cardColor);
  const textColorVcard = useSelector((state) => state.qrConfigs.vcard.textColor);
  const avatar = useSelector((state) => state.qrConfigs.vcard.avatar);

  return (
    <>
      <div
        style={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          backgroundColor: pageColorVcard,
          justifyContent: 'center',
        }}
      >
        {(!!avatar && (
          <img
            src={avatar}
            alt="avatar"
            style={{
              position: 'absolute',
              top: 50,
              width: 100,
              height: 100,
              borderRadius: '50%',
              border: `4px solid ${pageColorVcard}`,
              zIndex: 1000,
            }}
          />
        )) || (
          <img
            src={Avatar}
            alt="logo"
            style={{
              position: 'absolute',
              top: 50,
              width: 100,
              height: 100,
              borderRadius: '50%',
              border: `4px solid ${pageColorVcard}`,
              zIndex: 1000,
            }}
          />
        )}
        <Card
          style={{
            // overflow: 'scroll',
            padding: 16,
            backgroundColor: cardColorVcard,
            width: 247,
            height: 370,
            marginTop: 35,
            // marginLeft: 38,
          }}
        >
          <a
            style={{
              fontWeight: 'bold',
              fontSize: 25,
              flex: 1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              color: textColorVcard,
              marginTop: 35,
            }}
          >
            {lastName || 'Anna'} {firstName || 'Verdi'}
          </a>
          <div
            style={{
              fontSize: 15,
              marginBottom: 30,
              flex: 1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              color: textColorVcard,
            }}
          >
            {jobTitle || 'Web developer'}
          </div>
          <div
            style={{
              fontSize: 15,
              color: textColorVcard,
            }}
          >
            <a
              style={{
                fontWeight: 'bold',
                color: textColorVcard,
              }}
            >
              Phone:
            </a>{' '}
            {phoneNumber || '+391234567890'}
          </div>
          <div
            style={{
              fontSize: 15,
              color: textColorVcard,
            }}
          >
            <a
              style={{
                fontWeight: 'bold',
                color: textColorVcard,
              }}
            >
              Mobile:
            </a>{' '}
            {mobile || '+391234567890'}
          </div>
          <div
            style={{
              fontSize: 15,
              color: textColorVcard,
            }}
          >
            <a
              style={{
                fontWeight: 'bold',
                color: textColorVcard,
              }}
            >
              Email:
            </a>{' '}
            {email || 'ciao@gmail.com'}
          </div>
          <div
            style={{
              fontSize: 15,
              color: textColorVcard,
            }}
          >
            <a
              style={{
                fontWeight: 'bold',
                color: textColorVcard,
              }}
            >
              WebSite:{' '}
            </a>
            {webSite || 'https://ciao.it'}
          </div>
          <div
            style={{
              fontSize: 15,
              color: textColorVcard,
            }}
          >
            <a
              style={{
                fontWeight: 'bold',
                color: textColorVcard,
              }}
            >
              Company:{' '}
            </a>
            {company || 'The Cove'}
          </div>

          <div
            style={{
              fontSize: 15,
              color: textColorVcard,
            }}
          >
            <a
              style={{
                fontWeight: 'bold',
                color: textColorVcard,
              }}
            >
              Address:{' '}
            </a>
            {address || 'via Enrico Fermi'} ({city || 'TO'}) {postCode || '98765'} {country || 'Italy'}
          </div>
          <div
            style={{
              fontSize: 15,
              color: textColorVcard,
            }}
          >
            <a
              style={{
                fontWeight: 'bold',
                color: textColorVcard,
              }}
            >
              Fax:{' '}
            </a>
            {fax || '+392345678901'}
          </div>
        </Card>
        <div style={{ marginTop: 10 }}>
          <Button variant="contained"> Open in contacts</Button>
        </div>
      </div>
    </>
  );
};
