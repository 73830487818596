/* eslint-disable no-nested-ternary */
import React from 'react';

import {
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Switch,
  TextField,
  Select,
} from '@mui/material';
import { TwitterPicker } from 'react-color';
import { useDispatch, useSelector } from 'react-redux';
import { saveColors, saveLogo, saveOptions } from '../reducer/qr/configs';

export const InputRenderer = (props) => {
  // console.log('props: ', props);
  const { input, inputId, configs, setConfigs } = props;

  const dispatch = useDispatch();

  function handleSelectChange(data, key) {
    // console.log('KEY: ', input.key);

    setConfigs((configs) => ({
      ...configs,
      [key]: data,
    }));

    dispatch(saveOptions({ key: input.key, value: data }));
  }

  function handleCheckChange(data, key) {
    setConfigs((configs) => ({
      ...configs,
      [key]: data,
    }));

    if (key === 'removeQrCodeBehindLogo') {
      dispatch(saveLogo({ key: input.key, value: data }));
    } else {
      dispatch(saveOptions({ key: input.key, value: data }));
    }
  }

  function handleTextChange(data, key, type) {
    // console.log('DATA: ', data, key, type);

    setConfigs((configs) => ({
      ...configs,
      [key]: type === 'number' ? Number(data) : data,
    }));

    if (key.includes('logo')) {
      dispatch(saveLogo({ key: input.key, value: data }));
    } else {
      dispatch(saveOptions({ key: input.key, value: data }));
    }
  }

  if (input.key === 'removeQrCodeBehindLogo') {
    console.log('configs2', configs);
    console.log('value', configs[input.key]);
  }

  return (
    <Grid item xs={6}>
      {input.type === 'select' ? (
        <FormControl fullWidth>
          <InputLabel id={`platform-select-label-${inputId}`}>{input.label}</InputLabel>
          <Select
            labelId={`platform-select-label-${inputId}`}
            id={`platform-select-${inputId}`}
            label={input.label}
            value={configs[input.key]}
            onChange={(e) => handleSelectChange(e.target.value, input.key)}
          >
            {input.options?.map((opt, i) => (
              <MenuItem key={`platform-option-${inputId}-${i}`} value={opt}>
                {opt}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ) : input.type === 'checkbox' ? (
        <FormGroup>
          <FormControlLabel
            control={
              <Switch checked={!!configs[input.key]} onChange={(e) => handleCheckChange(e.target.checked, input.key)} />
            }
            label={input.label}
          />
        </FormGroup>
      ) : input.type === 'color' ? (
        <TwitterPicker
          triangle="hide"
          colors={[
            'transparent',
            '#FFFFFF',
            '#000000',
            '#22194D',
            '#0693E3',
            '#8ED1FC',
            '#ABB8C3',
            '#800080',
            '#9900EF',
            '#F78DA7',
            '#EB144C',
            '#FF0000',
            '#A52A2A',
            '#FFA500',
            '#FFFF00',
            '#008000',
            '#00D084',
          ]}
          color={configs[input.key]}
          onChange={(ev) => dispatch(saveColors({ key: input.key, value: ev.hex }))}
          onChangeComplete={(color) =>
            dispatch(
              setConfigs((configs) => ({
                ...configs,
                [input.key]: color.hex,
              }))
            )
          }
        />
      ) : (
        <TextField
          label={input.label}
          variant="outlined"
          placeholder={input.label}
          fullWidth
          type={input.type}
          value={configs[input.key]}
          onChange={(e) => handleTextChange(e.target.value, input.key, input.type)}
        />
      )}
    </Grid>
  );
};
