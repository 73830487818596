import { useEffect, useState, useCallback, useMemo } from 'react';
import { Grid, Stack, debounce } from '@mui/material';
// import { Edit, Delete, Email } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import MuiPhoneNumber from 'mui-phone-number';
import { saveCall } from '../reducer/qr/configs';

export const Call = () => {
  // const [value, setValue] = useState('');
  const [number, setValue] = useState(useSelector((state) => state.qrConfigs.call.number));
  const dispatch = useDispatch();

  const handleSaveCall = useCallback(
    (e) => {
      dispatch(saveCall({ key: 'number', value: e }));
    },
    [dispatch]
  );

  const debouncedSendRequest = useMemo(() => debounce(handleSaveCall, 1000), [handleSaveCall]);

  const onChange = (e) => {
    setValue(e);
    // const { value } = e;
    console.log('call:', e);
    debouncedSendRequest(e);
  };

  return (
    <>
      <Stack>
        <Grid container spacing={2} style={{ marginBottom: 0 }}>
          <a style={{ padding: 14, fontWeight: 'bold', fontSize: 24, marginLeft: 20, marginTop: 12 }}>Phone Number</a>
        </Grid>
        <a style={{ marginLeft: 20, fontStyle: 'italic', marginTop: 3 }}>Select coutry code and insert phone number</a>
        <MuiPhoneNumber
          style={{ marginLeft: 20, marginTop: 28, marginRight: 60 }}
          defaultCountry={'it'}
          regions={'europe'}
          value={number}
          onChange={onChange}
          // onChange={(e) => onChange(e)}
        />

        <a style={{ fontSize: 13, marginTop: 104, marginLeft: 20 }}>
          Scanning the QR code will call this phone number.
        </a>
      </Stack>
    </>
  );
};
