import { useCallback, useMemo, useState } from 'react';
import { Grid, TextField, Stack, debounce } from '@mui/material';
// import { Edit, Delete, Email } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { saveEmail } from '../reducer/qr/configs';

export const EmailOk = () => {
  const [address, setAddress] = useState(useSelector((state) => state.qrConfigs.email.address));
  const [subject, setSubject] = useState(useSelector((state) => state.qrConfigs.email.subject));
  const [message, setMessage] = useState(useSelector((state) => state.qrConfigs.email.message));

  const dispatch = useDispatch();

  const handleSaveAddress = useCallback(
    (address) => {
      dispatch(saveEmail({ key: 'address', value: address }));
    },
    [dispatch]
  );
  const handleSaveSubject = useCallback(
    (subject) => {
      dispatch(saveEmail({ key: 'subject', value: subject }));
    },
    [dispatch]
  );
  const handleSaveMessage = useCallback(
    (message) => {
      dispatch(saveEmail({ key: 'message', value: message }));
    },
    [dispatch]
  );

  const debouncedSendRequestAddress = useMemo(() => debounce(handleSaveAddress, 1000), [handleSaveAddress]);
  const debouncedSendRequestSubject = useMemo(() => debounce(handleSaveSubject, 1000), [handleSaveSubject]);
  const debouncedSendRequestMessage = useMemo(() => debounce(handleSaveMessage, 1000), [handleSaveMessage]);

  const onChangeAddress = (e) => {
    // const { value } = e.target;
    console.log('E: ', e.target.value);
    setAddress(e.target.value);
    debouncedSendRequestAddress(e.target.value);
  };

  const onChangeSubject = (e) => {
    // const { value } = e.target;
    setSubject(e.target.value);
    debouncedSendRequestSubject(e.target.value);
  };
  const onChangeMessage = (e) => {
    // const { value } = e.target;
    setMessage(e.target.value);
    debouncedSendRequestMessage(e.target.value);
  };

  return (
    <Stack>
      <Grid container spacing={2} style={{ marginBottom: 0 }}>
        <a style={{ padding: 13, fontWeight: 'bold', fontSize: 24, marginLeft: 20, marginTop: 12 }}>E-mail Content</a>
        <TextField
          fullWidth
          style={{ marginTop: 4, marginLeft: 30, marginRight: 20 }}
          // id="outlined-basic"
          label="Your Email Address"
          variant="outlined"
          defaultValue={address}
          onChange={onChangeAddress}
        />{' '}
        <TextField
          fullWidth
          onChange={onChangeSubject}
          style={{ marginTop: 12, marginLeft: 30, marginRight: 20 }}
          id="outlined-basic"
          label="Subject Of Email"
          variant="outlined"
          defaultValue={subject}
        />{' '}
        <TextField
          fullWidth
          onChange={onChangeMessage}
          style={{ marginTop: 12, marginLeft: 30, marginRight: 20 }}
          id="outlined-basic"
          label="Message"
          variant="outlined"
          defaultValue={message}
        />{' '}
        <a style={{ fontSize: 13, marginLeft: 35, marginTop: 35 }}>Scanning the QR code will send this email. </a>
      </Grid>
    </Stack>
  );
};
