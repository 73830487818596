import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  url: '',
  link: '',
  isDynamic: false,
  sms: {
    number: '+39',
    message: '',
  },
  call: {
    number: '+39',
  },
  email: {
    address: '',
    subject: '',
    message: '',
  },
  whatsapp: {
    number: '+39',
    message: '',
  },
  txt: '',
  wifi: {
    ssid: '',
    networkType: '',
    wifiPsw: '',
  },
  vcard: {
    firstName: '',
    lastName: '',
    phoneNumber: '',
    mobile: '',
    email: '',
    webSite: '',
    company: '',
    jobTitle: '',
    address: '',
    city: '',
    postCode: '',
    fax: '',
    country: '',
    pageColor: 'rgb(255, 191, 237)',
    cardColor: 'white',
    textColor: 'black',
    logo: '',
  },
  event: {
    logo: '',
    nameEvent: '',
    location: '',
    url: '',
    description: '',
    dataStart: '',
    dataEnd: '',
    timeStart: '',
    timeEnd: '',
    pageColor: 'rgb(239, 214, 232)',
    cardColor: 'white',
    textColor: 'black',
  },
  app: {
    ios: '',
    android: '',
  },
  appPreviewPage: {
    logo: '',
    pageColor: 'rgb(19, 10, 123)',
    cardColor: 'white',
    textColor: 'black',
    appName: '',
    description: '',
    website: '',
  },
  socialPreviewPage: {
    pageColor: 'rgb(135, 128, 211)',
    cardColor: 'white',
    textColor: 'black',
    socialName: '',
    socials: [
      { id: 1, type: 'facebook', url: 'https://facebook.com' },
      { id: 2, type: 'instagram', url: 'https://instagram.com' },
      { id: 3, type: 'linkedin', url: 'https://linkedin.com' },
      { id: 4, type: 'twitter', url: 'https://twitter.com' },
    ],
  },
  textColor: {
    pageColor: 'white',
    cardColor: 'rgb(233, 197, 242)',
    textColor: 'black',
  },
  options: {
    ecLevel: 'M',
    size: 200,
    quiteZone: 0,
    qrStyle: 'squares',
    eyeRadius: 0,
    eyeColor: '',
    enableCors: false,
  },
  colors: {
    bgColor: 'white',
    fgColor: 'black',
  },
  logo: {
    logoImage: '',
    logoWidth: 50,
    logoHeight: 50,
    logoOpacity: 1,
    removeQrCodeBehindLogo: false,
  },
  behavior: 'direct',
  editQr: false,
  type: '',
  code: '',
};

export const qrConfigsSlice = createSlice({
  name: 'qrConfigs',
  initialState,
  reducers: {
    toggleIsDynamic: (state) => {
      // console.log('toggled', state);
      state.isDynamic = !state.isDynamic;
    },
    saveUrl: (state, action) => {
      console.log('save url', action.payload);
      state.url = action.payload;
    },
    saveLink: (state, action) => {
      state.link = action.payload;
    },
    saveCode: (state, action) => {
      state.code = action.payload;
    },
    saveSms: (state, action) => {
      state.sms = {
        ...state.sms,
        [action.payload.key]: action.payload.value,
      };
    },
    saveCall: (state, action) => {
      state.call = {
        ...state.call,
        [action.payload.key]: action.payload.value,
      };
    },
    saveEmail: (state, action) => {
      state.email = {
        ...state.email,
        [action.payload.key]: action.payload.value,
      };
    },
    saveText: (state, action) => {
      state.txt = action.payload;
    },
    saveTextColor: (state, action) => {
      state.textColor = {
        ...state.textColor,
        [action.payload.key]: action.payload.value,
      };
    },
    saveWhatsapp: (state, action) => {
      state.whatsapp = {
        ...state.whatsapp,
        [action.payload.key]: action.payload.value,
      };
    },
    saveWifi: (state, action) => {
      state.wifi = {
        ...state.wifi,
        [action.payload.key]: action.payload.value,
      };
    },
    saveVcard: (state, action) => {
      state.vcard = {
        ...state.vcard,
        [action.payload.key]: action.payload.value,
      };
    },
    saveEvent: (state, action) => {
      // console.log(state, action);
      state.event = {
        ...state.event,
        [action.payload.key]: action.payload.value,
      };
    },
    saveApp: (state, action) => {
      state.app = {
        ...state.app,
        [action.payload.key]: action.payload.value,
      };
    },
    saveAppPreviewPage: (state, action) => {
      state.appPreviewPage = {
        ...state.appPreviewPage,
        [action.payload.key]: action.payload.value,
      };
    },
    saveSocialPreviewPage: (state, action) => {
      state.socialPreviewPage = {
        ...state.socialPreviewPage,
        [action.payload.key]: action.payload.value,
      };
    },
    saveSocialPreviewPageSocials: (state, action) => {
      state.socialPreviewPage = {
        ...state.socialPreviewPage,
        socials: state.socialPreviewPage.socials.map((social) => {
          if (social.id === action.payload.id) {
            return {
              ...social,
              url: action.payload.url,
            };
          }
          return social;
        }),
      };
    },
    addSocialPreviewPageSocials: (state, action) => {
      state.socialPreviewPage = {
        ...state.socialPreviewPage,
        socials: [
          ...state.socialPreviewPage.socials,
          { ...action.payload, id: state.socialPreviewPage.socials.length + 2 },
        ].sort((a, b) => (a.type < b.type ? -1 : 1)),
      };
    },
    deleteSocialPreviewPageSocials: (state, action) => {
      state.socialPreviewPage = {
        ...state.socialPreviewPage,
        socials: state.socialPreviewPage.socials.filter((social) => social.id !== action.payload),
      };
    },
    saveOptions: (state, action) => {
      state.options = {
        ...state.options,
        [action.payload.key]: action.payload.value,
      };
    },
    saveColors: (state, action) => {
      state.colors = {
        ...state.colors,
        [action.payload.key]: action.payload.value,
      };
    },
    saveLogo: (state, action) => {
      state.logo = {
        ...state.logo,
        [action.payload.key]: action.payload.value,
      };
    },
    saveBehavior: (state, action) => {
      state.behavior = action.payload;
    },
    saveEditQr: (state, action) => {
      state.editQr = action.payload;
    },
    saveType: (state, action) => {
      state.type = action.payload;
    },
    // reset: (state) => {
    //   console.log('Torno allo stato iniziale ', state);
    //   state = initialState;
    // },
    reset: (state) => {
      Object.keys(initialState).forEach((key) => {
        state[key] = initialState[key];
      });
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  saveUrl,
  saveSms,
  saveLink,
  saveCall,
  saveEmail,
  saveText,
  saveWhatsapp,
  saveWifi,
  saveVcard,
  saveEvent,
  saveApp,
  toggleIsDynamic,
  saveAppPreviewPage,
  saveSocialPreviewPage,
  saveTextColor,
  saveSocialPreviewPageSocials,
  addSocialPreviewPageSocials,
  deleteSocialPreviewPageSocials,
  saveOptions,
  saveColors,
  saveLogo,
  saveBehavior,
  reset,
  saveEditQr,
  saveType,
  saveCode,
} = qrConfigsSlice.actions;

export default qrConfigsSlice.reducer;
