import { configureStore } from '@reduxjs/toolkit';
import qrConfigsReducers from './qr/configs';
import userReducer from './user';

export default configureStore({
  reducer: {
    qrConfigs: qrConfigsReducers,
    user: userReducer,
  },
});
