import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Grid, TextField, Stack, Card, Button } from '@mui/material';
import UpgradeIcon from '@mui/icons-material/Upgrade';
import DeleteIcon from '@mui/icons-material/Delete';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import AndroidIcon from '@mui/icons-material/Android';
import AppleIcon from '@mui/icons-material/Apple';
import ImageUploading from 'react-images-uploading';
import { MuiColorInput } from 'mui-color-input';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { saveAppPreviewPage, saveApp } from '../reducer/qr/configs';

export const App = () => {
  const ios = useSelector((state) => state.qrConfigs.app.ios);
  const android = useSelector((state) => state.qrConfigs.app.android);
  const logoImage = useSelector((state) => state.qrConfigs.appPreviewPage.logo);
  const pageColor = useSelector((state) => state.qrConfigs.appPreviewPage.pageColor);
  const appName = useSelector((state) => state.qrConfigs.appPreviewPage.appName);
  const description = useSelector((state) => state.qrConfigs.appPreviewPage.description);
  const website = useSelector((state) => state.qrConfigs.appPreviewPage.website);

  const dispatch = useDispatch();
  const [color, setColor] = useState(useSelector((state) => state.qrConfigs.appPreviewPage.pageColor));
  const [colorCard, setColorCard] = useState(useSelector((state) => state.qrConfigs.appPreviewPage.cardColor));
  const [colorText, setColorText] = useState(useSelector((state) => state.qrConfigs.appPreviewPage.textColor));

  const isDynamic = useSelector((state) => state.qrConfigs.isDynamic);
  const behavior = useSelector((state) => state.qrConfigs.behavior);

  const [images, setImages] = useState();
  const maxNumber = 1;

  const onChange = (imageList) => {
    // data for submit
    setImages(imageList);
    dispatch(saveAppPreviewPage({ key: 'logo', value: imageList[0].data_url }));
  };

  function rgbToHex(rgbColor) {
    // Rimuovi il prefisso "rgb(" e la parentesi chiusa ")"
    const rgbValues = rgbColor.substring(4, rgbColor.length - 1).split(', ');

    // Converte i valori RGB in esadecimali
    const r = parseInt(rgbValues[0], 10).toString(16).padStart(2, '0');
    const g = parseInt(rgbValues[1], 10).toString(16).padStart(2, '0');
    const b = parseInt(rgbValues[2], 10).toString(16).padStart(2, '0');

    // Restituisci il colore esadecimale
    return `#${r}${g}${b}`;
  }

  const handleChange = (color) => {
    const colorEx = rgbToHex(color);

    setColor(colorEx);
    dispatch(saveAppPreviewPage({ key: 'pageColor', value: colorEx }));
  };
  const handleChangeCard = (color) => {
    const colorEx = rgbToHex(color);

    setColorCard(colorEx);
    dispatch(saveAppPreviewPage({ key: 'cardColor', value: colorEx }));
  };
  const handleChangeText = (color) => {
    const colorEx = rgbToHex(color);

    setColorText(colorEx);
    dispatch(saveAppPreviewPage({ key: 'textColor', value: colorEx }));
  };

  return (
    <>
      <Stack>
        <a style={{ padding: 14, fontWeight: 'bold', fontSize: 30, marginBottom: -20 }}>APP</a>
        {behavior === 'previewPage' && (
          <>
            <Grid container spacing={3} marginTop={0.5}>
              <Grid item xs={4}>
                <a style={{ padding: 8, fontWeight: 'bold', fontSize: 20, marginTop: 12 }}>Page color</a>{' '}
              </Grid>
              <Grid item xs={4}>
                <a style={{ padding: 8, fontWeight: 'bold', fontSize: 20, marginTop: 12 }}>Card color</a>{' '}
              </Grid>
              <Grid item xs={4}>
                <a style={{ padding: 8, fontWeight: 'bold', fontSize: 20, marginTop: 12 }}>Text color</a>{' '}
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={4}>
                <MuiColorInput value={color} onChange={handleChange} style={{ marginLeft: 0 }} />
              </Grid>
              <Grid item xs={4}>
                <MuiColorInput value={colorCard} onChange={handleChangeCard} style={{ marginLeft: 0 }} />
              </Grid>
              <Grid item xs>
                <MuiColorInput value={colorText} onChange={handleChangeText} style={{ marginLeft: 0 }} />
              </Grid>
            </Grid>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} marginTop={1} alignItems={'center'}>
              <Grid item xs={6}>
                <a style={{ padding: 8, fontWeight: 'bold', fontSize: 20, marginTop: 12 }}>Page information</a>
              </Grid>
              <Grid item xs={6}>
                <a style={{ padding: 8, fontWeight: 'bold', fontSize: 20, marginTop: 12 }}>Logo </a>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  style={{ marginTop: -3, marginLeft: 0, marginBottom: 15, paddingRight: 50 }}
                  id="outlined-basic"
                  label="App's name"
                  variant="outlined"
                  value={appName}
                  onChange={(ev) => dispatch(saveAppPreviewPage({ key: 'appName', value: ev.target.value }))}
                />{' '}
              </Grid>
              <Grid item xs={6}>
                <Grid style={{ marginTop: -13, marginLeft: 5 }}>
                  <ImageUploading
                    multiple
                    value={images}
                    onChange={onChange}
                    maxNumber={maxNumber}
                    dataURLKey="data_url"
                  >
                    {({
                      imageList,
                      onImageUpload,
                      onImageRemoveAll,
                      onImageUpdate,
                      onImageRemove,
                      isDragging,
                      dragProps,
                    }) => (
                      // write your building UI
                      <Grid>
                        <Button
                          style={{ marginBottom: 28, marginTop: 8, marginLeft: 21 }}
                          variant="outlined"
                          startIcon={<PhotoCamera />}
                          onClick={onImageUpload}
                          {...dragProps}
                        >
                          Upload image
                        </Button>
                        &nbsp;
                        {/* <Button variant="outlined" startIcon={<DeleteIcon />} onClick={onImageRemoveAll}>
                              Remove All images
                            </Button> */}
                        {imageList.map((image, index) => (
                          <Grid>
                            <img src={image.data_url} alt="" width="100" />
                            <Grid marginTop={1}>
                              <Button
                                style={{ marginRight: -22 }}
                                variant="text"
                                startIcon={<UpgradeIcon />}
                                onClick={() => onImageUpdate(index)}
                              />

                              <Button variant="text" startIcon={<DeleteIcon />} onClick={() => onImageRemove(index)} />
                            </Grid>
                          </Grid>
                        ))}
                      </Grid>
                    )}
                  </ImageUploading>
                </Grid>{' '}
              </Grid>
            </Grid>

            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              <Grid item xs={6}>
                <a style={{ padding: 8, fontWeight: 'bold', fontSize: 20, marginTop: 12 }}>Description</a>{' '}
              </Grid>
              <Grid item xs={6}>
                <a style={{ padding: 8, fontWeight: 'bold', fontSize: 20, marginTop: 12 }}>Website</a>{' '}
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  style={{ marginLeft: 0, marginBottom: 21, paddingRight: 50 }}
                  id="outlined-basic"
                  label="Description"
                  variant="outlined"
                  value={description}
                  onChange={(ev) => dispatch(saveAppPreviewPage({ key: 'description', value: ev.target.value }))}
                />{' '}
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  style={{ marginLeft: 0, paddingRight: 50 }}
                  id="outlined-basic"
                  label="Website(URL)"
                  variant="outlined"
                  value={website}
                  onChange={(ev) => dispatch(saveAppPreviewPage({ key: 'website', value: ev.target.value }))}
                />{' '}
              </Grid>
            </Grid>
          </>
        )}
        {/* <a style={{ padding: 14, fontWeight: 'bold', fontSize: 24, marginTop: -15, paddingRight: 50 }}>
          App links
        </a> */}
        <a style={{ padding: 14, fontWeight: 'normal', fontSize: 16 }}>
          <AppleIcon style={{ marginRight: 8 }} />
          App Store
        </a>
        <p />
        <TextField
          fullWidth
          style={{ marginTop: -4, marginLeft: 8, paddingRight: 32 }}
          id="outlined-basic"
          label="iOS App URL"
          variant="outlined"
          value={ios}
          onChange={(ev) => dispatch(saveApp({ key: 'ios', value: ev.target.value }))}
        />{' '}
        <a style={{ padding: 14, fontWeight: 'normal', fontSize: 16 }}>
          {' '}
          <AndroidIcon style={{ marginRight: 8 }} />
          Google Play Store
        </a>
        <p />
        <TextField
          fullWidth
          style={{ marginTop: -4, marginLeft: 8, paddingRight: 32 }}
          id="outlined-basic"
          label="Android App URL"
          variant="outlined"
          value={android}
          onChange={(ev) => dispatch(saveApp({ key: 'android', value: ev.target.value }))}
        />{' '}
        <a style={{ fontSize: 13, marginTop: 38, marginLeft: 35 }}>Download apps Android & iOS. </a>
      </Stack>
    </>
  );
};
