import { useEffect, useState, useMemo, useCallback } from 'react';
import { Grid, TextField, Stack, debounce } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import useForm from 'react-hook-form';
import { MuiColorInput } from 'mui-color-input';
import TableRow from '@mui/material/TableRow';
// import { CKEditor } from '../@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
// import CKEditorInspector from '@ckeditor/ckeditor5-inspector';
import { saveText, saveTextColor, toggleIsDynamic } from '../reducer/qr/configs';

export const TextOk = () => {
  // const txt = useSelector((state) => state.qrConfigs.txt);
  const txt = useSelector((state) => state.qrConfigs.txt);

  const dispatch = useDispatch();

  const handleSaveLink = useCallback(
    (value) => {
      dispatch(saveText(value));
    },
    [dispatch]
  );

  const debouncedSendRequest = useMemo(() => debounce(handleSaveLink, 1000), [handleSaveLink]);

  const onChange = (e) => {
    const { value } = e.target;
    debouncedSendRequest(value);
  };

  const isDynamic = useSelector((state) => state.qrConfigs.isDynamic);
  const behavior = useSelector((state) => state.qrConfigs.behavior);

  const color = useSelector((state) => state.qrConfigs.textColor.pageColor);
  const [colorCard, setColorCard] = useState(useSelector((state) => state.qrConfigs.textColor.cardColor));
  const [colorText, setColorText] = useState(useSelector((state) => state.qrConfigs.textColor.textColor));

  function rgbToHex(rgbColor) {
    // Rimuovi il prefisso "rgb(" e la parentesi chiusa ")"
    const rgbValues = rgbColor.substring(4, rgbColor.length - 1).split(', ');

    // Converte i valori RGB in esadecimali
    const r = parseInt(rgbValues[0], 10).toString(16).padStart(2, '0');
    const g = parseInt(rgbValues[1], 10).toString(16).padStart(2, '0');
    const b = parseInt(rgbValues[2], 10).toString(16).padStart(2, '0');

    // Restituisci il colore esadecimale
    return `#${r}${g}${b}`;
  }

  const handleChange = (color) => {
    const colorEx = rgbToHex(color);
    dispatch(saveTextColor({ key: 'pageColor', value: colorEx }));
  };
  const handleChangeCard = (color) => {
    const colorEx = rgbToHex(color);
    setColorCard(colorEx);
    dispatch(saveTextColor({ key: 'cardColor', value: colorEx }));
  };
  const handleChangeText = (color) => {
    const colorEx = rgbToHex(color);
    setColorText(colorEx);
    dispatch(saveTextColor({ key: 'textColor', value: colorEx }));
  };

  return (
    <>
      <Stack>
        <Grid container spacing={2} style={{ marginBottom: 0 }}>
          <a style={{ padding: 14, fontWeight: 'bold', fontSize: 24, marginLeft: 20, marginTop: 12 }}>Text</a>
          <TextField
            fullWidth
            label="Write your text here"
            variant="outlined"
            style={{ marginLeft: 30, marginRight: 20 }}
            id="outlined-basic"
            defaultValue={txt}
            onChange={onChange}
          />{' '}
          {behavior === 'previewPage' && (
            <Grid>
              <Grid container spacing={3} marginTop={0.5}>
                <Grid item xs={4}>
                  <a style={{ padding: 8, fontWeight: 'bold', fontSize: 20, marginLeft: 20, marginTop: 12 }}>
                    Page color
                  </a>{' '}
                </Grid>
                <Grid item xs={4}>
                  <a style={{ padding: 8, fontWeight: 'bold', fontSize: 20, marginLeft: 20, marginTop: 12 }}>
                    Card color
                  </a>{' '}
                </Grid>
                <Grid item xs={4}>
                  <a style={{ padding: 8, fontWeight: 'bold', fontSize: 20, marginLeft: 20, marginTop: 12 }}>
                    Text color
                  </a>{' '}
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={4}>
                  <MuiColorInput value={color} onChange={handleChange} style={{ marginLeft: 25 }} />
                </Grid>
                <Grid item xs={4}>
                  <MuiColorInput value={colorCard} onChange={handleChangeCard} style={{ marginLeft: 25 }} />
                </Grid>
                <Grid item xs>
                  <MuiColorInput value={colorText} onChange={handleChangeText} style={{ marginLeft: 25 }} />
                </Grid>
              </Grid>
            </Grid>
          )}
          <a style={{ fontSize: 13, marginTop: 80, marginLeft: 35 }}>Scanning the QR code will show this text. </a>
        </Grid>
      </Stack>
    </>
  );
};
