// components
import QrCodeIcon from '@mui/icons-material/QrCode';
import StarRateIcon from '@mui/icons-material/StarRate';
import SettingsIcon from '@mui/icons-material/Settings';
import SvgIconStyle from '../../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  user: getIcon('ic_user'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'general v3.5.0',
    items: [
      // { title: 'Settings', path: '/dashboard/settings', icon: ICONS.dashboard },
      { title: 'QR Code Creator', path: '/dashboard/qr', icon: <QrCodeIcon />, auth: false },
      // { title: 'Analytics', path: '/dashboard/analytics', icon: ICONS.analytics, auth: true },
      { title: 'Saved QR Codes', path: '/dashboard/saving', icon: <StarRateIcon />, auth: true },
      { title: 'User settings', path: '/dashboard/user/settings', icon: <SettingsIcon />, auth: true },
      // { title: 'Three', path: '/dashboard/three', icon: ICONS.analytics, auth: true },
    ],
  },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  // {
  //   subheader: 'management',
  //   items: [
  //     {
  //       title: 'user',
  //       path: '/dashboard/user',
  //       icon: <SettingsIcon />,
  //       auth: true,
  //       children: [
  //         { title: 'Settings', path: '/dashboard/user/settings' },
  //         // { title: 'Five', path: '/dashboard/user/five', auth: true },
  //         // { title: 'Six', path: '/dashboard/user/six', auth: true },
  //       ],
  //     },
  //   ],
  // },
];

export default navConfig;
