import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation, Link } from 'react-router-dom';
// layouts
import { UserSettings } from '@clerk/clerk-react';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// components
import LoadingScreen from '../components/LoadingScreen';
import UpdateQr from '../pages/UpdateQr';
import AnalyticsQr from '../pages/AnalyticsQr';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: <Navigate to="/dashboard/qr" replace />,
    },
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/qr" replace />, index: true },
        { path: 'qr', element: <QR /> },
        { path: 'qr/:id', element: <QR /> },
        { path: 'analytics', element: <PageAnalytics /> },
        { path: 'saving', element: <PageSaving /> },
        { path: 'three', element: <PageThree /> },
        { path: 'test', element: <Test /> },
        {
          path: 'saving/:id',
          element: <UpdateQr />,
        },
        {
          path: 'analytics/:id',
          element: <AnalyticsQr />,
        },
        {
          path: 'user',
          children: [
            { element: <Navigate to="/dashboard/user/settings" replace />, index: true },
            { path: 'settings', element: <PageSettings /> },
            { path: 'five', element: <PageFive /> },
            { path: 'six', element: <PageSix /> },
          ],
        },
      ],
    },
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// Dashboard
const PageAnalytics = Loadable(lazy(() => import('../pages/PageAnalytics')));
const PageSaving = Loadable(lazy(() => import('../pages/PageSaving')));
const PageThree = Loadable(lazy(() => import('../pages/PageThree')));
const PageSettings = Loadable(lazy(() => import('../pages/PageSettings')));
const PageFive = Loadable(lazy(() => import('../pages/PageFive')));
const PageSix = Loadable(lazy(() => import('../pages/PageSix')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
const QR = Loadable(lazy(() => import('../pages/QR')));
const Test = Loadable(lazy(() => import('../pages/Test')));
