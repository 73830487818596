import { Card, Grid, CardHeader } from '@mui/material';
import { useState, useEffect, React, useRef } from 'react';
import { ZoomableGroup, ComposableMap, Geographies, Geography } from 'react-simple-maps';
import { useParams } from 'react-router-dom';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import * as d3 from 'd3';
import * as topojson from 'topojson-client';
import useSettings from '../hooks/useSettings';
import 'react-tooltip/dist/react-tooltip.css';
import { getDataByQrId } from '../communication/CommunicationController';

const geoUrl = 'https://cdn.jsdelivr.net/npm/world-atlas@2/countries-110m.json';

const WorldMap = () => {
  const { themeStretch } = useSettings();
  const initialCountry = [
    { country: 'AF', count: 0 },
    { country: 'AX', count: 0 },
    { country: 'AL', count: 0 },
    { country: 'DZ', count: 0 },
    { country: 'AS', count: 0 },
    { country: 'AD', count: 0 },
    { country: 'AO', count: 0 },
    { country: 'AI', count: 0 },
    { country: 'AQ', count: 0 },
    { country: 'AG', count: 0 },
    { country: 'AR', count: 0 },
    { country: 'AM', count: 0 },
    { country: 'AW', count: 0 },
    { country: 'AU', count: 0 },
    { country: 'AT', count: 0 },
    { country: 'AZ', count: 0 },
    { country: 'BS', count: 0 },
    { country: 'BH', count: 0 },
    { country: 'BD', count: 0 },
    { country: 'BB', count: 0 },
    { country: 'BY', count: 0 },
    { country: 'BE', count: 0 },
    { country: 'BZ', count: 0 },
    { country: 'BJ', count: 0 },
    { country: 'BM', count: 0 },
    { country: 'BT', count: 0 },
    { country: 'BO', count: 0 },
    { country: 'BQ', count: 0 },
    { country: 'BA', count: 0 },
    { country: 'BW', count: 0 },
    { country: 'BV', count: 0 },
    { country: 'BR', count: 0 },
    { country: 'IO', count: 0 },
    { country: 'BN', count: 0 },
    { country: 'BG', count: 0 },
    { country: 'BF', count: 0 },
    { country: 'BI', count: 0 },
    { country: 'CV', count: 0 },
    { country: 'KH', count: 0 },
    { country: 'CM', count: 0 },
    { country: 'CA', count: 0 },
    { country: 'KY', count: 0 },
    { country: 'CF', count: 0 },
    { country: 'TD', count: 0 },
    { country: 'CL', count: 0 },
    { country: 'CN', count: 0 },
    { country: 'CX', count: 0 },
    { country: 'CC', count: 0 },
    { country: 'CO', count: 0 },
    { country: 'KM', count: 0 },
    { country: 'CG', count: 0 },
    { country: 'CD', count: 0 },
    { country: 'CK', count: 0 },
    { country: 'CR', count: 0 },
    { country: 'CI', count: 0 },
    { country: 'HR', count: 0 },
    { country: 'CU', count: 0 },
    { country: 'CW', count: 0 },
    { country: 'CY', count: 0 },
    { country: 'CZ', count: 0 },
    { country: 'DK', count: 0 },
    { country: 'DJ', count: 0 },
    { country: 'DM', count: 0 },
    { country: 'DO', count: 0 },
    { country: 'EC', count: 0 },
    { country: 'EG', count: 0 },
    { country: 'SV', count: 0 },
    { country: 'GQ', count: 0 },
    { country: 'ER', count: 0 },
    { country: 'EE', count: 0 },
    { country: 'SZ', count: 0 },
    { country: 'ET', count: 0 },
    { country: 'FK', count: 0 },
    { country: 'FO', count: 0 },
    { country: 'FJ', count: 0 },
    { country: 'FI', count: 0 },
    { country: 'FR', count: 0 },
    { country: 'GF', count: 0 },
    { country: 'PF', count: 0 },
    { country: 'TF', count: 0 },
    { country: 'GA', count: 0 },
    { country: 'GM', count: 0 },
    { country: 'GE', count: 0 },
    { country: 'DE', count: 0 },
    { country: 'GH', count: 0 },
    { country: 'GI', count: 0 },
    { country: 'GR', count: 0 },
    { country: 'GL', count: 0 },
    { country: 'GD', count: 0 },
    { country: 'GP', count: 0 },
    { country: 'GU', count: 0 },
    { country: 'GT', count: 0 },
    { country: 'GG', count: 0 },
    { country: 'GN', count: 0 },
    { country: 'GW', count: 0 },
    { country: 'GY', count: 0 },
    { country: 'HT', count: 0 },
    { country: 'HM', count: 0 },
    { country: 'VA', count: 0 },
    { country: 'HN', count: 0 },
    { country: 'HK', count: 0 },
    { country: 'HU', count: 0 },
    { country: 'IS', count: 0 },
    { country: 'IN', count: 0 },
    { country: 'ID', count: 0 },
    { country: 'IR', count: 0 },
    { country: 'IQ', count: 0 },
    { country: 'IE', count: 0 },
    { country: 'IM', count: 0 },
    { country: 'IL', count: 0 },
    { country: 'IT', count: 0 },
    { country: 'JM', count: 0 },
    { country: 'JP', count: 0 },
    { country: 'JE', count: 0 },
    { country: 'JO', count: 0 },
    { country: 'KZ', count: 0 },
    { country: 'KE', count: 0 },
    { country: 'KI', count: 0 },
    { country: 'KP', count: 0 },
    { country: 'KR', count: 0 },
    { country: 'KW', count: 0 },
    { country: 'KG', count: 0 },
    { country: 'LA', count: 0 },
    { country: 'LV', count: 0 },
    { country: 'LB', count: 0 },
    { country: 'LS', count: 0 },
    { country: 'LR', count: 0 },
    { country: 'LY', count: 0 },
    { country: 'LI', count: 0 },
    { country: 'LT', count: 0 },
    { country: 'LU', count: 0 },
    { country: 'MO', count: 0 },
    { country: 'MG', count: 0 },
    { country: 'MW', count: 0 },
    { country: 'MY', count: 0 },
    { country: 'MV', count: 0 },
    { country: 'ML', count: 0 },
    { country: 'MT', count: 0 },
    { country: 'MH', count: 0 },
    { country: 'MQ', count: 0 },
    { country: 'MR', count: 0 },
    { country: 'MU', count: 0 },
    { country: 'YT', count: 0 },
    { country: 'MX', count: 0 },
    { country: 'FM', count: 0 },
    { country: 'MD', count: 0 },
    { country: 'MC', count: 0 },
    { country: 'MN', count: 0 },
    { country: 'ME', count: 0 },
    { country: 'MS', count: 0 },
    { country: 'MA', count: 0 },
    { country: 'MZ', count: 0 },
    { country: 'MM', count: 0 },
    { country: 'NA', count: 0 },
    { country: 'NR', count: 0 },
    { country: 'NP', count: 0 },
    { country: 'NL', count: 0 },
    { country: 'NC', count: 0 },
    { country: 'NZ', count: 0 },
    { country: 'NI', count: 0 },
    { country: 'NE', count: 0 },
    { country: 'NG', count: 0 },
    { country: 'NU', count: 0 },
    { country: 'NF', count: 0 },
    { country: 'MK', count: 0 },
    { country: 'MP', count: 0 },
    { country: 'NO', count: 0 },
    { country: 'OM', count: 0 },
    { country: 'PK', count: 0 },
    { country: 'PW', count: 0 },
    { country: 'PS', count: 0 },
    { country: 'PA', count: 0 },
    { country: 'PG', count: 0 },
    { country: 'PY', count: 0 },
    { country: 'PE', count: 0 },
    { country: 'PH', count: 0 },
    { country: 'PN', count: 0 },
    { country: 'PL', count: 0 },
    { country: 'PT', count: 0 },
    { country: 'PR', count: 0 },
    { country: 'QA', count: 0 },
    { country: 'RE', count: 0 },
    { country: 'RO', count: 0 },
    { country: 'RU', count: 0 },
    { country: 'RW', count: 0 },
    { country: 'BL', count: 0 },
    { country: 'SH', count: 0 },
    { country: 'KN', count: 0 },
    { country: 'LC', count: 0 },
    { country: 'MF', count: 0 },
    { country: 'PM', count: 0 },
    { country: 'VC', count: 0 },
    { country: 'WS', count: 0 },
    { country: 'SM', count: 0 },
    { country: 'ST', count: 0 },
    { country: 'SA', count: 0 },
    { country: 'SN', count: 0 },
    { country: 'RS', count: 0 },
    { country: 'SC', count: 0 },
    { country: 'SL', count: 0 },
    { country: 'SG', count: 0 },
    { country: 'SX', count: 0 },
    { country: 'SK', count: 0 },
    { country: 'SI', count: 0 },
    { country: 'SB', count: 0 },
    { country: 'SO', count: 0 },
    { country: 'ZA', count: 0 },
    { country: 'GS', count: 0 },
    { country: 'SS', count: 0 },
    { country: 'ES', count: 0 },
    { country: 'LK', count: 0 },
    { country: 'SD', count: 0 },
    { country: 'SR', count: 0 },
    { country: 'SJ', count: 0 },
    { country: 'SE', count: 0 },
    { country: 'CH', count: 0 },
    { country: 'SY', count: 0 },
    { country: 'TW', count: 0 },
    { country: 'TJ', count: 0 },
    { country: 'TZ', count: 0 },
    { country: 'TH', count: 0 },
    { country: 'TL', count: 0 },
    { country: 'TG', count: 0 },
    { country: 'TK', count: 0 },
    { country: 'TO', count: 0 },
    { country: 'TT', count: 0 },
    { country: 'TN', count: 0 },
    { country: 'TR', count: 0 },
    { country: 'TM', count: 0 },
    { country: 'TC', count: 0 },
    { country: 'TV', count: 0 },
    { country: 'UG', count: 0 },
    { country: 'UA', count: 0 },
    { country: 'AE', count: 0 },
    { country: 'GB', count: 0 },
    { country: 'US', count: 0 },
    { country: 'UM', count: 0 },
    { country: 'UY', count: 0 },
    { country: 'UZ', count: 0 },
    { country: 'VU', count: 0 },
    { country: 'VE', count: 0 },
    { country: 'VN', count: 0 },
    { country: 'VG', count: 0 },
    { country: 'VI', count: 0 },
    { country: 'WF', count: 0 },
    { country: 'EH', count: 0 },
    { country: 'YE', count: 0 },
    { country: 'ZM', count: 0 },
    { country: 'ZW', count: 0 },
  ];

  const [regions, setRegions] = useState(initialCountry);
  const queryParams = useParams();
  const qrId = queryParams?.id;
  const [analytics, setAnalytics] = useState();
  const svgRef = useRef(null);

  const [map, setMap] = useState();

  const width = 1500;
  const height = 900;

  const handleSetMap = () => {
    const projection = d3
      .geoMercator()
      .scale(170)
      .center([2.454071, 46.279229])
      .translate([width / 2, height / 2]);

    const path = d3.geoPath().projection(projection);
    // console.log('path: ', path);

    const zoom = d3
      .zoom()
      .scaleExtent([1, 8]) // Imposta i limiti di zoom consentiti
      .on('zoom', zoomed);

    const svg = d3.select('#svg-world-map').append('svg').attr('width', width).attr('height', height);

    function zoomed(event) {
      g.attr('transform', event.transform);
    }

    const g = svg.append('g');

    d3.json('https://unpkg.com/world-atlas@1/world/110m.json').then((data) => {
      if (!regions) return;
      const tooltip = g.append('g').attr('class', 'tooltip').style('display', 'none');
      const countries = topojson.feature(data, data.objects.countries);

      tooltip
        .append('rect')
        .attr('width', 180)
        .attr('height', 78)
        .attr('rx', 4)
        .attr('ry', 4)
        .attr('fill', 'white')
        .style('opacity', 0.9)
        .style('stroke', '#ccc');
      tooltip.append('text').attr('x', 8).attr('y', 25).style('font-size', '14px').style('font-weight', 'bold');
      tooltip
        .append('text')
        .classed('text2', true)
        .attr('x', 8)
        .attr('y', 50)
        .style('font-size', '14px')
        .style('font-weight', 'bold');

      const countryCode = [
        { name: 'Afghanistan', alpha2: 'AF', countrycode: '004' },
        { name: 'Åland Islands', alpha2: 'AX', countrycode: '248' },
        { name: 'Albania', alpha2: 'AL', countrycode: '008' },
        { name: 'Algeria', alpha2: 'DZ', countrycode: '012' },
        { name: 'American Samoa', alpha2: 'AS', countrycode: '016' },
        { name: 'Andorra', alpha2: 'AD', countrycode: '020' },
        { name: 'Angola', alpha2: 'AO', countrycode: '024' },
        { name: 'Anguilla', alpha2: 'AI', countrycode: '660' },
        { name: 'Antarctica', alpha2: 'AQ', countrycode: '010' },
        { name: 'Antigua and Barbuda', alpha2: 'AG', countrycode: '028' },
        { name: 'Argentina', alpha2: 'AR', countrycode: '032' },
        { name: 'Armenia', alpha2: 'AM', countrycode: '051' },
        { name: 'Aruba', alpha2: 'AW', countrycode: '533' },
        { name: 'Australia', alpha2: 'AU', countrycode: '036' },
        { name: 'Austria', alpha2: 'AT', countrycode: '040' },
        { name: 'Azerbaijan', alpha2: 'AZ', countrycode: '031' },
        { name: 'Bahamas', alpha2: 'BS', countrycode: '044' },
        { name: 'Bahrain', alpha2: 'BH', countrycode: '048' },
        { name: 'Bangladesh', alpha2: 'BD', countrycode: '050' },
        { name: 'Barbados', alpha2: 'BB', countrycode: '052' },
        { name: 'Belarus', alpha2: 'BY', countrycode: '112' },
        { name: 'Belgium', alpha2: 'BE', countrycode: '056' },
        { name: 'Belize', alpha2: 'BZ', countrycode: '084' },
        { name: 'Benin', alpha2: 'BJ', countrycode: '204' },
        { name: 'Bermuda', alpha2: 'BM', countrycode: '060' },
        { name: 'Bhutan', alpha2: 'BT', countrycode: '064' },
        { name: 'Bolivia', alpha2: 'BO', countrycode: '068' },
        { name: 'Bonaire', alpha2: 'BQ', countrycode: '535' },
        { name: 'Bosnia and Herzegovina', alpha2: 'BA', countrycode: '070' },
        { name: 'Botswana', alpha2: 'BW', countrycode: '072' },
        { name: 'Bouvet Island', alpha2: 'BV', countrycode: '074' },
        { name: 'Brazil', alpha2: 'BR', countrycode: '076' },
        { name: 'British Indian Ocean Territory', alpha2: 'IO', countrycode: '086' },
        { name: 'Brunei Darussalam', alpha2: 'BN', countrycode: '096' },
        { name: 'Bulgaria', alpha2: 'BG', countrycode: '100' },
        { name: 'Burkina Faso', alpha2: 'BF', countrycode: '854' },
        { name: 'Burundi', alpha2: 'BI', countrycode: '108' },
        { name: 'Cabo Verde', alpha2: 'CV', countrycode: '132' },
        { name: 'Cambodia', alpha2: 'KH', countrycode: '116' },
        { name: 'Cameroon', alpha2: 'CM', countrycode: '120' },
        { name: 'Canada', alpha2: 'CA', countrycode: '124' },
        { name: 'Cayman Islands', alpha2: 'KY', countrycode: '136' },
        { name: 'Central African Republic', alpha2: 'CF', countrycode: '140' },
        { name: 'Chad', alpha2: 'TD', countrycode: '148' },
        { name: 'Chile', alpha2: 'CL', countrycode: '152' },
        { name: 'China', alpha2: 'CN', countrycode: '156' },
        { name: 'Christmas Island', alpha2: 'CX', countrycode: '162' },
        { name: 'Cocos (Keeling) Islands', alpha2: 'CC', countrycode: '166' },
        { name: 'Colombia', alpha2: 'CO', countrycode: '170' },
        { name: 'Comoros', alpha2: 'KM', countrycode: '174' },
        { name: 'Congo', alpha2: 'CG', countrycode: '178' },
        { name: 'Congo', alpha2: 'CD', countrycode: '180' },
        { name: 'Cook Islands', alpha2: 'CK', countrycode: '184' },
        { name: 'Costa Rica', alpha2: 'CR', countrycode: '188' },
        { name: "Côte d'Ivoire", alpha2: 'CI', countrycode: '384' },
        { name: 'Croatia', alpha2: 'HR', countrycode: '191' },
        { name: 'Cuba', alpha2: 'CU', countrycode: '192' },
        { name: 'Curaçao', alpha2: 'CW', countrycode: '531' },
        { name: 'Cyprus', alpha2: 'CY', countrycode: '196' },
        { name: 'Czechia', alpha2: 'CZ', countrycode: '203' },
        { name: 'Denmark', alpha2: 'DK', countrycode: '208' },
        { name: 'Djibouti', alpha2: 'DJ', countrycode: '262' },
        { name: 'Dominica', alpha2: 'DM', countrycode: '212' },
        { name: 'Dominican Republic', alpha2: 'DO', countrycode: '214' },
        { name: 'Ecuador', alpha2: 'EC', countrycode: '218' },
        { name: 'Egypt', alpha2: 'EG', countrycode: '818' },
        { name: 'El Salvador', alpha2: 'SV', countrycode: '222' },
        { name: 'Equatorial Guinea', alpha2: 'GQ', countrycode: '226' },
        { name: 'Eritrea', alpha2: 'ER', countrycode: '232' },
        { name: 'Estonia', alpha2: 'EE', countrycode: '233' },
        { name: 'Eswatini', alpha2: 'SZ', countrycode: '748' },
        { name: 'Ethiopia', alpha2: 'ET', countrycode: '231' },
        { name: 'Falkland Islands', alpha2: 'FK', countrycode: '238' },
        { name: 'Faroe Islands', alpha2: 'FO', countrycode: '234' },
        { name: 'Fiji', alpha2: 'FJ', countrycode: '242' },
        { name: 'Finland', alpha2: 'FI', countrycode: '246' },
        { name: 'France', alpha2: 'FR', countrycode: '250' },
        { name: 'French Guiana', alpha2: 'GF', countrycode: '254' },
        { name: 'French Polynesia', alpha2: 'PF', countrycode: '258' },
        { name: 'French Southern Territories', alpha2: 'TF', countrycode: '260' },
        { name: 'Gabon', alpha2: 'GA', countrycode: '266' },
        { name: 'Gambia', alpha2: 'GM', countrycode: '270' },
        { name: 'Georgia', alpha2: 'GE', countrycode: '268' },
        { name: 'Germany', alpha2: 'DE', countrycode: '276' },
        { name: 'Ghana', alpha2: 'GH', countrycode: '288' },
        { name: 'Gibraltar', alpha2: 'GI', countrycode: '292' },
        { name: 'Greece', alpha2: 'GR', countrycode: '300' },
        { name: 'Greenland', alpha2: 'GL', countrycode: '304' },
        { name: 'Grenada', alpha2: 'GD', countrycode: '308' },
        { name: 'Guadeloupe', alpha2: 'GP', countrycode: '312' },
        { name: 'Guam', alpha2: 'GU', countrycode: '316' },
        { name: 'Guatemala', alpha2: 'GT', countrycode: '320' },
        { name: 'Guernsey', alpha2: 'GG', countrycode: '831' },
        { name: 'Guinea', alpha2: 'GN', countrycode: '324' },
        { name: 'GuineaBissau', alpha2: 'GW', countrycode: '624' },
        { name: 'Guyana', alpha2: 'GY', countrycode: '328' },
        { name: 'Haiti', alpha2: 'HT', countrycode: '332' },
        { name: 'Heard Island and McDonald Islands', alpha2: 'HM', countrycode: '334' },
        { name: 'Holy See', alpha2: 'VA', countrycode: '336' },
        { name: 'Honduras', alpha2: 'HN', countrycode: '340' },
        { name: 'Hong Kong', alpha2: 'HK', countrycode: '344' },
        { name: 'Hungary', alpha2: 'HU', countrycode: '348' },
        { name: 'Iceland', alpha2: 'IS', countrycode: '352' },
        { name: 'India', alpha2: 'IN', countrycode: '356' },
        { name: 'Indonesia', alpha2: 'ID', countrycode: '360' },
        { name: 'Iran', alpha2: 'IR', countrycode: '364' },
        { name: 'Iraq', alpha2: 'IQ', countrycode: '368' },
        { name: 'Ireland', alpha2: 'IE', countrycode: '372' },
        { name: 'Isle of Man', alpha2: 'IM', countrycode: '833' },
        { name: 'Israel', alpha2: 'IL', countrycode: '376' },
        { name: 'Italy', alpha2: 'IT', countrycode: '380' },
        { name: 'Jamaica', alpha2: 'JM', countrycode: '388' },
        { name: 'Japan', alpha2: 'JP', countrycode: '392' },
        { name: 'Jersey', alpha2: 'JE', countrycode: '832' },
        { name: 'Jordan', alpha2: 'JO', countrycode: '400' },
        { name: 'Kazakhstan', alpha2: 'KZ', countrycode: '398' },
        { name: 'Kenya', alpha2: 'KE', countrycode: '404' },
        { name: 'Kiribati', alpha2: 'KI', countrycode: '296' },
        { name: 'Korea ', alpha2: 'KP', countrycode: '408' },
        { name: 'Korea, Republic of', alpha2: 'KR', countrycode: '410' },
        { name: 'Kuwait', alpha2: 'KW', countrycode: '414' },
        { name: 'Kyrgyzstan', alpha2: 'KG', countrycode: '417' },
        { name: "Lao People's Democratic Republic", alpha2: 'LA', countrycode: '418' },
        { name: 'Latvia', alpha2: 'LV', countrycode: '428' },
        { name: 'Lebanon', alpha2: 'LB', countrycode: '422' },
        { name: 'Lesotho', alpha2: 'LS', countrycode: '426' },
        { name: 'Liberia', alpha2: 'LR', countrycode: '430' },
        { name: 'Libya', alpha2: 'LY', countrycode: '434' },
        { name: 'Liechtenstein', alpha2: 'LI', countrycode: '438' },
        { name: 'Lithuania', alpha2: 'LT', countrycode: '440' },
        { name: 'Luxembourg', alpha2: 'LU', countrycode: '442' },
        { name: 'Macao', alpha2: 'MO', countrycode: '446' },
        { name: 'Madagascar', alpha2: 'MG', countrycode: '450' },
        { name: 'Malawi', alpha2: 'MW', countrycode: '454' },
        { name: 'Malaysia', alpha2: 'MY', countrycode: '458' },
        { name: 'Maldives', alpha2: 'MV', countrycode: '462' },
        { name: 'Mali', alpha2: 'ML', countrycode: '466' },
        { name: 'Malta', alpha2: 'MT', countrycode: '470' },
        { name: 'Marshall Islands', alpha2: 'MH', countrycode: '584' },
        { name: 'Martinique', alpha2: 'MQ', countrycode: '474' },
        { name: 'Mauritania', alpha2: 'MR', countrycode: '478' },
        { name: 'Mauritius', alpha2: 'MU', countrycode: '480' },
        { name: 'Mayotte', alpha2: 'YT', countrycode: '175' },
        { name: 'Mexico', alpha2: 'MX', countrycode: '484' },
        { name: 'Micronesia', alpha2: 'FM', countrycode: '583' },
        { name: 'Moldova', alpha2: 'MD', countrycode: '498' },
        { name: 'Monaco', alpha2: 'MC', countrycode: '492' },
        { name: 'Mongolia', alpha2: 'MN', countrycode: '496' },
        { name: 'Montenegro', alpha2: 'ME', countrycode: '499' },
        { name: 'Montserrat', alpha2: 'MS', countrycode: '500' },
        { name: 'Morocco', alpha2: 'MA', countrycode: '504' },
        { name: 'Mozambique', alpha2: 'MZ', countrycode: '508' },
        { name: 'Myanmar', alpha2: 'MM', countrycode: '104' },
        { name: 'Namibia', alpha2: 'NA', countrycode: '516' },
        { name: 'Nauru', alpha2: 'NR', countrycode: '520' },
        { name: 'Nepal', alpha2: 'NP', countrycode: '524' },
        { name: 'Netherlands', alpha2: 'NL', countrycode: '528' },
        { name: 'New Caledonia', alpha2: 'NC', countrycode: '540' },
        { name: 'New Zealand', alpha2: 'NZ', countrycode: '554' },
        { name: 'Nicaragua', alpha2: 'NI', countrycode: '558' },
        { name: 'Niger', alpha2: 'NE', countrycode: '562' },
        { name: 'Nigeria', alpha2: 'NG', countrycode: '566' },
        { name: 'Niue', alpha2: 'NU', countrycode: '570' },
        { name: 'Norfolk Island', alpha2: 'NF', countrycode: '574' },
        { name: 'North Macedonia', alpha2: 'MK', countrycode: '807' },
        { name: 'Northern Mariana Islands', alpha2: 'MP', countrycode: '580' },
        { name: 'Norway', alpha2: 'NO', countrycode: '578' },
        { name: 'Oman', alpha2: 'OM', countrycode: '512' },
        { name: 'Pakistan', alpha2: 'PK', countrycode: '586' },
        { name: 'Palau', alpha2: 'PW', countrycode: '585' },
        { name: 'Palestine, State of', alpha2: 'PS', countrycode: '275' },
        { name: 'Panama', alpha2: 'PA', countrycode: '591' },
        { name: 'Papua New Guinea', alpha2: 'PG', countrycode: '598' },
        { name: 'Paraguay', alpha2: 'PY', countrycode: '600' },
        { name: 'Peru', alpha2: 'PE', countrycode: '604' },
        { name: 'Philippines', alpha2: 'PH', countrycode: '608' },
        { name: 'Pitcairn', alpha2: 'PN', countrycode: '612' },
        { name: 'Poland', alpha2: 'PL', countrycode: '616' },
        { name: 'Portugal', alpha2: 'PT', countrycode: '620' },
        { name: 'Puerto Rico', alpha2: 'PR', countrycode: '630' },
        { name: 'Qatar', alpha2: 'QA', countrycode: '634' },
        { name: 'Réunion', alpha2: 'RE', countrycode: '638' },
        { name: 'Romania', alpha2: 'RO', countrycode: '642' },
        { name: 'Russian Federation', alpha2: 'RU', countrycode: '643' },
        { name: 'Rwanda', alpha2: 'RW', countrycode: '646' },
        { name: 'Saint Barthélemy', alpha2: 'BL', countrycode: '652' },
        { name: 'Saint Helena', alpha2: 'SH', countrycode: '654' },
        { name: 'Saint Kitts and Nevis', alpha2: 'KN', countrycode: '659' },
        { name: 'Saint Lucia', alpha2: 'LC', countrycode: '662' },
        { name: 'Saint Martin', alpha2: 'MF', countrycode: '663' },
        { name: 'Saint Pierre and Miquelon', alpha2: 'PM', countrycode: '666' },
        { name: 'Saint Vincent and the Grenadines', alpha2: 'VC', countrycode: '670' },
        { name: 'Samoa', alpha2: 'WS', countrycode: '882' },
        { name: 'San Marino', alpha2: 'SM', countrycode: '674' },
        { name: 'Sao Tome and Principe', alpha2: 'ST', countrycode: '678' },
        { name: 'Saudi Arabia', alpha2: 'SA', countrycode: '682' },
        { name: 'Senegal', alpha2: 'SN', countrycode: '686' },
        { name: 'Serbia', alpha2: 'RS', countrycode: '688' },
        { name: 'Seychelles', alpha2: 'SC', countrycode: '690' },
        { name: 'Sierra Leone', alpha2: 'SL', countrycode: '694' },
        { name: 'Singapore', alpha2: 'SG', countrycode: '702' },
        { name: 'Sint Maarten', alpha2: 'SX', countrycode: '534' },
        { name: 'Slovakia', alpha2: 'SK', countrycode: '703' },
        { name: 'Slovenia', alpha2: 'SI', countrycode: '705' },
        { name: 'Solomon Islands', alpha2: 'SB', countrycode: '090' },
        { name: 'Somalia', alpha2: 'SO', countrycode: '706' },
        { name: 'South Africa', alpha2: 'ZA', countrycode: '710' },
        { name: 'South Georgia', alpha2: 'GS', countrycode: '239' },
        { name: 'South Sudan', alpha2: 'SS', countrycode: '728' },
        { name: 'Spain', alpha2: 'ES', countrycode: '724' },
        { name: 'Sri Lanka', alpha2: 'LK', countrycode: '144' },
        { name: 'Sudan', alpha2: 'SD', countrycode: '729' },
        { name: 'Suriname', alpha2: 'SR', countrycode: '740' },
        { name: 'Svalbard and Jan Mayen', alpha2: 'SJ', countrycode: '744' },
        { name: 'Sweden', alpha2: 'SE', countrycode: '752' },
        { name: 'Switzerland', alpha2: 'CH', countrycode: '756' },
        { name: 'Syrian Arab Republic', alpha2: 'SY', countrycode: '760' },
        { name: 'Taiwan, Province of China', alpha2: 'TW', countrycode: '158' },
        { name: 'Tajikistan', alpha2: 'TJ', countrycode: '762' },
        { name: 'Tanzania', alpha2: 'TZ', countrycode: '834' },
        { name: 'Thailand', alpha2: 'TH', countrycode: '764' },
        { name: 'TimorLeste', alpha2: 'TL', countrycode: '626' },
        { name: 'Togo', alpha2: 'TG', countrycode: '768' },
        { name: 'Tokelau', alpha2: 'TK', countrycode: '772' },
        { name: 'Tonga', alpha2: 'TO', countrycode: '776' },
        { name: 'Trinidad and Tobago', alpha2: 'TT', countrycode: '780' },
        { name: 'Tunisia', alpha2: 'TN', countrycode: '788' },
        { name: 'Turkey', alpha2: 'TR', countrycode: '792' },
        { name: 'Turkmenistan', alpha2: 'TM', countrycode: '795' },
        { name: 'Turks and Caicos Islands', alpha2: 'TC', countrycode: '796' },
        { name: 'Tuvalu', alpha2: 'TV', countrycode: '798' },
        { name: 'Uganda', alpha2: 'UG', countrycode: '800' },
        { name: 'Ukraine', alpha2: 'UA', countrycode: '804' },
        { name: 'United Arab Emirates', alpha2: 'AE', countrycode: '784' },
        { name: 'United Kingdom', alpha2: 'GB', countrycode: '826' },
        { name: 'United States of America', alpha2: 'US', countrycode: '840' },
        { name: 'United States', alpha2: 'UM', countrycode: '581' },
        { name: 'Uruguay', alpha2: 'UY', countrycode: '858' },
        { name: 'Uzbekistan', alpha2: 'UZ', countrycode: '860' },
        { name: 'Vanuatu', alpha2: 'VU', countrycode: '548' },
        { name: 'Venezuela', alpha2: 'VE', countrycode: '862' },
        { name: 'Viet Nam', alpha2: 'VN', countrycode: '704' },
        { name: 'Virgin Islands', alpha2: 'VG', countrycode: '092' },
        { name: 'Virgin Islands', alpha2: 'VI', countrycode: '850' },
        { name: 'Wallis and Futuna', alpha2: 'WF', countrycode: '876' },
        { name: 'Western Sahara', alpha2: 'EH', countrycode: '732' },
        { name: 'Yemen', alpha2: 'YE', countrycode: '887' },
        { name: 'Zambia', alpha2: 'ZM', countrycode: '894' },
        { name: 'Zimbabwe', alpha2: 'ZW', countrycode: '716' },
      ];

      const commonCountries = regions
        .filter((initialObj) => countryCode.some((countryObj) => countryObj.alpha2 === initialObj.country))
        .map((initialObj) => {
          const countryObj = countryCode.find((country) => country.alpha2 === initialObj.country);
          return {
            count: initialObj.count,
            id: countryObj.countrycode,
          };
        });

      const mouseOver = () => {
        d3.select(this).transition().duration(100).style('opacity', 0.7);

        // .style('fill', 'rgb(0, 0, 255)')
        // .style('cursor', 'pointer');

        tooltip.style('display', null);
      };

      const mouseMove = (event, d) => {
        const provinceFeature = d;
        const piecesSold = countryCode.find((item) => item.countrycode === provinceFeature.id);
        const countryCountScan = regions.find((item) => item.country === piecesSold.alpha2);
        const text = piecesSold.name;
        const text2 = `SCAN: ${countryCountScan?.count ? countryCountScan?.count : '0'}`;

        const x = d3.pointer(event)[0] + 10;
        const y = d3.pointer(event)[1] - 60;
        tooltip.raise();
        tooltip.attr('transform', `translate(${x}, ${y})`);
        tooltip.select('text').text(text);
        tooltip.select('.text2').text(text2);

        const tooltipRect = tooltip.select('rect').node()?.getBoundingClientRect();
        const svgRect = svgRef.current?.getBoundingClientRect();

        if (tooltipRect && svgRect) {
          const tooltipWidth = tooltipRect.width;
          const svgWidth = svgRect.width;
          // const svgHeight = svgRect.height;

          const maxTooltipX = svgWidth - tooltipWidth - 10;

          if (x > maxTooltipX) {
            tooltip.attr('transform', `translate(${maxTooltipX}, ${y})`);
          }
          if (y < 10) {
            tooltip.attr('transform', `translate(${x}, 10)`);
          }
        }
      };

      const mouseLeave = () => {
        d3.select(this).transition().style('opacity', 1);

        tooltip.style('display', 'none');
      };

      const maxCount = commonCountries.reduce((max, country) => (country.count > max ? country.count : max), 0);

      // array contenente id e size di ciascun paese
      const countrySizes = countries.features.map((feature) => {
        const bounds = path.bounds(feature);
        const width = bounds[1][0] - bounds[0][0];
        const height = bounds[1][1] - bounds[0][1];
        return {
          id: feature.id,
          size: width * height,
        };
      });

      const sizeScale = d3
        .scaleLinear()
        .domain(d3.extent(countrySizes, (d) => d.size))
        .range([2, 1]);

      g.selectAll('path')
        .data(countries.features)
        .enter()
        .append('path')
        .attr('class', 'country')
        .attr('d', path)
        .attr('strokelinejoin', 'round')
        .attr('stroke', 'grey')
        .style('transition', 'all 0.2s ease-in-out')
        .style('strokewidth', '1px')
        .style('cursor', 'pointer')
        .style('fill', (d) => {
          const countryData = commonCountries.find((country) => country.id === d.id);
          if (countryData) {
            const { count } = countryData;
            const color = d3.interpolateGreens(count / maxCount);
            return color;
          }
          return 'transparent';
        })

        // .on('click', (event, d) => {
        //   const countrySize = countrySizes.find((size) => size.id === d.id);
        //   const zoomLevel = sizeScale(countrySize.size);
        //   const centroid = path.centroid(d);
        //   svg
        //     .transition()
        //     .duration(750)
        //     .call(
        //       zoom.transform,
        //       d3.zoomIdentity
        //         .translate(width / 2, height / 2) // Sposta l'origine dello zoom al centro
        //         .scale(zoomLevel) // Imposta il livello di zoom calcolato
        //         .translate(-centroid[0], -centroid[1])
        //     );
        // })

        .on('mouseover', function (d) {
          d3.select(this).style('fill', 'rgb(102, 153, 102)');
          tooltip.style('display', null);
        })
        .on('mousemove', mouseMove)
        .on('mouseout', function (d) {
          d3.select(this).style('fill', (d) => {
            const countryData = commonCountries.find((country) => country.id === d.id);
            if (countryData) {
              const { count } = countryData;
              const color = d3.interpolateGreens(count / maxCount);
              return color;
            }
            return 'transparent';
          });
          tooltip.style('display', 'none');
        });
      setMap(svg);
    });
  };

  useEffect(() => {
    handleSetMap();
  }, [regions]);

  useEffect(() => {
    if (analytics?.length > 0 && analytics !== undefined && analytics !== null) {
      updateCounts(analytics);
    }
  }, [analytics]);

  const updateCounts = (newData) => {
    const updatedMonths = regions.map((month) => {
      const matchingData = newData.find((data) => data.user_country === month.country);
      const count = matchingData ? matchingData.country_count : 0;
      return {
        ...month,
        count,
      };
    });
    setRegions(updatedMonths);
  };

  const getQrAnalytics = async () => {
    try {
      const res = await getDataByQrId(qrId);
      return res;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const getAnalytics = async () => {
    try {
      const res = await getQrAnalytics();
      setAnalytics(res.worldMap);
    } catch (err) {
      console.error('Error getting user qrs', err);
    }
  };

  useEffect(() => {
    getAnalytics();
  }, []);

  return (
    <>
      <Grid container>
        <Grid item xs={6}>
          {/* {map && <svg ref={map} width={width} height={height} />} */}
          {map && <div id="svg-world-map" />}
        </Grid>
      </Grid>
    </>
  );
};
export default WorldMap;
