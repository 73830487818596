import { useEffect, useState, useCallback, useMemo } from 'react';
import { Grid, TextField, debounce, FormControl, InputLabel, Select, MenuItem, Stack } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { saveWifi } from '../reducer/qr/configs';

export const Wifi = () => {
  const [ssid, setSsid] = useState(useSelector((state) => state.qrConfigs.wifi.ssid));
  const [networkType, setNetworkType] = useState(useSelector((state) => state.qrConfigs.wifi.networkType));
  const [wifiPsw, setWifiPsw] = useState(useSelector((state) => state.qrConfigs.wifi.wifiPsw));

  const dispatch = useDispatch();

  const handleSaveSsid = useCallback(
    (ssid) => {
      dispatch(saveWifi({ key: 'ssid', value: ssid }));
    },
    [dispatch]
  );
  const handleSaveNetworkType = useCallback(
    (networkType) => {
      dispatch(saveWifi({ key: 'networkType', value: networkType }));
    },
    [dispatch]
  );
  const handleSaveWifiPsw = useCallback(
    (wifiPsw) => {
      dispatch(saveWifi({ key: 'wifiPsw', value: wifiPsw }));
    },
    [dispatch]
  );

  const debouncedSendRequestSsid = useMemo(() => debounce(handleSaveSsid, 1000), [handleSaveSsid]);
  const debouncedSendRequestNetworkType = useMemo(() => debounce(handleSaveNetworkType, 1000), [handleSaveNetworkType]);
  const debouncedSendRequestWifiPsw = useMemo(() => debounce(handleSaveWifiPsw, 1000), [handleSaveWifiPsw]);

  const onChangeSsid = (e) => {
    // const { value } = e.target;
    console.log('E: ', e.target.value);
    setSsid(e.target.value);
    debouncedSendRequestSsid(e.target.value);
  };

  const onChangeNetworkType = (e) => {
    // const { value } = e.target;
    setNetworkType(e.target.value);
    debouncedSendRequestNetworkType(e.target.value);
  };
  const onChangeWifiPsw = (e) => {
    // const { value } = e.target;
    setWifiPsw(e.target.value);
    debouncedSendRequestWifiPsw(e.target.value);
  };

  return (
    <>
      <Stack>
        <Grid container spacing={2} style={{ marginBottom: 0 }}>
          <a style={{ padding: 14, fontWeight: 'bold', fontSize: 24, marginLeft: 20, marginTop: 12 }}>Wi-Fi</a>
        </Grid>
        <TextField
          style={{ marginLeft: 15, marginRight: 20 }}
          id="outlined-basic"
          label="SSID"
          variant="outlined"
          defaultValue={ssid}
          onChange={onChangeSsid}
        />{' '}
        <FormControl>
          <InputLabel style={{ marginTop: 13, marginLeft: 15 }}>Network type</InputLabel>
          <Select
            style={{ marginTop: 13, marginLeft: 15, marginRight: 20 }}
            label="Network type"
            defaultValue={networkType}
            onChange={onChangeNetworkType}
          >
            <MenuItem value={'No encryption'}>No encryption</MenuItem>
            <MenuItem value={'WPA/WPA2'}>WPA/WPA2</MenuItem>
            <MenuItem value={'WEP'}>WEP</MenuItem>
          </Select>
        </FormControl>
        <TextField
          style={{ marginTop: 13, marginLeft: 15, marginRight: 20 }}
          id="outlined-basic"
          label="WI-FI psw"
          variant="outlined"
          defaultValue={wifiPsw}
          onChange={onChangeWifiPsw}
        />{' '}
        <a style={{ fontSize: 13, marginTop: 30, marginLeft: 15 }}>Scanning the QR code will connect to WI-FI. </a>
      </Stack>
    </>
  );
};
