import { Container, Select, Grid, Card, TextField, Button, FormControl, InputLabel } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import MobileStoreButton from 'react-mobile-store-button';
import { Link } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import YouTubeIcon from '@mui/icons-material/YouTube';
import LinkIcon from '@mui/icons-material/Link';
import TelegramIcon from '@mui/icons-material/Telegram';
import PinterestIcon from '@mui/icons-material/Pinterest';
import RedditIcon from '@mui/icons-material/Reddit';
import EventIcon from '@mui/icons-material/Event';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import dayjs from 'dayjs';
import moment from 'moment';
import { textAlign } from '@mui/system';
import IphoneFrame from '../assets/iphone_frame.png';
import Booking from '../assets/booking.png';
import Avatar from '../assets/avatar.png';
import Weddingday from '../assets/weddingday.jpeg';
import { PrevievTextOption } from './PreviewTextOption';
import { PrevievEventOption } from './PreviewEventOption';
import { PrevievVcardOption } from './PreviewVcardOption';

export const PrevievAppOption = ({ props }) => {
  const { input, configs } = props;

  // app
  const logo = useSelector((state) => state.qrConfigs.appPreviewPage.logo);
  const pageColor = useSelector((state) => state.qrConfigs.appPreviewPage.pageColor);
  const cardColor = useSelector((state) => state.qrConfigs.appPreviewPage.cardColor);
  const textColor = useSelector((state) => state.qrConfigs.appPreviewPage.textColor);
  const appName = useSelector((state) => state.qrConfigs.appPreviewPage.appName);
  const description = useSelector((state) => state.qrConfigs.appPreviewPage.description);
  const website = useSelector((state) => state.qrConfigs.appPreviewPage.website);
  const ios = useSelector((state) => state.qrConfigs.app.ios);
  const android = useSelector((state) => state.qrConfigs.app.android);

  return (
    <div
      style={{
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: pageColor,
      }}
    >
      {(!!logo && (
        <img
          src={logo}
          alt="logo"
          style={{
            position: 'absolute',
            top: 70,
            width: 100,
            height: 100,
            borderRadius: '50%',
            border: `4px solid ${pageColor}`,
            zIndex: 1000,
          }}
        />
      )) || (
        <img
          src={Booking}
          alt="logo"
          style={{
            position: 'absolute',
            top: 70,
            zIndex: 1000,
          }}
        />
      )}
      <Card
        style={{
          // overflow: 'scroll',
          padding: 16,
          width: 250,
          height: 400,
          marginTop: 95,
          backgroundColor: cardColor,
        }}
      >
        <a
          style={{
            flex: 1,
            display: 'flex',
            fontWeight: 'bold',
            fontSize: 30,
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: 75,
            color: textColor,
          }}
        >
          {appName || 'Booking'}
        </a>
        <a
          href={website}
          style={{
            flex: 1,
            display: 'flex',
            fontWeight: 'normal',
            fontSize: 15,
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: 5,
            color: textColor,
          }}
        >
          {website || 'https://booking.com'}
        </a>
        <div
          style={{
            flex: 1,
            display: 'flex',
            fontWeight: 'normal',
            fontSize: 12,
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: 8,
            color: textColor,
          }}
        >
          {description ||
            "E' nata come una piccola start up olandese. Oggi è una delle aziende di e-commerce più grandi del mondo attiva nel settore dei viaggi. "}
        </div>
        <div
          style={{
            marginTop: 15,
            marginLeft: 32,
          }}
        >
          <MobileStoreButton
            store="ios"
            style={{ height: 45 }}
            url={ios || 'https://apps.apple.com/it/app/booking-com-offerte-di-viaggio/id367003839'}
          />
        </div>
        <div
          style={{
            marginLeft: 24,
          }}
        >
          <MobileStoreButton
            store="android"
            style={{ height: 65 }}
            url={android || 'https://play.google.com/store/apps/details?id=com.booking&gl=IT'}
          />
        </div>
      </Card>
    </div>
  );
};
