import { useEffect, useState, useCallback, useMemo } from 'react';
import { Grid, TextField, Stack, Button, debounce } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import * as React from 'react';
import ImageUploading from 'react-images-uploading';
import { MuiColorInput } from 'mui-color-input';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import UpgradeIcon from '@mui/icons-material/Upgrade';
import DeleteIcon from '@mui/icons-material/Delete';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import { saveVcard } from '../reducer/qr/configs';

export const Vcard = () => {
  const [firstName, setFirstName] = useState(useSelector((state) => state.qrConfigs.vcard.firstName));
  const [lastName, setLastName] = useState(useSelector((state) => state.qrConfigs.vcard.lastName));
  const [phoneNumber, setPhoneNumber] = useState(useSelector((state) => state.qrConfigs.vcard.phoneNumber));
  const [mobile, setMobile] = useState(useSelector((state) => state.qrConfigs.vcard.mobile));
  const [email, setEmail] = useState(useSelector((state) => state.qrConfigs.vcard.email));
  const [webSite, setWebsite] = useState(useSelector((state) => state.qrConfigs.vcard.webSite));
  const [company, setCompany] = useState(useSelector((state) => state.qrConfigs.vcard.company));
  const [jobTitle, setJob] = useState(useSelector((state) => state.qrConfigs.vcard.jobTitle));
  const [address, setAddress] = useState(useSelector((state) => state.qrConfigs.vcard.address));
  const [city, setCity] = useState(useSelector((state) => state.qrConfigs.vcard.city));
  const [postCode, setPostCode] = useState(useSelector((state) => state.qrConfigs.vcard.postCode));
  const [fax, setFax] = useState(useSelector((state) => state.qrConfigs.vcard.fax));
  const [country, setCountry] = useState(useSelector((state) => state.qrConfigs.vcard.country));

  const behavior = useSelector((state) => state.qrConfigs.behavior);

  const [color, setColor] = useState(useSelector((state) => state.qrConfigs.vcard.pageColor));
  const [colorCard, setColorCard] = useState(useSelector((state) => state.qrConfigs.vcard.cardColor));
  const [colorText, setColorText] = useState(useSelector((state) => state.qrConfigs.vcard.textColor));

  const dispatch = useDispatch();

  const handleSaveFirstName = useCallback(
    (name) => {
      dispatch(saveVcard({ key: 'firstName', value: name }));
    },
    [dispatch]
  );
  const handleSaveLastName = useCallback(
    (lastname) => {
      dispatch(saveVcard({ key: 'lastName', value: lastname }));
    },
    [dispatch]
  );
  const handleSavePhoneNumber = useCallback(
    (phone) => {
      dispatch(saveVcard({ key: 'phoneNumber', value: phone }));
    },
    [dispatch]
  );
  const handleSaveMobile = useCallback(
    (mobile) => {
      dispatch(saveVcard({ key: 'mobile', value: mobile }));
    },
    [dispatch]
  );
  const handleSaveEmail = useCallback(
    (email) => {
      dispatch(saveVcard({ key: 'email', value: email }));
    },
    [dispatch]
  );
  const handleSaveWebsite = useCallback(
    (web) => {
      dispatch(saveVcard({ key: 'webSite', value: web }));
    },
    [dispatch]
  );

  const handleSaveCompany = useCallback(
    (company) => {
      dispatch(saveVcard({ key: 'company', value: company }));
    },
    [dispatch]
  );
  const handleSaveJob = useCallback(
    (job) => {
      dispatch(saveVcard({ key: 'jobTitle', value: job }));
    },
    [dispatch]
  );
  const handleSaveAddress = useCallback(
    (address) => {
      dispatch(saveVcard({ key: 'address', value: address }));
    },
    [dispatch]
  );
  const handleSaveCity = useCallback(
    (city) => {
      dispatch(saveVcard({ key: 'city', value: city }));
    },
    [dispatch]
  );
  const handleSavePostCode = useCallback(
    (postcode) => {
      dispatch(saveVcard({ key: 'postCode', value: postcode }));
    },
    [dispatch]
  );
  const handleSaveFax = useCallback(
    (fax) => {
      dispatch(saveVcard({ key: 'fax', value: fax }));
    },
    [dispatch]
  );
  const handleSaveCountry = useCallback(
    (country) => {
      dispatch(saveVcard({ key: 'country', value: country }));
    },
    [dispatch]
  );

  const debouncedSendRequestName = useMemo(() => debounce(handleSaveFirstName, 1000), [handleSaveFirstName]);
  const debouncedSendRequestLastName = useMemo(() => debounce(handleSaveLastName, 1000), [handleSaveLastName]);
  const debouncedSendRequestphone = useMemo(() => debounce(handleSavePhoneNumber, 1000), [handleSavePhoneNumber]);
  const debouncedSendRequestmobile = useMemo(() => debounce(handleSaveMobile, 1000), [handleSaveMobile]);
  const debouncedSendRequestemail = useMemo(() => debounce(handleSaveEmail, 1000), [handleSaveEmail]);
  const debouncedSendRequestweb = useMemo(() => debounce(handleSaveWebsite, 1000), [handleSaveWebsite]);
  const debouncedSendRequestCompany = useMemo(() => debounce(handleSaveCompany, 1000), [handleSaveCompany]);
  const debouncedSendRequestJob = useMemo(() => debounce(handleSaveJob, 1000), [handleSaveJob]);
  const debouncedSendRequestAddress = useMemo(() => debounce(handleSaveAddress, 1000), [handleSaveAddress]);
  const debouncedSendRequestCity = useMemo(() => debounce(handleSaveCity, 1000), [handleSaveCity]);
  const debouncedSendRequestPostCode = useMemo(() => debounce(handleSavePostCode, 1000), [handleSavePostCode]);
  const debouncedSendRequestFax = useMemo(() => debounce(handleSaveFax, 1000), [handleSaveFax]);
  const debouncedSendRequestCountry = useMemo(() => debounce(handleSaveCountry, 1000), [handleSaveCountry]);

  const onChangeName = (e) => {
    // const { value } = e.target;
    console.log('E: ', e.target.value);
    setFirstName(e.target.value);
    debouncedSendRequestName(e.target.value);
  };

  const onChangeLastName = (e) => {
    // const { value } = e.target;
    setLastName(e.target.value);
    debouncedSendRequestLastName(e.target.value);
  };
  const onChangePhone = (e) => {
    // const { value } = e.target;
    setPhoneNumber(e.target.value);
    debouncedSendRequestphone(e.target.value);
  };
  const onChangeMobile = (e) => {
    // const { value } = e.target;
    console.log('E: ', e.target.value);
    setMobile(e.target.value);
    debouncedSendRequestmobile(e.target.value);
  };

  const onChangeEmail = (e) => {
    // const { value } = e.target;
    setEmail(e.target.value);
    debouncedSendRequestemail(e.target.value);
  };
  const onChangeWeb = (e) => {
    // const { value } = e.target;
    setWebsite(e.target.value);
    debouncedSendRequestweb(e.target.value);
  };

  const onChangeCompany = (e) => {
    // const { value } = e.target;
    console.log('E: ', e.target.value);
    setCompany(e.target.value);
    debouncedSendRequestCompany(e.target.value);
  };

  const onChangeJob = (e) => {
    // const { value } = e.target;
    setJob(e.target.value);
    debouncedSendRequestJob(e.target.value);
  };
  const onChangeAddress = (e) => {
    // const { value } = e.target;
    setAddress(e.target.value);
    debouncedSendRequestAddress(e.target.value);
  };
  const onChangeCity = (e) => {
    // const { value } = e.target;
    console.log('E: ', e.target.value);
    setCity(e.target.value);
    debouncedSendRequestCity(e.target.value);
  };
  const onChangePostCode = (e) => {
    // const { value } = e.target;
    console.log('E: ', e.target.value);
    setPostCode(e.target.value);
    debouncedSendRequestPostCode(e.target.value);
  };

  const onChangeFax = (e) => {
    // const { value } = e.target;
    setFax(e.target.value);
    debouncedSendRequestFax(e.target.value);
  };
  const onChangeCountry = (e) => {
    // const { value } = e.target;
    setCountry(e.target.value);
    debouncedSendRequestCountry(e.target.value);
  };

  function rgbToHex(rgbColor) {
    // Rimuovi il prefisso "rgb(" e la parentesi chiusa ")"
    const rgbValues = rgbColor.substring(4, rgbColor.length - 1).split(', ');

    // Converte i valori RGB in esadecimali
    const r = parseInt(rgbValues[0], 10).toString(16).padStart(2, '0');
    const g = parseInt(rgbValues[1], 10).toString(16).padStart(2, '0');
    const b = parseInt(rgbValues[2], 10).toString(16).padStart(2, '0');

    // Restituisci il colore esadecimale
    return `#${r}${g}${b}`;
  }

  const [images, setImages] = useState([]);
  const maxNumber = 1;
  const onChange = (imageList) => {
    setImages(imageList);
    dispatch(saveVcard({ key: 'avatar', value: imageList[0].data_url }));
  };

  const handleChange = (color) => {
    const colorEx = rgbToHex(color);

    setColor(colorEx);
    dispatch(saveVcard({ key: 'pageColor', value: colorEx }));
  };

  const handleChangeCard = (color) => {
    const colorEx = rgbToHex(color);

    setColorCard(colorEx);
    dispatch(saveVcard({ key: 'cardColor', value: colorEx }));
  };
  const handleChangeText = (color) => {
    const colorEx = rgbToHex(color);
    setColorText(colorEx);
    dispatch(saveVcard({ key: 'textColor', value: colorEx }));
  };

  return (
    <>
      <Stack>
        <a style={{ padding: 8, fontWeight: 'bold', fontSize: 24, marginLeft: 20, marginTop: 2 }}>
          Contact Information
        </a>

        <Table aria-label="simple table">
          <TableBody>
            <TableRow>
              <TableCell align="right">
                <TextField
                  id="outlined-basic"
                  label="First Name"
                  variant="outlined"
                  defaultValue={firstName}
                  onChange={onChangeName}
                />{' '}
              </TableCell>
              <TableCell align="right">
                <TextField
                  id="outlined-basic"
                  label="Last Name"
                  variant="outlined"
                  defaultValue={lastName}
                  onChange={onChangeLastName}
                />{' '}
              </TableCell>
              <TableCell align="right">
                <TextField
                  id="outlined-basic"
                  label="Phone number"
                  variant="outlined"
                  defaultValue={phoneNumber}
                  onChange={onChangePhone}
                />{' '}
              </TableCell>
              <TableCell align="right">
                <TextField
                  id="outlined-basic"
                  label="Mobile"
                  variant="outlined"
                  defaultValue={mobile}
                  onChange={onChangeMobile}
                />{' '}
              </TableCell>
            </TableRow>
          </TableBody>
          <TableBody>
            <TableRow>
              <TableCell align="right">
                <TextField
                  id="outlined-basic"
                  label="Email"
                  variant="outlined"
                  defaultValue={email}
                  onChange={onChangeEmail}
                />{' '}
              </TableCell>
              <TableCell align="right">
                <TextField
                  id="outlined-basic"
                  label="Website (URL)"
                  variant="outlined"
                  defaultValue={webSite}
                  onChange={onChangeWeb}
                />{' '}
              </TableCell>
              <TableCell align="right">
                <TextField
                  id="outlined-basic"
                  label="Company"
                  variant="outlined"
                  value={company}
                  onChange={onChangeCompany}
                />{' '}
              </TableCell>
              <TableCell align="right">
                <TextField
                  id="outlined-basic"
                  label="Job title"
                  variant="outlined"
                  value={jobTitle}
                  onChange={onChangeJob}
                />{' '}
              </TableCell>
            </TableRow>
          </TableBody>
          <TableBody>
            <TableRow>
              <TableCell align="right">
                <TextField
                  id="outlined-basic"
                  label="Address"
                  variant="outlined"
                  value={address}
                  onChange={onChangeAddress}
                />{' '}
              </TableCell>
              <TableCell align="right">
                <TextField id="outlined-basic" label="City" variant="outlined" value={city} onChange={onChangeCity} />{' '}
              </TableCell>
              <TableCell align="right">
                <TextField id="outlined-basic" label="Fax" variant="outlined" value={fax} onChange={onChangeFax} />{' '}
              </TableCell>
              <TableCell align="right">
                <TextField
                  id="outlined-basic"
                  label="PostCode"
                  variant="outlined"
                  value={postCode}
                  onChange={onChangePostCode}
                />{' '}
              </TableCell>
            </TableRow>
          </TableBody>
          <TableBody>
            <TableRow>
              <TableCell align="right">
                <TextField
                  id="outlined-basic"
                  // style={{ marginTop: 4, marginLeft: 16 }}
                  label="Country"
                  variant="outlined"
                  value={country}
                  onChange={onChangeCountry}
                />{' '}
              </TableCell>
              <TableCell align="left">
                {behavior === 'previewPage' && (
                  <ImageUploading
                    multiple
                    value={images}
                    onChange={onChange}
                    maxNumber={maxNumber}
                    dataURLKey="data_url"
                  >
                    {({
                      imageList,
                      onImageUpload,
                      onImageRemoveAll,
                      onImageUpdate,
                      onImageRemove,
                      isDragging,
                      dragProps,
                    }) => (
                      // write your building UI
                      <Grid>
                        <Button
                          style={{ marginBottom: 8, marginTop: 8 }}
                          variant="outlined"
                          startIcon={<PhotoCamera />}
                          onClick={onImageUpload}
                          {...dragProps}
                        >
                          Upload image
                        </Button>
                        &nbsp;
                        {/* <Button variant="outlined" startIcon={<DeleteIcon />} onClick={onImageRemoveAll}>
                              Remove All images
                            </Button> */}
                        {imageList.map((image, index) => (
                          <Grid key="">
                            <img src={image.data_url} alt="" width="100" />
                            <Grid marginTop={1}>
                              <Button
                                style={{ marginRight: -22 }}
                                variant="text"
                                startIcon={<UpgradeIcon />}
                                onClick={() => onImageUpdate(index)}
                              />

                              <Button variant="text" startIcon={<DeleteIcon />} onClick={() => onImageRemove(index)} />
                            </Grid>
                          </Grid>
                        ))}
                      </Grid>
                    )}
                  </ImageUploading>
                )}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>

        {behavior === 'previewPage' && (
          <Grid>
            <Grid container spacing={3} marginTop={-5}>
              <Grid item xs={4}>
                <a style={{ padding: 8, fontWeight: 'bold', fontSize: 20, marginLeft: 20, marginTop: 12 }}>
                  Page color
                </a>{' '}
              </Grid>
              <Grid item xs={4}>
                <a style={{ padding: 8, fontWeight: 'bold', fontSize: 20, marginLeft: 20, marginTop: 12 }}>
                  Card color
                </a>{' '}
              </Grid>
              <Grid item xs={4}>
                <a style={{ padding: 8, fontWeight: 'bold', fontSize: 20, marginLeft: 20, marginTop: 12 }}>
                  Text color
                </a>{' '}
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={4}>
                <MuiColorInput value={color} onChange={handleChange} style={{ marginLeft: 25 }} />
              </Grid>
              <Grid item xs={4}>
                <MuiColorInput value={colorCard} onChange={handleChangeCard} style={{ marginLeft: 25 }} />
              </Grid>
              <Grid item xs>
                <MuiColorInput value={colorText} onChange={handleChangeText} style={{ marginLeft: 25 }} />
              </Grid>
            </Grid>
          </Grid>
        )}

        <a style={{ fontSize: 13, marginTop: 14, marginLeft: 30 }}>
          Your QR code will save this contact to the phone scanning.{' '}
        </a>
      </Stack>
    </>
  );
};
