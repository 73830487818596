import axios from 'axios';
import { useEffect, useState } from 'react';
import { getCookie } from '../utils/getCookie';

// const API_BASE_URL = 'http://localhost:3100';
// export const DISPATCHER_URL = 'http://localhost:3000';
const API_BASE_URL = 'https://api.qrify.link';
export const DISPATCHER_URL = 'https://to.qrify.link';

export const postQrLink = async (link) => {
  console.log(link);
  console.log('Link: ', link);

  let configs = {
    behavior: link.behavior,
    code: link.code,
    mycode: link.mycode,
    // user_id: link.userId,
    ec_level: link.options.ecLevel,
    qr_size: link.options.size,
    quite_zone: link.options.quiteZone,
    qr_style: link.options.qrStyle,
    qr_eye_radius: link.options.eyeRadius,
    enable_cors: link.options.enableCors,
    qr_eye_color: link.options.eyeColor,
    qr_background_color: link.colors.bgColor,
    qr_color: link.colors.fgColor,
    logo_url: link.logo.logoImage,
    logo_size: link.logo.logoWidth,
    logo_height: link.logo.logoHeight,
    logo_opacity: link.logo.logoOpacity,
    remove_qr_code_behind_logo: link.logo.removeQrCodeBehindLogo,
    type: link.type,
  };

  // switch degli elementi di "behavior" = direct

  switch (link.type) {
    case 'link':
      configs = { ...configs, url: link.link };
      break;
    case 'sms':
      configs = { ...configs, phone_number: link.sms.number, message: link.sms.message };
      break;
    case 'call':
      configs = { ...configs, phone_number: link.call.number };
      break;
    case 'email':
      configs = { ...configs, email: link.email.address, subject: link.email.subject, text: link.email.message };
      break;
    case 'txt':
      configs = { ...configs, text: link.txt };
      break;
    case 'whatsapp':
      configs = { ...configs, phone_number: link.whatsapp.number, message: link.whatsapp.message };
      break;
    case 'wifi':
      configs = { ...configs, ssid: link.wifi.ssid, password: link.wifi.wifiPsw, network_type: link.wifi.networkType };
      break;
    case 'vcard':
      configs = {
        ...configs,
        mobile_number: link.vcard.mobile,
        email: link.vcard.email,
        firstname: link.vcard.firstName,
        lastname: link.vcard.lastName,
        phone_number: link.vcard.phoneNumber,
        website: link.vcard.webSite,
        company: link.vcard.company,
        job_title: link.vcard.jobTitle,
        address: link.vcard.address,
        city: link.vcard.city,
        fax: link.vcard.fax,
        postcode: link.vcard.postCode,
        country: link.vcard.country,
      };
      break;
    case 'event':
      configs = {
        ...configs,
        name: link.event.nameEvent,
        location: link.event.location,
        url: link.event.url,
        description: link.event.description,
        date_time_start: link.event.dataStart,
        date_time_end: link.event.dataEnd,
      };
      break;

    case 'app':
      configs = {
        ...configs,
        android_url: link.app.android,
        ios_url: link.app.ios,
        page_title: link.appPreviewPage.appName,
        page_description: link.appPreviewPage.description,
        website: link.appPreviewPage.website,
      };

      break;
    case 'social':
      configs = {
        ...configs,
        background_color: link.socialPreviewPage.pageColor,
        card_color: link.socialPreviewPage.cardColor,
        text_color: '',
        image_uploaded: '',
      };
      link.socialPreviewPage.socials.map((social) => {
        configs = {
          ...configs,
          social_type: social.type,
          url: social.url,
        };

        return configs;
      });

      break;
    default:
      return configs;
  }

  // switch degli elementi di "behavior" = previewpage
  if (link.behavior === 'previewPage') {
    switch (link.type) {
      case 'txt':
        configs = {
          ...configs,
          pageBackgroundColor: link.textColor.pageColor,
          card_color: link.textColor.cardColor,
          text_color: link.textColor.textColor,
          image_uploaded: '',
        };
        break;
      case 'vcard':
        configs = {
          ...configs,
          pageBackgroundColor: link.vcard.pageColor,
          card_color: link.vcard.cardColor,
          text_color: link.vcard.textColor,
          image_uploaded: link.vcard.avatar,
        };

        break;
      case 'event':
        configs = {
          ...configs,
          pageBackgroundColor: link.event.pageColor,
          card_color: link.event.cardColor,
          text_color: link.event.textColor,
          image_uploaded: link.event.logo,
        };

        break;

      case 'app':
        configs = {
          ...configs,
          pageBackgroundColor: link.appPreviewPage.pageColor,
          card_color: link.appPreviewPage.cardColor,
          text_color: link.appPreviewPage.textColor,
          image_uploaded: link.appPreviewPage.logo,
        };

        break;
      case 'social':
        configs = {
          ...configs,
          pageBackgroundColor: link.socialPreviewPage.pageColor,
          card_color: link.socialPreviewPage.cardColor,
          text_color: '',
          image_uploaded: '',
        };
        link.socialPreviewPage.socials.map((social) => {
          configs = {
            ...configs,
            social_type: social.type,
            url: social.url,
          };

          return configs;
        });

        break;
      default:
    }
  }

  const token = getCookie('__session');
  // console.log('token', token);

  try {
    const response = await axios.post(`${API_BASE_URL}/qrs`, configs, {
      headers: {
        Authorization: token,
      },
    });

    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const getData = async (userId) => {
  // console.log('USER_ID: ', userId);
  const token = getCookie('__session');
  // console.log('token', token);
  try {
    const response = await axios.get(`${API_BASE_URL}/qrs/user/${userId}`, {
      headers: {
        // Authorization: `Bearer ${token}`,
        Authorization: token,
      },
    });
    // console.log('response: ', response.data.data);
    return response.data.data;
  } catch (error) {
    console.log(error);
  }
};

export const getDataByQrId = async (qrId) => {
  const token = getCookie('__session');
  try {
    const response = await axios.get(`${API_BASE_URL}/qrs/${qrId}`, {
      headers: {
        Authorization: token,
      },
    });
    console.log('response: ', response.data.data);
    return response.data.data;
  } catch (error) {
    console.log(error);
  }
};

export const deleteData = async (qrId) => {
  // console.log('USER_ID: ', userId);
  const token = getCookie('__session');
  // console.log('token', token);

  try {
    const response = await axios.delete(`${API_BASE_URL}/qrs/${qrId}`, {
      headers: {
        // Authorization: `Bearer ${token}`,
        Authorization: token,
      },
    });
    // console.log('response: ', response.data.data);
    return response.data.data;
  } catch (error) {
    console.log(error);
  }
};

export const updateQrData = async (link, qrId) => {
  console.log('code: ', link);
  let configs = {
    behavior: link.behavior,
    code: link.code,
    mycode: link.mycode,
    user_id: link.userId,
    ec_level: link.options.ecLevel,
    qr_size: link.options.size,
    quite_zone: link.options.quiteZone,
    qr_style: link.options.qrStyle,
    qr_eye_radius: link.options.eyeRadius,
    enable_cors: link.options.enableCors,
    qr_eye_color: link.options.eyeColor,
    qr_background_color: link.colors.bgColor,
    qr_color: link.colors.fgColor,
    logo_url: link.logo.logoImage,
    logo_size: link.logo.logoWidth,
    logo_height: link.logo.logoHeight,
    logo_opacity: link.logo.logoOpacity,
    remove_qr_code_behind_logo: link.logo.removeQrCodeBehindLogo,
    type: link.type,
  };

  // switch degli elementi di "behavior" = direct

  switch (link.type) {
    case 'link':
      configs = { ...configs, url: link.link };
      break;
    case 'sms':
      configs = { ...configs, phone_number: link.sms.number, message: link.sms.message };
      break;
    case 'call':
      configs = { ...configs, phone_number: link.call.number };
      break;
    case 'email':
      configs = { ...configs, email: link.email.address, subject: link.email.subject, text: link.email.message };
      break;
    case 'txt':
      configs = { ...configs, text: link.txt };
      break;
    case 'whatsapp':
      configs = { ...configs, phone_number: link.whatsapp.number, message: link.whatsapp.message };
      break;
    case 'wifi':
      configs = { ...configs, ssid: link.wifi.ssid, password: link.wifi.wifiPsw, network_type: link.wifi.networkType };
      break;
    case 'vcard':
      configs = {
        ...configs,
        mobile_number: link.vcard.mobile,
        email: link.vcard.email,
        firstname: link.vcard.firstName,
        lastname: link.vcard.lastName,
        phone_number: link.vcard.phoneNumber,
        website: link.vcard.webSite,
        company: link.vcard.company,
        job_title: link.vcard.jobTitle,
        address: link.vcard.address,
        city: link.vcard.city,
        fax: link.vcard.fax,
        postcode: link.vcard.postCode,
        country: link.vcard.country,
      };
      break;
    case 'event':
      configs = {
        ...configs,
        name: link.event.nameEvent,
        location: link.event.location,
        url: link.event.url,
        description: link.event.description,
        date_time_start: link.event.dataStart,
        date_time_end: link.event.dataEnd,
      };
      break;

    case 'app':
      configs = {
        ...configs,
        android_url: link.app.android,
        ios_url: link.app.ios,
        page_title: link.appPreviewPage.appName,
        page_description: link.appPreviewPage.description,
        website: link.appPreviewPage.website,
      };

      break;
    case 'social':
      configs = {
        ...configs,
        background_color: link.socialPreviewPage.pageColor,
        card_color: link.socialPreviewPage.cardColor,
        text_color: '',
        image_uploaded: '',
      };
      link.socialPreviewPage.socials.map((social) => {
        configs = {
          ...configs,
          social_type: social.type,
          url: social.url,
        };

        return configs;
      });

      break;
    default:
      return configs;
  }

  // switch degli elementi di "behavior" = previewpage
  if (link.behavior === 'previewPage') {
    switch (link.type) {
      case 'txt':
        configs = {
          ...configs,
          pageBackgroundColor: link.textColor.pageColor,
          card_color: link.textColor.cardColor,
          text_color: link.textColor.textColor,
          image_uploaded: '',
        };
        break;
      case 'vcard':
        configs = {
          ...configs,
          pageBackgroundColor: link.vcard.pageColor,
          card_color: link.vcard.cardColor,
          text_color: link.vcard.textColor,
          image_uploaded: link.vcard.logo,
        };

        break;
      case 'event':
        configs = {
          ...configs,
          pageBackgroundColor: link.event.pageColor,
          card_color: link.event.cardColor,
          text_color: link.event.textColor,
          image_uploaded: link.event.logo,
        };

        break;

      case 'app':
        configs = {
          ...configs,
          pageBackgroundColor: link.appPreviewPage.pageColor,
          card_color: link.appPreviewPage.cardColor,
          text_color: link.appPreviewPage.textColor,
          image_uploaded: link.appPreviewPage.logo,
        };

        break;
      case 'social':
        configs = {
          ...configs,
          pageBackgroundColor: link.socialPreviewPage.pageColor,
          card_color: link.socialPreviewPage.cardColor,
          text_color: '',
          image_uploaded: '',
        };
        link.socialPreviewPage.socials.map((social) => {
          configs = {
            ...configs,
            social_type: social.type,
            url: social.url,
          };

          return configs;
        });

        break;
      default:
    }
  }

  const token = getCookie('__session');

  try {
    const response = await axios.put(`${API_BASE_URL}/qrs/${qrId}`, configs, {
      headers: {
        Authorization: token,
      },
    });

    return response.data;
  } catch (error) {
    console.log(error);
  }
};
