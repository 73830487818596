import { Grid, Card } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import YouTubeIcon from '@mui/icons-material/YouTube';
import LinkIcon from '@mui/icons-material/Link';
import TelegramIcon from '@mui/icons-material/Telegram';
import PinterestIcon from '@mui/icons-material/Pinterest';
import RedditIcon from '@mui/icons-material/Reddit';
import IphoneFrame from '../assets/iphone_frame.png';
import { PrevievTextOption } from './PreviewTextOption';
import { PrevievEventOption } from './PreviewEventOption';
import { PrevievVcardOption } from './PreviewVcardOption';
import { PrevievAppOption } from './PreviewAppOptions';

export const PrevievSocialOption = ({ props }) => {
  const { input, configs } = props;

  // social
  const pageColorSocial = useSelector((state) => state.qrConfigs.socialPreviewPage.pageColor);
  const cardColorSocial = useSelector((state) => state.qrConfigs.socialPreviewPage.cardColor);
  const textColorSocial = useSelector((state) => state.qrConfigs.socialPreviewPage.textColor);
  const socialName = useSelector((state) => state.qrConfigs.socialPreviewPage.socialName);
  const socials = useSelector((state) => state.qrConfigs.socialPreviewPage.socials);
  const logoSocial = useSelector((state) => state.qrConfigs.socialPreviewPage.logo);

  const renderIcon = (type) => {
    switch (type) {
      case 'facebook':
        return (
          <Card fullWidth style={{ padding: 3, marginBottom: 8, borderRadius: 30 }} sx={{ border: '1px solid black' }}>
            {' '}
            <FacebookIcon sx={{ marginLeft: 5, fontSize: 30, color: '#3b5998' }} />
            <div
              style={{
                fontSize: 20,
                marginLeft: 80,
                marginTop: -40,
              }}
            >
              Facebook
            </div>
          </Card>
        );
      case 'instagram':
        return (
          <Card fullWidth style={{ padding: 3, marginBottom: 8, borderRadius: 30 }} sx={{ border: '1px solid black' }}>
            {' '}
            <InstagramIcon sx={{ marginLeft: 5, fontSize: 30, color: '#C13584' }} />
            <div
              style={{
                fontSize: 20,
                marginLeft: 80,
                marginTop: -40,
              }}
            >
              Instagram
            </div>
          </Card>
        );
      case 'linkedin':
        return (
          <Card fullWidth style={{ padding: 3, marginBottom: 8, borderRadius: 30 }} sx={{ border: '1px solid black' }}>
            {' '}
            <LinkedInIcon sx={{ marginLeft: 5, fontSize: 30, color: '#0e76a8' }} />
            <div
              style={{
                fontSize: 20,
                marginLeft: 80,
                marginTop: -40,
              }}
            >
              LinkedIn
            </div>
          </Card>
        );
      case 'twitter':
        return (
          <Card fullWidth style={{ padding: 3, marginBottom: 8, borderRadius: 30 }} sx={{ border: '1px solid black' }}>
            {' '}
            <TwitterIcon sx={{ marginLeft: 5, fontSize: 30, color: '#00acee' }} />
            <div
              style={{
                fontSize: 20,
                marginLeft: 80,
                marginTop: -40,
              }}
            >
              Twitter
            </div>
          </Card>
        );
      case 'youtube':
        return (
          <Card fullWidth style={{ padding: 3, marginBottom: 8, borderRadius: 30 }} sx={{ border: '1px solid black' }}>
            {' '}
            <YouTubeIcon sx={{ marginLeft: 5, fontSize: 30, color: '#c4032b' }} />
            <div
              style={{
                fontSize: 20,
                marginLeft: 80,
                marginTop: -40,
              }}
            >
              YouTube
            </div>
          </Card>
        );
      case 'telegram':
        return (
          <Card fullWidth style={{ padding: 3, marginBottom: 8, borderRadius: 30 }} sx={{ border: '1px solid black' }}>
            {' '}
            <TelegramIcon sx={{ marginLeft: 5, fontSize: 30, color: '#0088cc' }} />
            <div
              style={{
                fontSize: 20,
                marginLeft: 80,
                marginTop: -40,
              }}
            >
              Telegram
            </div>
          </Card>
        );
      case 'pinterest':
        return (
          <Card fullWidth style={{ padding: 3, marginBottom: 8, borderRadius: 30 }} sx={{ border: '1px solid black' }}>
            {' '}
            <PinterestIcon sx={{ marginLeft: 5, fontSize: 30, color: '#E60023' }} />
            <div
              style={{
                fontSize: 20,
                marginLeft: 80,
                marginTop: -40,
              }}
            >
              Pinterest
            </div>
          </Card>
        );
      case 'reddit':
        return (
          <Card fullWidth style={{ padding: 3, marginBottom: 8, borderRadius: 30 }} sx={{ border: '1px solid black' }}>
            {' '}
            <RedditIcon sx={{ marginLeft: 5, fontSize: 30, color: '#FF4500' }} />
            <div
              style={{
                fontSize: 20,
                marginLeft: 80,
                marginTop: -40,
              }}
            >
              Reddit
            </div>
          </Card>
        );
      case 'link':
        return (
          <Card fullWidth style={{ padding: 3, marginBottom: 8, borderRadius: 30 }} sx={{ border: '1px solid black' }}>
            {' '}
            <LinkIcon sx={{ marginLeft: 5, fontSize: 30, color: '#555555' }} />
            <div
              style={{
                fontSize: 20,
                marginLeft: 80,
                marginTop: -40,
                textDecoration: 'none',
              }}
            >
              WebSite
            </div>
          </Card>
        );
      default:
        return null;
    }
  };

  return (
    <Grid
      style={{
        backgroundColor: pageColorSocial,
        marginLeft: 8,
        marginRight: 5,
        width: 400,
        height: 588,
        padding: 16,
        marginTop: 5,
        // overflow: 'scroll',
      }}
    >
      {/* {(!!logoSocial && (
              <img
                src={logoSocial}
                alt="logo"
                style={{
                  position: 'absolute',
                  marginLeft: '25%',
                  borderRadius: '50%',
                  border: `4px solid ${pageColorSocial}`,
                  width: 100,
                  height: 100,
                  top: 50,
                  zIndex: 1000,
                }}
              />
            )) || (
              <img
                src={Avatar}
                alt="logo"
                style={{
                  position: 'absolute',
                  marginLeft: '25%',
                  borderRadius: '50%',
                  border: `4px solid ${pageColorSocial}`,
                  width: 100,
                  height: 100,
                  top: 50,
                  zIndex: 1000,
                }}
              />
            )} */}
      <a
        style={{
          fontWeight: 'bold',
          fontFamily: 'unset',
          fontSize: 25,
          flex: 1,
          marginBottom: 10,
          display: 'flex',
          textAlign: 'center',
          alignItems: 'center',
          justifyContent: 'center',
          color: textColorSocial,
          marginTop: 130,
        }}
      >
        {socialName || 'Follow me on Social Media'}
      </a>
      <div>
        {socials.map((social) => (
          <div key={`preview_page_social_${social.id}`}>
            <a style={{ textDecoration: 'none' }} href={social.url} target="_blank" rel="noreferrer">
              {renderIcon(social.type)}
            </a>
          </div>
        ))}
      </div>
    </Grid>
  );
};
