/* eslint-disable no-nested-ternary */
import { Grid } from '@mui/material';
import { useDispatch } from 'react-redux';
import IphoneFrame from '../assets/iphone_frame.png';
import { PrevievTextOption } from './PreviewTextOption';
import { PrevievEventOption } from './PreviewEventOption';
import { PrevievVcardOption } from './PreviewVcardOption';
import { PrevievAppOption } from './PreviewAppOptions';
import { PrevievSocialOption } from './PreviewSocialOption';

// import { saveAppPreviewPage } from '../reducer/qr/configs';

export const PrevievOption = (props) => {
  const { input, configs } = props;

  const dispatch = useDispatch();

  return (
    <Grid style={{ marginRight: -130 }}>
      {input === 'txt' ? (
        <PreviewIphone>
          <PrevievTextOption props={props} />
        </PreviewIphone>
      ) : input === 'app' ? (
        <PreviewIphone>
          <PrevievAppOption props={props} />
        </PreviewIphone>
      ) : input === 'social' ? (
        <PreviewIphone>
          <PrevievSocialOption props={props} />
        </PreviewIphone>
      ) : input === 'event' ? (
        <PreviewIphone>
          <PrevievEventOption props={props} />
        </PreviewIphone>
      ) : (
        <PreviewIphone>
          <PrevievVcardOption props={props} />
        </PreviewIphone>
      )}
    </Grid>
  );
};

const PreviewIphone = (props) => {
  const height = 3568 / 6;
  const width = 1756 / 6;
  return (
    <div
      style={{
        marginLeft: '1%',
        position: 'relative',
        // height: 'fit-content',
        height,
        width,
      }}
    >
      <img
        src={IphoneFrame}
        alt="iphone-frame"
        style={{
          position: 'absolute',
          zIndex: 1000,
          minWidth: width,
          maxWidth: width,
          minHeight: height,
          maxHeight: height,
          pointerEvents: 'none',
        }}
      />
      <div
        style={{
          position: 'absolute',
          top: 5,
          left: 5,
          width: width - 10,
          minHeight: height - 10,
          zIndex: 0,
          backgroundColor: 'transparent',
          borderRadius: 50,
          overflow: 'hidden',
          display: 'flex',
        }}
      >
        {props.children}
      </div>
    </div>
  );
};
