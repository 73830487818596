export const getCookie = (key) => {
  try {
    if (document) {
      let cookies = document.cookie;
      if (cookies) {
        cookies = cookies
          .split(';')
          .map((cookie) => cookie.split('='))
          .reduce(
            (acc, [key, value]) => ({
              ...acc,
              [key.trim()]: value.trim(),
            }),
            {}
          );
        return cookies[key];
      }
      return '';
    }
    return '';
  } catch (err) {
    console.log('Error getting cookie');
    throw err;
  }
};
