import Chart from 'chart.js/auto';
import { Line } from 'react-chartjs-2';
import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect, React } from 'react';

import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { postQrLink, getDataByQrId, updateQrData } from '../communication/CommunicationController';

const LineChart = () => {
  const userLogged = useSelector((state) => state.user.user);
  const queryParams = useParams();
  const qrId = queryParams?.id;
  const [analytics, setAnalytics] = useState();

  const initialMonths = [
    {
      mese: '01',
      count: 0,
    },
    {
      mese: '02',
      count: 0,
    },
    {
      mese: '03',
      count: 0,
    },
    {
      mese: '04',
      count: 0,
    },
    {
      mese: '05',
      count: 0,
    },
    {
      mese: '06',
      count: 0,
    },
    {
      mese: '07',
      count: 0,
    },
    {
      mese: '08',
      count: 0,
    },
    {
      mese: '09',
      count: 0,
    },
    {
      mese: '10',
      count: 0,
    },
    {
      mese: '11',
      count: 0,
    },
    {
      mese: '12',
      count: 0,
    },
  ];

  const [months, setMonths] = useState(initialMonths);
  // console.log('months', months);

  useEffect(() => {
    // console.log('months', months);
  }, [months]);

  useEffect(() => {
    if (analytics?.length > 0 && analytics !== undefined && analytics !== null) {
      updateCounts(analytics);
    }
  }, [analytics]);

  const updateCounts = (newData) => {
    const updatedMonths = months.map((month) => {
      const matchingData = newData.find((data) => data.mese === month.mese);
      const count = matchingData ? matchingData.count : 0;
      return {
        ...month,
        count,
      };
    });
    setMonths(updatedMonths);
  };

  const getQrAnalytics = async () => {
    try {
      const res = await getDataByQrId(qrId);
      return res;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  //   console.log('no');

  const getAnalytics = async () => {
    try {
      const res = await getQrAnalytics();
      setAnalytics(res.analytics);
    } catch (err) {
      console.error('Error getting user qrs', err);
    }
  };

  // useEffect(() => {
  //   if (userLogged.isLog && userLogged.id) {
  //     getAnalytics();
  //   }
  // }, [userLogged]);
  useEffect(() => {
    getAnalytics();
  }, []);

  const data = {
    labels: ['gen', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sept', 'oct', 'nov', 'dec'],
    datasets: [
      {
        data: months?.map((conta) => conta.count),
        fill: false,
        borderWidth: 2,
        tension: 0.5,
        pointRadius: 0,
        borderColor: 'rgb(75, 192, 192)',
        pointHoverBackgroundColor: true,
        pointHoverBorderColor: true,
        pointHoverBorderWidth: true,
        pointHoverRadius: true,
      },
    ],
  };

  const options = {
    scales: {
      x: {
        grid: {
          display: false, // Nasconde le linee verticali
        },
      },
      y: {
        grid: {
          display: true, // Mostra le linee orizzontali
          borderDash: [2, 2],
          borderDashOffset: [10, 10],
        },
      },
    },
    plugins: {
      title: {
        display: true,
        text: 'Scanner per month',
      },
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
        usePointStyle: true,
        titleAlign: 'center',
        titleColor: 'gold',
        titleSpacing: 3,
        titleFont: {
          weight: 'bold',
        },
      },
    },
  };

  return (
    <div>
      <Line data={data} options={options} />
    </div>
  );
};

export default LineChart;
