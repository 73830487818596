import Chart from 'chart.js/auto';
import { Bar } from 'react-chartjs-2';
import { useDispatch, useSelector } from 'react-redux';
import {
  Container,
  FormControlLabel,
  Grid,
  Box,
  Card,
  Button,
  TextField,
  Alert,
  Toolbar,
  CircularProgress,
} from '@mui/material';
import { useState, useEffect, useRef, React, memo } from 'react';
import { ZoomableGroup, ComposableMap, Geographies, Geography, Marker, Annotation } from 'react-simple-maps';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import * as d3 from 'd3';

import 'react-tooltip/dist/react-tooltip.css';
import { postQrLink, getDataByQrId, updateQrData } from '../communication/CommunicationController';

const geoUrl = 'https://raw.githubusercontent.com/deldersveld/topojson/master/countries/italy/italy-regions.json';

const ItalyMaps = ({ setTooltipContent }) => {
  const [info, setInfo] = useState('');
  const userLogged = useSelector((state) => state.user.user);
  const queryParams = useParams();
  const qrId = queryParams?.id;
  const [analytics, setAnalytics] = useState();

  const initialRegion = [
    {
      region: 'Abruzzo',
      count: 0,
    },
    {
      region: 'Aosta Valley',
      count: 0,
    },
    {
      region: 'Apulia',
      count: 0,
    },
    {
      region: 'Basilicata',
      count: 0,
    },
    {
      region: 'Calabria',
      count: 0,
    },
    {
      region: 'Campania',
      count: 0,
    },
    {
      region: 'Emilia-Romagna',
      count: 0,
    },
    {
      region: 'Friuli-Venezia Giulia',
      count: 0,
    },
    {
      region: 'Lazio',
      count: 0,
    },
    {
      region: 'Liguria',
      count: 0,
    },
    {
      region: 'Lombardy',
      count: 0,
    },
    {
      region: 'Marche',
      count: 0,
    },
    {
      region: 'Molise',
      count: 0,
    },
    {
      region: 'Piedmont',
      count: 0,
    },

    {
      region: 'Sardinia',
      count: 0,
    },
    {
      region: 'Sicily',
      count: 0,
    },
    {
      region: 'Trentino-Alto Adige',
      count: 0,
    },
    {
      region: 'Tuscany',
      count: 0,
    },
    {
      region: 'Umbria',
      count: 0,
    },

    {
      region: 'Veneto',
      count: 0,
    },
  ];

  const [regions, setRegions] = useState(initialRegion);

  useEffect(() => {
    console.log('regions', regions);
  }, [regions]);

  useEffect(() => {
    setCont();
  }, [info]);

  useEffect(() => {
    if (analytics?.length > 0 && analytics !== undefined && analytics !== null) {
      updateCounts(analytics);
    }
  }, [analytics]);

  const updateCounts = (newData) => {
    const updatedMonths = regions.map((month) => {
      const matchingData = newData.find((data) => data.region === month.region);
      const count = matchingData ? matchingData.region_count : 0;
      return {
        ...month,
        count,
      };
    });
    setRegions(updatedMonths);
  };

  const getQrAnalytics = async () => {
    try {
      const res = await getDataByQrId(qrId);
      return res;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const getAnalytics = async () => {
    try {
      const res = await getQrAnalytics();
      setAnalytics(res.map);
    } catch (err) {
      console.error('Error getting user qrs', err);
    }
  };

  useEffect(() => {
    getAnalytics();
  }, []);

  const [regionData, setRegionData] = useState({
    content: '',
    count: 0,
  });

  function setCont() {
    switch (info) {
      case 'Abruzzo':
        setRegionData({
          content: info,
          count: regions[0].count,
        });
        break;
      case 'Aosta Valley':
        setRegionData({
          content: info,
          count: regions[1].count,
        });
        break;
      case 'Apulia':
        setRegionData({
          content: info,
          count: regions[2].count,
        });
        break;
      case 'Basilicata':
        setRegionData({
          content: info,
          count: regions[3].count,
        });
        break;
      case 'Calabria':
        setRegionData({
          content: info,
          count: regions[4].count,
        });
        break;
      case 'Campania':
        setRegionData({
          content: info,
          count: regions[5].count,
        });
        break;
      case 'Emilia-Romagna':
        setRegionData({
          content: info,
          count: regions[6].count,
        });
        break;
      case 'Friuli-Venezia Giulia':
        setRegionData({
          content: info,
          count: regions[7].count,
        });
        break;
      case 'Lazio':
        setRegionData({
          content: info,
          count: regions[8].count,
        });
        break;
      case 'Liguria':
        setRegionData({
          content: info,
          count: regions[9].count,
        });
        break;
      case 'Lombardy':
        setRegionData({
          content: info,
          count: regions[10].count,
        });
        break;
      case 'Marche':
        setRegionData({
          content: info,
          count: regions[11].count,
        });
        break;
      case 'Molise':
        setRegionData({
          content: info,
          count: regions[12].count,
        });
        break;
      case 'Piedmont':
        setRegionData({
          content: info,
          count: regions[13].count,
        });
        break;

      case 'Sardinia':
        setRegionData({
          content: info,
          count: regions[14].count,
        });
        break;
      case 'Sicily':
        setRegionData({
          content: info,
          count: regions[15].count,
        });
        break;
      case 'Trentino-Alto Adige':
        setRegionData({
          content: info,
          count: regions[16].count,
        });
        break;
      case 'Tuscany':
        setRegionData({
          content: info,
          count: regions[17].count,
        });
        break;
      case 'Umbria':
        setRegionData({
          content: info,
          count: regions[18].count,
        });
        break;

      case 'Veneto':
        setRegionData({
          content: info,
          count: regions[19].count,
        });
        break;
      default:
        break;
    }
  }

  console.log('regionData', regionData);

  return (
    <Card style={{ width: '1100px' }}>
      <ComposableMap
        // projection="geoAzimuthalEqualArea"

        projectionConfig={{
          scale: 2500,
          center: [13, 42],
          // rotate: [-2, 2, -4],
        }}
      >
        {/* <ZoomableGroup zoom={1}> */}
        <Geographies geography={'/features.json'}>
          {({ geographies }) =>
            geographies.map((geo) => (
              <Geography
                data-tooltip-id="my-tooltip"
                data-tooltip-content={geo.properties.NAME_1}
                key={geo.rsmKey}
                geography={geo}
                fill="rgb(75, 192, 192)"
                stroke="rgb(60, 153, 153)"
                onMouseEnter={() => {
                  setTooltipContent(`${geo.properties.NAME_1}`);
                  setInfo(`${geo.properties.NAME_1}`);
                }}
                onMouseLeave={() => {
                  setTooltipContent('');
                  // setInfo('');
                }}
                style={{
                  hover: {
                    fill: 'rgb(54, 162, 235)',
                    outline: 'none',
                  },
                  default: {
                    // fill: '#D6D6DA',
                    outline: 'none',
                  },

                  pressed: {
                    fill: 'rgb(54, 162, 235)',
                    outline: 'none',
                  },
                }}
              />
            ))
          }
        </Geographies>
        {/* </ZoomableGroup> */}
      </ComposableMap>
      <ReactTooltip id="my-tooltip" />
    </Card>
  );
};
export default memo(ItalyMaps);
