// @mui
import { Container, Typography, Box, Card, Grid } from '@mui/material';
// hooks
import { useTheme } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { UserProfile } from '@clerk/clerk-react';
import { dark } from '@clerk/themes';
import { ClerkWrapper } from '../clerk';
import useSettings from '../hooks/useSettings';
// components
import Page from '../components/Page';

// ----------------------------------------------------------------------

export default function PageSettings() {
  const { themeStretch } = useSettings();
  const { themeMode, onChangeMode } = useSettings();

  const userLogged = useSelector((state) => state.user.user);
  const navigate = useNavigate();

  const theme = useTheme();
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_THEME = theme.palette.mode;

  return (
    <Page title="User Settings">
      <Container style={{ marginTop: -80 }} maxWidth={themeStretch ? false : 'xl'}>
        {/* <ClerkWrapper themeMode={themeMode}> */}
        <Box align="center">
          {PRIMARY_THEME === 'light' ? (
            <UserProfile
              appearance={{
                baseTheme: {
                  variables: {
                    colorPrimary: PRIMARY_MAIN,
                    borderRadius: '8px',
                    fontFamily: 'Raleway, sans-serif',
                    fontWeight: {
                      normal: 400,
                      medium: 500,
                      bold: 600,
                    },
                  },
                  __type: 'prebuilt_appearance',
                },
              }}
            />
          ) : (
            <UserProfile
              appearance={{
                baseTheme: {
                  variables: {
                    colorBackground: '#191E28',
                    colorPrimary: PRIMARY_MAIN,
                    // colorInputBackground: '#19191A',
                    colorAlphaShade: 'white',
                    colorText: 'white',
                    colorInputText: 'white',
                  },
                  __type: 'prebuilt_appearance',
                },
              }}
            />
          )}
        </Box>
        {/* </ClerkWrapper> */}
      </Container>
    </Page>
  );
}
