import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
// @mui
import { styled, useTheme } from '@mui/material/styles';
import { Box, Stack, Drawer, Button } from '@mui/material';
// hooks
import { useClerk, useUser, UserButton, SignInButton, SignedIn, SignedOut, SignOutButton } from '@clerk/clerk-react';
import LogoutIcon from '@mui/icons-material/Logout';
import { useDispatch, useSelector } from 'react-redux';
import useResponsive from '../../../hooks/useResponsive';
import useCollapseDrawer from '../../../hooks/useCollapseDrawer';
// utils
import cssStyles from '../../../utils/cssStyles';
// config
import { NAVBAR } from '../../../config';
// components
import Logo from '../../../components/Logo';
import Scrollbar from '../../../components/Scrollbar';
import { NavSectionVertical } from '../../../components/nav-section';

//
import navConfig from './NavConfig';
import NavbarDocs from './NavbarDocs';
import NavbarAccount from './NavbarAccount';
import CollapseButton from './CollapseButton';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    transition: theme.transitions.create('width', {
      duration: theme.transitions.duration.shorter,
    }),
  },
}));

// ----------------------------------------------------------------------

NavbarVertical.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
};

let isLogin = false;
export default function NavbarVertical({ isOpenSidebar, onCloseSidebar }) {
  const theme = useTheme();
  const { user, isLoading } = useUser();

  // const user = useSelector((state) => state.user.user.isLog);

  const { pathname } = useLocation();

  const isDesktop = useResponsive('up', 'lg');
  if (user === null || user === undefined) {
    isLogin = false;
  } else {
    isLogin = true;
  }

  // console.log('navconfigs: ', navConfig);
  const { isCollapse, collapseClick, collapseHover, onToggleCollapse, onHoverEnter, onHoverLeave } =
    useCollapseDrawer();

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const handleLogout = () => {
    localStorage.clear();
    window.location.href = '/';
  };

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
      }}
    >
      <Stack
        spacing={3}
        sx={{
          pt: 3,
          pb: 2,
          px: 2.5,
          flexShrink: 0,
          ...(isCollapse && { alignItems: 'center' }),
        }}
      >
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Logo />

          {isDesktop && !isCollapse && (
            <CollapseButton onToggleCollapse={onToggleCollapse} collapseClick={collapseClick} />
          )}
        </Stack>
        <NavbarAccount isCollapse={isCollapse} />
        {isLogin && (
          <SignOutButton>
            {isCollapse === true ? (
              <Button variant="contained" className="btn">
                <LogoutIcon />
              </Button>
            ) : (
              <Button variant="contained" className="btn" onClick={handleLogout}>
                Logout
              </Button>
            )}
          </SignOutButton>
        )}
      </Stack>
      <NavSectionVertical
        navConfig={navConfig
          .map((navbarSection) => ({
            ...navbarSection,
            items: navbarSection.items.filter((item) => (isLogin ? true : !item.auth)),
          }))
          .filter((navbarSection) => !!navbarSection.items.length)}
        isCollapse={isCollapse}
      />
      {/* <NavSectionVertical
        navConfig={navConfig
          .map((navbarSection) => ({
            ...navbarSection,
            items: navbarSection.items
              .map((item) => {
                if (item.children) {
                  item.children = item.children.filter((child) => (isLogin ? true : !child.auth));
                }
                return item;
              })
              .filter((item) => {
                if (item.children) {
                  return !!item.children.length;
                }
                return isLogin ? true : !item.auth;
              }),
          }))
          .filter((navbarSection) => !!navbarSection.items.length)}
        isCollapse={isCollapse}
      /> */}
      <Box sx={{ flexGrow: 1 }} />
      {!isCollapse && <NavbarDocs />}
    </Scrollbar>
  );

  return (
    <RootStyle
      sx={{
        width: {
          lg: isCollapse ? NAVBAR.DASHBOARD_COLLAPSE_WIDTH : NAVBAR.DASHBOARD_WIDTH,
        },
        ...(collapseClick && {
          position: 'absolute',
        }),
      }}
    >
      {!isDesktop && (
        <Drawer open={isOpenSidebar} onClose={onCloseSidebar} PaperProps={{ sx: { width: NAVBAR.DASHBOARD_WIDTH } }}>
          {renderContent}
        </Drawer>
      )}
      {isDesktop && (
        <Drawer
          open
          variant="persistent"
          onMouseEnter={onHoverEnter}
          onMouseLeave={onHoverLeave}
          PaperProps={{
            sx: {
              width: NAVBAR.DASHBOARD_WIDTH,
              borderRightStyle: 'dashed',
              bgcolor: 'background.default',
              transition: (theme) =>
                theme.transitions.create('width', {
                  duration: theme.transitions.duration.standard,
                }),
              ...(isCollapse && {
                width: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
              }),
              ...(collapseHover && {
                ...cssStyles(theme).bgBlur(),
                boxShadow: (theme) => theme.customShadows.z24,
              }),
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </RootStyle>
  );
}
