import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// @mui
import { Grid, Card, Button } from '@mui/material';
import { useTheme, styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import 'react-device-emulator/lib/styles/style.css';
import UpgradeIcon from '@mui/icons-material/Upgrade';
import DeleteIcon from '@mui/icons-material/Delete';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import ImageUploading from 'react-images-uploading';
// import { Link } from '../components/Link';
import { saveOptions } from '../reducer/qr/configs';
import { Sms } from './Sms';
import { Link } from './Link';
import { Event } from './Event';
import { EmailOk } from './Email';
import { Vcard } from './Vcard';
import { App } from './AppQR';
import { Call } from './Call';
import { Whatsapp } from './Whatsapp';
import { TextOk } from './Text';
import { Wifi } from './Wifi';
import { Social } from './Social';
import { InputRenderer } from './InputRenderer';

// hooks
import useSettings from '../hooks/useSettings';
// components

const Accordion = styled((props) => <MuiAccordion disableGutters elevation={0} square {...props} />)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />} {...props} />
))(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const inputs = [
  { key: 'ecLevel', label: 'EC Level', type: 'select', options: ['M', 'L', 'Q', 'H'] }, // L | M | Q | H
  { key: 'size', label: 'Size', type: 'number' }, // number (in pixel)
  { key: 'quiteZone', label: 'Quite zone', type: 'number' }, // number (in pixel)
  { key: 'bgColor', label: 'Background Color', type: 'color' }, // string
  { key: 'fgColor', label: 'Frontground Color', type: 'color' }, // string
  { key: 'qrStyle', label: 'Qr style', type: 'select', options: ['squares', 'dots'] }, // squares | dots
  { key: 'eyeRadius', label: 'Qr eye radius', type: 'number' }, // CornerRadii | CornerRadii[]
  { key: 'eyeColor', label: 'Qr eye color', type: 'text' }, // EyeColor | EyeColor[]
  { key: 'logoImage', label: 'Logo url', type: 'text' }, // string (url | base64)
  { key: 'logoWidth', label: 'Logo size', type: 'number' }, // number (in pixel)
  { key: 'logoHeight', label: 'Logo height', type: 'number' }, // number (in pixel)
  { key: 'logoOpacity', label: 'Logo opacity', type: 'number' }, // number (css opacity 0 <= x <= 1)
  { key: 'enableCors', label: 'Enable CORS', type: 'checkbox' }, // boolean
  { key: 'removeQrCodeBehindLogo', label: 'Remove Qr code behind logo', type: 'checkbox' }, // boolean
  // { label: "id", type: "text" }, // string
];

const formatUrl = (type, configs) => {
  switch (type) {
    case 'link':
      return configs.link;
    case 'sms':
      return `SMSTO:${configs.sms.number?.split(' ').join('')}:${configs.sms.message}`;
    case 'call':
      return `${configs.call.number?.split(' ').join('')}`;
    case 'email':
      return `MATMSG:TO:${configs.email.address};SUB:${configs.email.subject};BODY:${configs.email.message};;`;
    case 'txt':
      return configs.txt;
    case 'whatsapp':
      return `https://wa.me/${configs.whatsapp.number?.split(' ').join('')}/?text=${configs.whatsapp.message}`;
    case 'wifi':
      return `WIFI:S:${configs.wifi.ssid};T:${configs.wifi.networkType};P:${configs.wifi.wifiPsw};;`;
    case 'vcard':
      return `BEGIN:VCARD\nVERSION:2.1\nN;CHARSET=UTF-8:${configs.vcard.lastName};${
        configs.vcard.firstName
      }\nFN;CHARSET=UTF-8:${configs.vcard.firstName};${configs.vcard.lastName}\nTEL;CELL:${configs.vcard.mobile
        ?.split(' ')
        .join('')}\nTEL;HOME;VOICE:${configs.vcard.phoneNumber?.split(' ').join('')}\nORG;CHARSET=UTF-8:${
        configs.vcard.company
      }\nTITLE;CHARSET=UTF-8:${configs.vcard.jobTitle}\nTEL;WORK;FAX:${
        configs.vcard.fax
      }\nADR;CHARSET=UTF-8;WORK;PREF:;;${configs.vcard.address};${configs.vcard.city};${configs.vcard.postCode};${
        configs.vcard.country
      }\nEMAIL:${configs.vcard.email}\nURL:${configs.vcard.webSite}\nEND:VCARD`;
    case 'event':
      return `BEGIN:VEVENT\nSUMMARY:${configs.event.nameEvent}\nLOCATION:${configs.event.location}\nURL:${configs.event.url}\nDESCRIPTION:${configs.event.description}\nDTSTART:${configs.event.dataStart}00\nDTEND:${configs.event.dataEnd}00\nEND:VEVENT`;
    case 'app':
      return `https://${configs.app.ios}`;
    default:
  }
};

export const ComponentQrCard = ({ props }) => {
  const { themeStretch } = useSettings();
  const theme = useTheme();
  const PRIMARY_MAIN = theme.palette.primary.main;

  const [expanded, setExpanded] = useState('panel1');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const [selectedQrOptions, setSelectedQrOptions] = useState('link');
  const qrConfigs = useSelector((state) => state.qrConfigs);

  const qrCodeUrl = formatUrl(selectedQrOptions, qrConfigs);

  const [configs, setConfigs] = useState({
    value: qrCodeUrl,
    qrStyle: 'squares',
    ecLevel: 'M',
    size: 200,
  });

  const dispatchOptions = useDispatch();

  const [images, setImages] = useState([]);
  const maxNumber = 1;
  const onChange = (imageList, addUpdateIndex) => {
    // data for submit
    setImages(imageList);
    setConfigs((configs) => ({
      ...configs,
      logoImage: imageList.length && imageList[0].data_url,
    }));
  };

  return (
    <Card style={{ marginTop: 35 }}>
      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary
          aria-controls="panel1d-content"
          id="panel1d-header"
          style={{ color: 'black' ? PRIMARY_MAIN : 'unset' }}
        >
          <Typography>Type</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <TableContainer>
              <Table sx={{ margin: 1 }} aria-label="simple table">
                {selectedQrOptions === 'link' && <Link />}
                {selectedQrOptions === 'sms' && <Sms />}
                {selectedQrOptions === 'email' && <EmailOk />}
                {selectedQrOptions === 'txt' && <TextOk />}
                {selectedQrOptions === 'call' && <Call />}
                {selectedQrOptions === 'event' && <Event />}
                {selectedQrOptions === 'whatsapp' && <Whatsapp />}
                {selectedQrOptions === 'wifi' && <Wifi />}
                {selectedQrOptions === 'social' && <Social />}
                {selectedQrOptions === 'app' && <App />}
                {selectedQrOptions === 'vcard' && <Vcard />}
              </Table>
            </TableContainer>
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary
          style={{ color: 'black' ? PRIMARY_MAIN : 'unset' }}
          aria-controls="panel2d-content"
          id="panel2d-header"
        >
          <Typography>Options</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <Grid item xs={12} spacing={1} container style={{ marginTop: 13 }}>
              {inputs
                .filter((input) => input.type !== 'color' && !input.key.toLowerCase().includes('logo'))
                .map((input, i) => (
                  <InputRenderer
                    key={`qr-input-${i}`}
                    input={input}
                    inputId={i}
                    configs={configs}
                    setConfigs={setConfigs}
                    onChange={(ev) => dispatchOptions(saveOptions(ev.target.value))}
                  />
                ))}
            </Grid>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
        <AccordionSummary
          style={{ color: 'black' ? PRIMARY_MAIN : 'unset' }}
          aria-controls="panel2d-content"
          id="panel2d-header"
        >
          <Typography>Logo</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <Grid item xs={12} spacing={1} container style={{ marginTop: 16 }}>
              {inputs
                .filter((input) => input.type !== 'color' && !!input.key.toLowerCase().includes('logo'))
                .map((input, i) => (
                  <InputRenderer
                    key={`qr-input-${i}`}
                    input={input}
                    inputId={i}
                    configs={configs}
                    setConfigs={setConfigs}
                  />
                ))}
              <Grid item xs={12} style={{ marginTop: 2, marginLeft: 12 }}>
                <ImageUploading multiple value={images} onChange={onChange} maxNumber={maxNumber} dataURLKey="data_url">
                  {({ imageList, onImageUpload, onImageUpdate, onImageRemove, dragProps }) => (
                    // write your building UI
                    <Grid>
                      <Button
                        style={{ marginBottom: 8, marginTop: 8 }}
                        variant="outlined"
                        startIcon={<PhotoCamera />}
                        onClick={onImageUpload}
                        {...dragProps}
                      >
                        Upload image
                      </Button>
                      &nbsp;
                      {/* <Button variant="outlined" startIcon={<DeleteIcon />} onClick={onImageRemoveAll}>
                              Remove All images
                            </Button> */}
                      {imageList.map((image, index) => (
                        <Grid key={`qr-input-image`}>
                          <img src={image.data_url} alt="" width="100" />
                          <Grid marginTop={1}>
                            <Button
                              style={{ marginRight: -22 }}
                              variant="text"
                              startIcon={<UpgradeIcon />}
                              onClick={() => onImageUpdate(index)}
                            />

                            <Button variant="text" startIcon={<DeleteIcon />} onClick={() => onImageRemove(index)} />
                          </Grid>
                        </Grid>
                      ))}
                    </Grid>
                  )}
                </ImageUploading>
              </Grid>
            </Grid>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
        <AccordionSummary
          style={{ color: 'black' ? PRIMARY_MAIN : 'unset' }}
          aria-controls="panel2d-content"
          id="panel2d-header"
        >
          <Typography>Colors</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <Grid
              item
              xs={12}
              container
              justifyContent={'space-evenly'}
              style={{ marginTop: 15, marginBottom: 15, marginLeft: 20 }}
            >
              {inputs
                .filter((input) => input.type === 'color')
                .map((input, i) => (
                  <InputRenderer
                    key={`qr-input-${i}`}
                    input={input}
                    inputId={i}
                    configs={configs}
                    setConfigs={setConfigs}
                  />
                ))}
            </Grid>
          </Typography>
        </AccordionDetails>
      </Accordion>
    </Card>
  );
};
