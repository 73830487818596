import { useEffect, useState, useController, useCallback, useMemo } from 'react';
import { Grid, TextField, Stack, Button, debounce } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import * as React from 'react';
import UpgradeIcon from '@mui/icons-material/Upgrade';
import DeleteIcon from '@mui/icons-material/Delete';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import Table from '@mui/material/Table';
import { MuiColorInput } from 'mui-color-input';
import TableContainer from '@mui/material/TableContainer';
import ImageUploading from 'react-images-uploading';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import dayjs from 'dayjs';
import { DatePicker, TimePicker, DateTimePicker } from '@mui/x-date-pickers';
import { saveEvent } from '../reducer/qr/configs';

export const Event = () => {
  const [nameEvent, setNameEvent] = useState(useSelector((state) => state.qrConfigs.event.nameEvent));
  const [location, setLocation] = useState(useSelector((state) => state.qrConfigs.event.location));
  const [url, setUrl] = useState(useSelector((state) => state.qrConfigs.event.url));
  const [description, setDescription] = useState(useSelector((state) => state.qrConfigs.event.description));
  const [dataStart, setDataStart] = useState(useSelector((state) => state.qrConfigs.event.dataStart));
  const [dataEnd, setDataEnd] = useState(useSelector((state) => state.qrConfigs.event.dataEnd));

  const [color, setColor] = useState(useSelector((state) => state.qrConfigs.event.pageColor));
  const [colorCard, setColorCard] = useState(useSelector((state) => state.qrConfigs.event.cardColor));
  const [colorText, setColorText] = useState(useSelector((state) => state.qrConfigs.event.textColor));

  const dispatch = useDispatch();

  const handleSaveName = useCallback(
    (name) => {
      dispatch(saveEvent({ key: 'nameEvent', value: name }));
    },
    [dispatch]
  );
  const handleSaveLocation = useCallback(
    (location) => {
      dispatch(saveEvent({ key: 'location', value: location }));
    },
    [dispatch]
  );
  const handleSaveUrl = useCallback(
    (url) => {
      dispatch(saveEvent({ key: 'url', value: url }));
    },
    [dispatch]
  );
  const handleSaveDescription = useCallback(
    (description) => {
      dispatch(saveEvent({ key: 'description', value: description }));
    },
    [dispatch]
  );
  const handleSaveDataStart = useCallback(
    (dataStart) => {
      console.log('DATA: ', dayjs(dataStart).format('YYYY-MM-DD HH:mm'));

      dispatch(saveEvent({ key: 'dataStart', value: dayjs(dataStart).format('YYYY-MM-DD HH:mm') }));
    },
    [dispatch]
  );
  const handleSaveDataEnd = useCallback(
    (dataEnd) => {
      dispatch(saveEvent({ key: 'dataEnd', value: dayjs(dataEnd).format('YYYY-MM-DD HH:mm') }));
    },
    [dispatch]
  );

  const debouncedSendRequestName = useMemo(() => debounce(handleSaveName, 1000), [handleSaveName]);
  const debouncedSendRequestLocation = useMemo(() => debounce(handleSaveLocation, 1000), [handleSaveLocation]);
  const debouncedSendRequestUrl = useMemo(() => debounce(handleSaveUrl, 1000), [handleSaveUrl]);
  const debouncedSendRequestDescription = useMemo(() => debounce(handleSaveDescription, 1000), [handleSaveDescription]);
  const debouncedSendRequestDataStart = useMemo(() => debounce(handleSaveDataStart, 1000), [handleSaveDataStart]);
  const debouncedSendRequestDataEnd = useMemo(() => debounce(handleSaveDataEnd, 1000), [handleSaveDataEnd]);

  const onChangeName = (e) => {
    // const { value } = e.target;
    console.log('E: ', e.target.value);
    setNameEvent(e.target.value);
    debouncedSendRequestName(e.target.value);
  };

  const onChangeLocation = (e) => {
    // const { value } = e.target;
    setLocation(e.target.value);
    debouncedSendRequestLocation(e.target.value);
  };
  const onChangeUrl = (e) => {
    // const { value } = e.target;
    setUrl(e.target.value);
    debouncedSendRequestUrl(e.target.value);
  };
  const onChangeDescription = (e) => {
    // const { value } = e.target;
    console.log('E: ', e.target.value);
    setDescription(e.target.value);
    debouncedSendRequestDescription(e.target.value);
  };

  const onChangeDataStart = (e) => {
    // const { value } = e.target;
    setDataStart(e);
    debouncedSendRequestDataStart(e);
  };
  const onChangeDataEnd = (e) => {
    // const { value } = e.target;
    setDataEnd(e);
    debouncedSendRequestDataEnd(e);
  };

  const behavior = useSelector((state) => state.qrConfigs.behavior);

  function rgbToHex(rgbColor) {
    // Rimuovi il prefisso "rgb(" e la parentesi chiusa ")"
    const rgbValues = rgbColor.substring(4, rgbColor.length - 1).split(', ');

    // Converte i valori RGB in esadecimali
    const r = parseInt(rgbValues[0], 10).toString(16).padStart(2, '0');
    const g = parseInt(rgbValues[1], 10).toString(16).padStart(2, '0');
    const b = parseInt(rgbValues[2], 10).toString(16).padStart(2, '0');

    // Restituisci il colore esadecimale
    return `#${r}${g}${b}`;
  }

  const [images, setImages] = useState();
  const maxNumber = 1;

  const onChange = (imageList) => {
    // data for submit
    setImages(imageList);
    dispatch(saveEvent({ key: 'logo', value: imageList[0].data_url }));
  };

  const handleChange = (color) => {
    const colorEx = rgbToHex(color);

    setColor(colorEx);
    dispatch(saveEvent({ key: 'pageColor', value: colorEx }));
  };
  const handleChangeCard = (color) => {
    const colorEx = rgbToHex(color);

    setColorCard(colorEx);
    dispatch(saveEvent({ key: 'cardColor', value: colorEx }));
  };
  const handleChangeText = (color) => {
    const colorEx = rgbToHex(color);

    setColorText(colorEx);
    dispatch(saveEvent({ key: 'textColor', value: colorEx }));
  };

  return (
    <>
      <Stack>
        <a style={{ padding: 13, fontWeight: 'bold', fontSize: 24, marginLeft: 20, marginTop: 12 }}>Event</a>
        {behavior === 'previewPage' && (
          <TableContainer>
            <Table sx={{ marginLeft: -3 }} aria-label="simple table">
              <TableRow>
                <TableCell align="left">
                  <a style={{ padding: 8, fontWeight: 'bold', fontSize: 20, marginLeft: 20, marginTop: 12 }}>
                    Page color
                  </a>
                </TableCell>
                <TableCell align="left">
                  <a style={{ padding: 8, fontWeight: 'bold', fontSize: 20, marginLeft: 20, marginTop: 12 }}>
                    Card color
                  </a>
                </TableCell>
                <TableCell align="left">
                  <a style={{ padding: 8, fontWeight: 'bold', fontSize: 20, marginLeft: 20, marginTop: 12 }}>
                    Text color
                  </a>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left">
                  <MuiColorInput value={color} onChange={handleChange} style={{ marginLeft: 30 }} />
                </TableCell>
                <TableCell align="left">
                  <MuiColorInput value={colorCard} onChange={handleChangeCard} style={{ marginLeft: 30 }} />
                </TableCell>
                <TableCell align="left">
                  <MuiColorInput value={colorText} onChange={handleChangeText} style={{ marginLeft: 30 }} />
                </TableCell>
              </TableRow>
            </Table>
          </TableContainer>
        )}
        <Table sx={{ minWidth: 550 }} aria-label="simple table">
          <TableBody>
            <TableRow>
              <TableCell align="left">
                <TextField
                  id="outlined-basic"
                  label="Name event"
                  variant="outlined"
                  defaultValue={nameEvent}
                  onChange={onChangeName}
                />{' '}
              </TableCell>
              <TableCell align="left">
                <TextField
                  id="outlined-basic"
                  label="Location"
                  variant="outlined"
                  defaultValue={location}
                  onChange={onChangeLocation}
                />{' '}
              </TableCell>
              <TableCell align="left">
                <TextField
                  id="outlined-basic"
                  label="URL"
                  variant="outlined"
                  defaultValue={url}
                  onChange={onChangeUrl}
                />{' '}
              </TableCell>
            </TableRow>
          </TableBody>
          <TableBody>
            <TableRow>
              <TableCell align="left">
                <TextField
                  id="outlined-basic"
                  label="Description"
                  variant="outlined"
                  defaultValue={description}
                  onChange={onChangeDescription}
                />{' '}
              </TableCell>

              <TableCell align="left">
                <DateTimePicker
                  id="outlined-basic"
                  label="Data start"
                  defaultValue={dayjs(dataStart)}
                  onChange={onChangeDataStart}
                />
              </TableCell>
              <TableCell align="left">
                <DateTimePicker
                  id="outlined-basic"
                  label="Data end"
                  defaultValue={dayjs(dataEnd)}
                  onChange={onChangeDataEnd}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        {behavior === 'previewPage' && (
          <ImageUploading multiple value={images} onChange={onChange} maxNumber={maxNumber} dataURLKey="data_url">
            {({ imageList, onImageUpload, onImageRemoveAll, onImageUpdate, onImageRemove, isDragging, dragProps }) => (
              // write your building UI
              <Grid>
                <Button
                  style={{ marginBottom: 28, marginTop: 8, marginLeft: 21 }}
                  variant="outlined"
                  startIcon={<PhotoCamera />}
                  onClick={onImageUpload}
                  {...dragProps}
                >
                  Upload image
                </Button>
                &nbsp;
                {/* <Button variant="outlined" startIcon={<DeleteIcon />} onClick={onImageRemoveAll}>
                              Remove All images
                            </Button> */}
                {imageList.map((image, index) => (
                  <Grid>
                    <img src={image.data_url} alt="" width="100" />
                    <Grid marginTop={1}>
                      <Button
                        style={{ marginRight: -22 }}
                        variant="text"
                        startIcon={<UpgradeIcon />}
                        onClick={() => onImageUpdate(index)}
                      />

                      <Button variant="text" startIcon={<DeleteIcon />} onClick={() => onImageRemove(index)} />
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            )}
          </ImageUploading>
        )}
        <a style={{ fontSize: 13, marginTop: 14, marginLeft: 30 }}>
          Your QR code will save this contact to the phone scanning.{' '}
        </a>
      </Stack>
    </>
  );
};
