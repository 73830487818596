import Chart from 'chart.js/auto';
import { Line } from 'react-chartjs-2';
import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect, React } from 'react';
import {
  Container,
  FormControlLabel,
  Grid,
  Box,
  Card,
  CardContent,
  Typography,
  Avatar,
  Button,
  TextField,
  Alert,
  Toolbar,
  Tooltip,
  CircularProgress,
} from '@mui/material';
import { useTheme, styled } from '@mui/material/styles';

import AbcIcon from '@mui/icons-material/Abc';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { postQrLink, getDataByQrId, updateQrData } from '../communication/CommunicationController';

const MainLanguage = () => {
  const userLogged = useSelector((state) => state.user.user);
  const queryParams = useParams();
  const qrId = queryParams?.id;
  const [analytics, setAnalytics] = useState();
  const [languages, setLanguages] = useState();

  // console.log(analytics);

  const getQrAnalytics = async () => {
    try {
      const res = await getDataByQrId(qrId);
      return res;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const getAnalytics = async () => {
    try {
      const res = await getQrAnalytics();
      setAnalytics(res.language);
    } catch (err) {
      console.error('Error getting user qrs', err);
    }
  };

  useEffect(() => {
    if (userLogged.isLog && userLogged.id) {
      getAnalytics();
    }
  }, [userLogged]);

  useEffect(() => {
    if (analytics !== null && analytics !== undefined) {
      const languages = analytics.split('-');

      setLanguages(languages[1]);
    }
    //   console.log(languages[1]);
  }, [analytics]);

  return (
    <Card
      style={{
        background: 'linear-gradient(45deg, #64B5F6, #4CAF50)',

        borderRadius: 8,
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
        color: '#fff',
      }}
    >
      <CardContent style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        {' '}
        <Avatar
          style={{
            backgroundColor: '#E1F5FE',
            width: 64,
            height: 64,
            marginBottom: 16,
            // transform: 'perspective(500px) rotateY(20deg)',
          }}
        >
          <AbcIcon style={{ fontSize: 32, color: 'black' }} />
        </Avatar>
        <Typography variant="h4" style={{ marginTop: 8 }}>
          {languages}
        </Typography>
        <Typography variant="h6" style={{ marginTop: 16 }}>
          Top language
        </Typography>
      </CardContent>
    </Card>
  );
};

export default MainLanguage;
